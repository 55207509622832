<template>
  <div class="bill-view">
    <div v-if="products.length == 0">
      <h5>Factura</h5>
      <p>
        No tienes una pedido actual para facturar con el restaurante, si quieres
        ver tus facturas pasadas ingresa a tu historial de pedidos en tu perfil
      </p>
      <button class="primary-button" @click="toMenu(0)">
        Ver menú
      </button>
      <button class="secondary-button">
        Ver historial de pedidos
      </button>
    </div>
    <div v-else>
      <perquisite />
      <pay-methods />
      <div class="bill-preview">
        <h6>Cuenta</h6>
        <div v-for="product in products" :key="product.id">
          <div class="product">
            <div>{{ product.name }}</div>
            <div>
              $ {{ new Intl.NumberFormat("es-CO").format(product.price) }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div>Propina</div>
          <div>$ {{ new Intl.NumberFormat("es-CO").format(perquisite) }}</div>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center">
          <h6>SUBTOTAL</h6>
          <div>{{ subtotal }}</div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div>Impuesto consumo</div>
          <div>$25.000</div>
        </div>
        <hr />
        <h6>DESCUENTOS</h6>
        <div class="d-flex justify-content-between align-items-center">
          <div>Bitute Caldoso</div>
          <div>-$10.000</div>
        </div>
        <hr />
        <div class="d-flex justify-content-between align-items-center">
          <h6>TOTAL</h6>
          <h6>$30.000</h6>
        </div>
      </div>
      <pay-button
        @click="pay"
        data-bs-toggle="modal"
        data-bs-target="#modalPayProcess"
      />
    </div>
  </div>
</template>
<script>
import Perquisite from "@/components/Perquisite.vue"
import PayMethods from "@/components/PayMethods.vue"
import PayButton from "@/components/PayButton.vue"
import { mapGetters, mapMutations } from "vuex"
export default {
  name: "BillView",
  components: {
    Perquisite,
    PayMethods,
    PayButton,
  },
  computed: {
    ...mapGetters({
      products: "getOrderProducts",
      perquisite: "getPerquisite",
      totalProductsPrice: "getTotalPrice",
      inProgressBill: "getInProgressBill",
    }),
    subtotal() {
      return Number(this.perquisite) + Number(this.totalProductsPrice)
    },
  },
  methods: {
    pay() {
      // this.$store.commit("setInProgressBill");
      setTimeout(() => {
        window.$("#modalPayProcess").modal("hide")
        this.$router.push("/farewell")
      }, 10000)
    },
    ...mapMutations({
      toMenu: "toggleFooterNavbarButtons",
    }),
  },
}
</script>
<style scoped>
.bill-view {
  padding: 0 20px;
  text-align: start;
  margin-top: 24px;
  margin-bottom: 120px;
}
.bill-preview {
  margin-top: 20px;
}
.product {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
h5 {
  margin: 0 0 9px 0;
  font-size: 22px;
}
h6 {
  margin: 0;
  font-size: 18px;
}
.primary-button {
  width: 100%;
  margin-top: 20px;
}
.secondary-button {
  width: 100%;
  margin-top: 10px;
}
</style>
