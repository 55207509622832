export const getNewConnection = state => {
  return state.newConnection
}
export const getSocketError = state => {
  return state.error
}
export const getConnectionId = state => {
  return state.connectionId
}
export const getSocket = state => {
  return state.socket
}
export const getPhoneData = state => {
  return { phone: state.phone, phoneId: state.phoneId }
}
export const getLocation = state => {
  return { location: state.location }
}
