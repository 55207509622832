<template>
  <div class="perquisite">
    <h5>Propina a meseros y cocina</h5>
    <p>
      Es un gesto de reconocimiento y gratitud por el buen servicio recibido,
      pero no es obligatorio.
    </p>
    <div class="perquisites">
      <button
        :class="
          sugestedPerquisite ? 'perquisite-button active' : 'perquisite-button'
        "
        @click="togglePerquisite(0)"
      >
        <p>
          <span>Sugerido:</span> &nbsp; ${{
            new Intl.NumberFormat("es-CO").format(10000)
          }}
        </p>
      </button>
      <button
        :class="
          !sugestedPerquisite ? 'perquisite-button active' : 'perquisite-button'
        "
        @click="togglePerquisite(1)"
        data-bs-toggle="modal"
        data-bs-target="#modalPerquisite"
      >
        <p>Otro</p>
      </button>
    </div>
    <button v-if="!sugestedPerquisite" class="perquisite-button other">
      <p>$ {{ new Intl.NumberFormat("es-CO").format(perquisite) }}</p>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "tip-vue",
  data() {
    return {
      sugestedPerquisite: true,
      otherPerquisite: 0,
    }
  },
  computed: {
    ...mapGetters({
      perquisite: "getPerquisite",
    }),
  },
  methods: {
    togglePerquisite(index) {
      if (index == 0) {
        this.sugestedPerquisite = true
      } else {
        this.sugestedPerquisite = false
      }
    },
  },
}
</script>
<style scoped>
.perquisites {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.perquisite-button {
  height: 40px;
  border-radius: 20px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececee;
}
.perquisites .perquisite-button:first-child {
  width: 182px;
}
.perquisites .perquisite-button:last-child {
  width: 86px;
}
.active {
  background-color: #e0e0e0;
}
.other {
  margin-top: 20px;
  background-color: #02c874;
}
.other p {
  color: #fff;
  font-weight: 700;
}
</style>
