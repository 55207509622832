<template>
  <q-card flat bordered class="product-item">
    <div class="food-info" @click="food.is_active ? openModal(food) : ''">
      <div>
        <h3 class="text-secondary  fs-18">
          {{ name }}
        </h3>
        <small style=" margin-bottom: 10px;" class="body-color fs-14">{{
          truncatedDescription
        }}</small>
      </div>
      <div class="food-item--footer">
        <p class="body-color fs-14" v-if="food.is_active">
          $ {{ new Intl.NumberFormat("es-CO").format(food.total_price) }}
        </p>
        <q-chip
          style="background-color:var(--q-secondary); font-size: 14px !important;"
          class="q-ma-none q-mt-sm text-white"
          v-else
        >
          <p>Agotado</p>
        </q-chip>
      </div>
    </div>
    <q-avatar size="87px" rounded class="relative-position">
      <q-chip
        v-if="!categoryAvailability"
        :class="{ 'bagde-with-image': !noImg, 'bagde-without-image': noImg }"
        class="q-mx-none bagde-available"
        color="secondary"
        text-color="white"
        label="No disponible"
        style="position: absolute; bottom: -16px; left: -17px; right: 0; background-color: var(--q-secondary); font-family: var(--q-body-font);"
      ></q-chip>
      <img
        v-if="!noImg"
        @click="food.is_active ? openModal(food) : ''"
        class="food-img"
        :src="food.image"
        alt=""
      />
    </q-avatar>
    <q-btn
      v-if="food.is_active && categoryAvailability"
      class="add-btn"
      style="background-color:var(--q-secondary) ;"
      text-color="white"
      icon="add"
      @click="openModal(food)"
    />
  </q-card>
</template>
<script>
export default {
  props: {
    food: {
      type: Object,
      required: true,
    },
    openModal: {
      type: Function,
      required: true,
    },
    categoryAvailability: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    name() {
      const name = this.food.name.substring(0, 36)
      return name
    },
    description() {
      const description = this.food.description.substring(0, 58)
      return description
    },
    noImg() {
      return this.food.image === null
    },
    truncatedDescription() {
      const maxDescriptionLength = 58 // El número máximo de caracteres antes de agregar puntos suspensivos
      const description = this.food.description

      if (description.length <= maxDescriptionLength) {
        return description // No es necesario truncar
      } else {
        return `${description.slice(0, maxDescriptionLength - 3)}...` // Agrega los puntos suspensivos
      }
    },
  },
  methods: {
    // openModal() {
    //   this.$store.commit("setFoodConfig", this.food.id);
    //   window.$("#modalFood").modal("show");
    // },
  },
}
</script>
<style lang="scss" scoped>
h5 {
  font-weight: 600;
}
.bagde-with-image {
  min-width: 120px;
  width: 100%;
  height: 32px;
  font-size: 12px;
  padding: 2px 5px 0 25px !important;
  display: flex;
  border-radius: 7px 0px 0px 0px !important;
  margin: 0 !important;
  margin-left: -5px;
}
.bagde-without-image {
  min-width: 120px;
  width: 100%;
  height: 32px;
  font-size: 12px; /* Ajusta el tamaño del texto según sea necesario */
  padding: 2px 5px 0 25px !important;
  border-radius: 7px 0px 0px 0px !important;
  margin: 0 !important;
}
.product-item {
  width: 100%;
  height: auto;
  min-height: 120px;
  padding: 16px;
  display: grid;
  grid-template-columns: auto 87px;
  column-gap: 10px;
  background: var(--q-bg-color);
  border: 1px solid #d9e3fe;
  border-radius: 5px;
  text-align: start;
}
.food-img {
  height: 93px;
  width: 93px;
  background-color: gray;
  border-radius: 4px !important;
  border: none;
  align-self: center;
}
.food-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  small {
    font-size: 12px;
  }
}
.info {
  max-height: 72px;
}
p {
  margin: 0;
  text-align: start;
  line-height: 12px;
}
small {
  margin-top: 5px;
  font-size: 12px;
  text-align: start;
  height: 41px;
  line-height: 21px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.add-btn {
  width: 36px;
  height: 36px !important;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0;
  border-radius: 5px 0px;
}
/* @media screen and (min-width: 1023px) {
  .food-item {
    min-width: 254px;
    width: 100%;
    max-width: 370px;
  }
}
@media screen and (min-width: 1359px) {
  .food-item {
    height: 157px;
    padding: 16px;
  }
  .add-button {
    height: 44px;
    width: 44px;
  }
} */
</style>
