const footerNavbar = {
  state: {
    businessName: null,
    currentNavbarButton: 0,
  },
  mutations: {
    setBusinessName(state, payload) {
      state.businessName = payload
    },
    setCurrentNavbarButton(state, payload) {
      state.currentNavbarButton = payload
    },
  },
  actions: {},
  getters: {
    getCurrentNavbarButton(state) {
      return state.currentNavbarButton
    },
    getFooterNavbarButtons(state) {
      return state.footerNavbarButtons
    },
  },
}
export default footerNavbar
