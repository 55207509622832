<template>
  <div class="rating-stars">
    <div class="rating-element">
      <button class="rating-btn" @click="rateStar(1)">
        <img :src="rate > 0 ? star : starEmpty" alt="" />
      </button>
      <small>Muy Insatisfecho</small>
    </div>
    <div class="rating-element">
      <button class="rating-btn" @click="rateStar(2)">
        <img :src="rate > 1 ? star : starEmpty" alt="" />
      </button>
      <small>Insatisfecho</small>
    </div>
    <div class="rating-element">
      <button class="rating-btn" @click="rateStar(3)">
        <img :src="rate > 2 ? star : starEmpty" alt="" />
      </button>
      <small>Neutral</small>
    </div>
    <div class="rating-element">
      <button class="rating-btn" @click="rateStar(4)">
        <img :src="rate > 3 ? star : starEmpty" alt="" />
      </button>
      <small>Satisfecho</small>
    </div>
    <div class="rating-element">
      <button class="rating-btn" @click="rateStar(5)">
        <img :src="rate > 4 ? star : starEmpty" alt="" />
      </button>
      <small>Muy Satisfecho</small>
    </div>
  </div>
</template>
<script>
import StarIcon from "@/assets/icons/star-full.svg"
import StarIconEmpty from "@/assets/icons/star-empty.svg"
export default {
  name: "rating-stars",
  props: {
    action: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      rate: 0,
      star: StarIcon,
      starEmpty: StarIconEmpty,
    }
  },
  methods: {
    rateStar(index) {
      this.rate = index
      this.action(index)
    },
  },
}
</script>
<style lang="scss" scoped>
.rating-stars {
  display: flex;
  justify-content: center;
}
.rating-btn {
  background-color: transparent;
  border: none;
}
.rating-element {
  display: flex;
  flex-direction: column;
  width: 60px;
  margin: 0 8px;

  img {
    width: 28px;
    height: 28px;
  }
  small {
    text-align: center;
    font-size: 10px;
  }
}
</style>
