<template>
  <section
    class="categories d-flex flex-column align-items-center"
    @click="collapseProfile"
  >
    <div v-if="buttons.length == 0" class="non-menu">
      Aun no hay un menu disponible para este Restaurante
    </div>
    <div v-else class="container-categories">
      <div class="input">
        <img src="@/assets/icons/search.svg" alt="" />
        <input
          type="text"
          placeholder="Busca un producto"
          v-model="text"
          @input="filterByText"
        />
      </div>
      <div v-if="isFiltered == false" class="buttons">
        <div v-for="(category, index) in buttons" :key="index">
          <category-button
            :text="category.text"
            :active="category.active"
            @click="toogleCategories(category.id)"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex"
import CategoryButton from "./CategoryButton.vue"
export default {
  components: {
    CategoryButton,
  },
  data() {
    return {
      coordinates: [],
      buttons: [],
      text: "",
    }
  },
  mounted() {
    this.createCategory()
  },
  computed: {
    ...mapGetters({
      isFiltered: "getIsFiltered",
      allProducts: "getAllProducts",
    }),
  },
  methods: {
    toogleCategories(id) {
      for (let index = 0; index < this.buttons.length; index++) {
        if (index == id) {
          this.buttons[index].active = true
          this.moveToCategory(index)
        } else {
          this.buttons[index].active = false
        }
      }
    },
    moveToCategory(index) {
      const scroll =
        window.scrollY +
        document
          .querySelector(`#${this.buttons[index - 1].text}`)
          .getBoundingClientRect().top
      window.scroll(0, scroll - 156)
    },
    createCategory() {
      for (let index = 0; index < this.allProducts.length; index++) {
        if (this.allProducts[index].products.length > 0) {
          this.buttons.push({
            text: this.allProducts[index].name,
            active: false,
            id: index,
          })
        }
      }
      if (this.buttons.length == 0) {
        return
      }
      this.buttons[0].active = true
      this.filterByText()
    },
    filterByText() {
      this.$store.commit("filterByText", this.text)
    },
  },
}
</script>
<style scoped>
.non-menu {
  width: 80%;
}
.container-categories {
  width: 100%;
  padding: 0 20px;
}
.input {
  width: 100%;
  position: relative;
}
.input img {
  position: absolute;
  top: 28px;
  left: 10px;
}
input {
  margin: 16px 0 0 0;
  height: 40px;
  width: 100%;
  border: 1px solid #f2f2f2;
  background: #f7f7f7;
  border-radius: 5px;
  padding-left: 40px;
}
.categories {
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  z-index: 99;
}
.buttons {
  display: flex;
  overflow: auto;
}
@media screen and (min-width: 767px) {
  .categories {
    background-color: transparent;
    box-shadow: none;
  }
  .container-categories {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }
  .input {
    width: 255px;
  }
  input {
    margin: 0;
  }
  .input img {
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
