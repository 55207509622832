export const setOrder = (state, payload) => {
  state.table = payload.table
  state.orderType = payload.orderType
}

export const addProduct = (state, payload) => {
  state.products.push(payload)
}
export const resetOrder = state => {
  state.products = []
}
export const removeProduct = (state, productId) => {
  for (let index = 0; index < state.products.length; index++) {
    const product = state.products[index]
    if (product.product.id === productId) {
      state.products.splice(index, 1)
    }
  }
}
export const updateProduct = (state, updatedProduct) => {
  for (let index = 0; index < state.products.length; index++) {
    const product = state.products[index]
    if (product.product.id === updatedProduct.product.id) {
      state.products[index] = updatedProduct
    }
  }
}
export const setOrderId = (state, payload) => {
  state.orderId = payload
}
export const setCurrentOrder = (state, payload) => {
  state.order = payload
}
export const updateTableNum = (state, payload) => {
  state.tableNum = payload
}

export const reset = state => {
  state.orderType = null
  state.products = []
  state.orderId = null
  state.order = []
  state.newTableNum = 0
}
