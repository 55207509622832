<template>
  <section id="scroll-list" class="menu-list">
    <div v-if="!noOneProduct" class="container-lists">
      <div
        class="category-list"
        v-for="(category, index) in categories"
        :key="index"
      >
        <div class="category-header flex-container">
          <h3
            :id="category.ref"
            class="category-title fs-18"
            style="color: var(--q-secondary);"
          >
            {{ category.name }}
          </h3>
          <q-chip
            v-if="!category.is_available"
            class="q-mx-none text-white"
            color="secondary"
            style="font-family: var(--q-body-font);"
            label="No disponible"
          ></q-chip>
        </div>
        <q-item-section class="mp-q-item-section" side> </q-item-section>
        <div
          v-if="
            category.description.length > 0 && category.products.length != 0
          "
          class="q-mb-sm text-left body-font body-color fs-16"
          style="font-weight: 400;"
        >
          {{ category.description }}
        </div>
        <div v-if="category.products.length > 0" class="list-by-category">
          <div v-for="item in category.products" :key="item.id">
            <ProductItem
              :openModal="toggleModalProduct"
              :food="item"
              :categoryAvailability="category.is_available"
            />
          </div>
        </div>
      </div>
      <div class="offset"></div>
    </div>
    <div v-else>
      <p>No fue posible encontrar un producto con ese nombre :(</p>
    </div>
  </section>
  <ModalProduct
    v-model="productModal"
    :currentProduct="currentProduct"
    :close="toggleModalProduct"
  />
  <ModalOnlyRead
    v-model="onlyReadModal"
    :productId="currentProduct"
    :close="toggleModalProduct"
  />
</template>
<script>
import { defineAsyncComponent } from "vue"
import { mapGetters } from "vuex"
import ProductItem from "./ProductItem.vue"
export default {
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ProductItem,
    ModalProduct: defineAsyncComponent(() =>
      import("../modals/ModalProduct.vue")
    ),
    ModalOnlyRead: defineAsyncComponent(() =>
      import("../modals/ModalOnlyRead.vue")
    ),
  },
  data() {
    return {
      productModal: false,
      onlyReadModal: false,
      maximizedToggle: true,
      currentProduct: null,
    }
  },
  computed: {
    ...mapGetters("socket", {
      phoneData: "getPhoneData",
    }),
    ...mapGetters({
      categories: "getFilteredCategories",
    }),
    ...mapGetters(["getBranchInfo"]),
    noOneProduct() {
      let products = 0
      for (let index = 0; index < this.categories.length; index++) {
        const element = this.categories[index]
        products = products + element.products.length
      }
      return products == 0
    },
  },
  methods: {
    toggleModalProduct(product) {
      const body = {
        product: product.id, // aqui va el id del producto
        client_id: this.phoneData.phone || null, // aqui va el numero de telefono en caso de que lo haya, si no lo hay entonces se puede enviar vacio
        sucursal: this.getBranchInfo[0].id, // aqui va la sucursal
      }
      this.$store.dispatch("mostLessProducts", body)
      if (this.onlyRead) {
        this.onlyReadModal = !this.onlyReadModal
      } else {
        this.productModal = !this.productModal
      }
      this.currentProduct = product
      this.$store.commit("setFoodConfig", product)
    },
  },
}
</script>
<style scoped>
.body-color {
  color: var(--q-body-color);
  /* color: white; */
}
.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Otra opción si es necesario centrar verticalmente */
}
.menu-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 60px; */
  padding: 0 20px;
  padding-top: 16px;
  background-color: var(--q-bg-contrast);
  /* overflow-y: auto; */
  /* padding-bottom: 50px; */
  scroll-behavior: smooth;
}

.container-lists {
  width: 100%;
  max-height: calc(100vh - 305px);
  overflow: visible;
}

.category-list {
  margin-bottom: 10px;
}

.category-title {
  text-align: start;
  margin-bottom: 10px;
}

.list-by-category {
  display: grid;
  row-gap: 21px;
}

p {
  margin-top: 20px;
  width: 100%;
}

.offset {
  height: 60px;
}

@media screen and (min-width: 767px) {
  .list-food {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 26px;
  }
}
</style>
