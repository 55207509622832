import api from "@/api"
import axios from "axios"

const client = {
  state: {
    clientInfo: null,
    cacheInfo: false,
    subscribeClientInfo: {},
  },
  mutations: {
    setSubscribeClientInfo(state, payload) {
      state.subscribeClientInfo = payload
    },
    setClientInfo(state, payload) {
      state.clientInfo = payload
    },
    remember(state) {
      state.cacheInfo = true
    },
    noRemember(state) {
      state.cacheInfo = false
    },
  },
  actions: {
    reviewsAnalytics({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${api}/r/${rootState.businessName}/v1/review-analitics`,
            payload
          )
          .then(
            response => {
              resolve(response)
            },
            error => {
              reject(error)
            }
          )
      })
    },
    totalSessions({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/r/${rootState.businessName}/v1/general-analytics`, {
            client_id: payload.client_id,
            frequent_customer: payload.frequent_customer,
            sucursal: payload.sucursal,
            type: payload.type,
          })
          .then(
            response => {
              resolve(response)
            },
            error => {
              reject(error)
            }
          )
      })
    },
    editSubscribeClient({ rootState }, payload) {
      return new Promise((resolve, reject) => {
        const phoneNumber = `+1${localStorage.getItem("persistedPhone")}`
        axios
          .patch(
            `${api}/r/${rootState.businessName}/v1/clients/client_update`,
            {
              phone_number: phoneNumber,
              first_name: payload.first_name,
              last_name: payload.last_name,
              email: payload.email,
              birthday: payload.birthday,
              zip_code: payload.zipcode,
              subscribe_email: payload.subscribe_email,
              subscribe_sms: payload.subscribe_sms,
            }
          )
          .then(
            response => {
              resolve(response)
            },
            error => {
              reject(error)
            }
          )
      })
    },
    subscribeClient({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/r/${rootState.businessName}/v1/clients`, {
            phone_number: payload.phone_number,
            subscribe: payload.subscribe,
            source: payload.source,
          })
          .then(
            response => {
              commit("setSubscribeClientInfo", response.data)
              resolve(response)
            },
            error => {
              reject(error)
            }
          )
      })
    },
    createClient({ commit, rootState }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/r/${rootState.businessName}/v1/clients`, {
            phone_number: payload,
          })
          .then(
            response => {
              commit("setClientInfo", response.data)
              resolve(response)
            },
            error => {
              reject(error)
            }
          )
      })
    },
  },
  getters: {
    getSubscribeClientInfo(state) {
      return state.clientInfo
    },
    getClientInfo(state) {
      return state.clientInfo
    },
    getCacheInfo(state) {
      return state.cacheInfo
    },
  },
}

export default client
