<template>
  <div class="pay-methods">
    <h6>Método de Pago</h6>
    <p>
      Elige la forma en que deseas pagar para que un mesero se acerque a ti.
    </p>
    <div class="pay-buttons">
      <button
        :class="payMethod == 0 ? 'pay-button active cash' : 'pay-button cash'"
        @click="togglePayMethod(0)"
      >
        <img :src="payMethod == 0 ? iconCashWhite : iconCashDark" alt="" />
        <p>Efectivo</p>
      </button>
      <button
        :class="payMethod == 1 ? 'pay-button active' : 'pay-button'"
        @click="togglePayMethod(1)"
      >
        <img
          :src="payMethod == 1 ? iconDatafonoWhite : iconDatafonoDark"
          alt=""
        />
        <p>Datáfono</p>
      </button>
    </div>
    <div class="input">
      <div class="label">
        <label>¿Con cuánto vas a pagar?</label>
        <p>Opcional</p>
      </div>
      <input type="number" placeholder="$0" v-model="mountToPay" />
      <div class="money-symbol">
        $
      </div>
    </div>
  </div>
</template>
<script>
import datafonoDark from "@/assets/icons/datafono.svg"
import datafonoWhite from "@/assets/icons/datafono-white.svg"
import cashDark from "@/assets/icons/cash.svg"
import cashWhite from "@/assets/icons/cash-white.svg"
export default {
  data() {
    return {
      payMethod: -1,
      iconDatafonoDark: datafonoDark,
      iconDatafonoWhite: datafonoWhite,
      iconCashDark: cashDark,
      iconCashWhite: cashWhite,
      mountToPay: 0,
    }
  },
  methods: {
    togglePayMethod(index) {
      this.payMethod = index
      this.$store.commit("setPayMethod", index)
    },
  },
}
</script>
<style scoped>
.pay-methods {
  margin-top: 20px;
}
.pay-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.pay-button {
  height: 55px;
  width: 134px;
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
button img {
  width: 25px;
}
.pay-button:first-child img {
  margin-right: 5px;
}
.active {
  background-color: #333333;
}
.active p {
  color: white;
}
.input {
  margin-top: 18px;
  position: relative;
}
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
label {
  font-weight: 700;
}
.label p {
  opacity: 0.5;
}
input {
  width: 100%;
  margin-top: 8px;
  padding-left: 20px;
}
.money-symbol {
  position: absolute;
  bottom: 10px;
  left: 10px;
}
</style>
