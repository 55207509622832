const recomendations = {
  state: {
    recomendedProducts: null,
    currentRecomendedProduct: null,
  },
  mutations: {
    setRecomendedProducts(state, payload) {
      state.recomendedProducts = payload
    },
    setCurrentRecomendedProduct(state, payload) {
      state.currentRecomendedProduct = payload
    },
  },
  actions: {},
  getters: {
    getRecomendedProducts(state) {
      return state.recomendedProducts
    },
    getCurrentRecomendedProduct(state) {
      return state.currentRecomendedProduct
    },
  },
}
export default recomendations
