export const getTable = state => {
  return state.table
}
export const getProducts = state => {
  return state.products
}
export const getEatHereProducts = state => {
  const products = state.products.filter(product => product.order_type === 3)
  return products
}
export const getTakeAwayProducts = state => {
  const products = state.products.filter(product => product.order_type === 1)
  return products
}
export const getOrderId = state => {
  return state.orderId
}
export const getOrder = state => {
  return state.order
}
export const getPreparingProducts = state => {
  return state.order.length > 0 && state.order[0].order_status > 1
    ? state.order[0].order_products.filter(
        product => product.product_status > 1 && product.product_status < 4
      )
    : []
}

export const getTableNum = state => {
  state.tableNum
}

export const getDeliveredProducts = state => {
  return state.order.length > 0
    ? state.order[0].order_products.filter(
        product => product.product_status > 3
      )
    : []
}
