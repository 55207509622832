<template>
  <div class="help-view">
    <q-img
      src="@/assets/images/helpview.png"
      height="140px"
      width="140px"
      class="q-mb-xl"
    />
    <h3
      class="q-mb-sm text-secondary fs-22 title-font"
      style="font-weight: 600;"
    >
      ¿Necesitas ayuda?
    </h3>
    <p style="margin-bottom: 30px;" class="fs-16 body-font">
      Selecciona el tipo de ayuda que necesitas
    </p>
    <q-btn
      class="fw q-mb-md body-font fs-16"
      color="secondary"
      unelevated
      no-caps
      @click="onClickServiceButton('server')"
      label="Solicitar mesero"
      :disable="counting || gettingLocation || !isWithinRange"
    >
      <vue-countdown
        class="q-ml-md"
        v-if="counting"
        :time="timeRemaining"
        @end="onCountdownEnd"
        v-slot="{ minutes, seconds }"
      >
        <vue-countdown
          v-if="counting"
          :time="timeRemaining"
          @end="onCountdownEnd"
          v-slot="{ totalSeconds }"
        >
          <q-circular-progress
            show-value
            class="text-white q-ma-xs"
            :value="totalSeconds"
            :min="0"
            :max="300"
            size="30px"
            color="white"
            :thickness="0.05"
          >
            {{ minutes }} : <template v-if="seconds < 10">0</template
            >{{ seconds }}
          </q-circular-progress>
        </vue-countdown>
      </vue-countdown>
    </q-btn>

    <q-btn
      class="fw q-mb-md text-secondary body-font fs-16"
      color="primary"
      style="border: 1px solid var(--q-secondary);"
      outline
      label="Pedir la cuenta"
      no-caps
      @click="onClickServiceButton('checkout')"
      :disable="counting2 || gettingLocation || !isWithinRange"
    >
      <vue-countdown
        class="q-ml-md"
        v-if="counting2"
        :time="timeRemaining2"
        @end="onCountdownEnd2"
        v-slot="{ minutes, seconds }"
      >
        <vue-countdown
          v-if="counting2"
          :time="timeRemaining2"
          @end="onCountdownEnd2"
          v-slot="{ totalSeconds }"
        >
          <q-circular-progress
            show-value
            class="text-black q-ma-xs"
            :value="totalSeconds"
            :min="0"
            :max="300"
            size="30px"
            color="black"
            :thickness="0.05"
          >
            {{ minutes }} : <template v-if="seconds < 10">0</template
            >{{ seconds }}
          </q-circular-progress>
        </vue-countdown>
      </vue-countdown>
    </q-btn>
    <!-- <q-btn
      class="fw"
      color="primary"
      flat
      label="Calificar servicio"
      no-caps
      @click="toggleRatingModal"
    /> -->
  </div>
  <ModalErrorNotificationsCol v-model="visiblity.modalError" />
  <ModalPhoneRequiredCol
    v-model="visiblity.requiredInformation"
    :onRequestService="onRequestService"
    :serviceName="serviceName"
  />
  <ModalLocationRequiredCol v-model="visiblity.requiredLocation" />
  <!-- <ModalRating v-model="ratingModal" :openAlert="toggleModalGratitude" />
  <ModalRequestCheck v-model="requestCheckModal" :requestBill="onBillRequest" />
  <ModalGratitude v-model="gratitudeModal" /> -->
</template>

<script>
import { defineAsyncComponent } from "vue"
import { setCssVar } from "quasar"
import VueCountdown from "@chenfengyuan/vue-countdown"
import { mapGetters } from "vuex"
import { useQuasar } from "quasar"
import { mapActions } from "vuex"
export default {
  components: {
    // ModalRating: defineAsyncComponent(() =>
    //   import("../modals/ModalRating.vue")
    // ),
    // ModalGratitude: defineAsyncComponent(() =>
    //   import("../modals/ModalGratitude.vue")
    // ),
    // ModalRequestCheck: defineAsyncComponent(() =>
    //   import("../modals/ModalRequestCheck.vue")
    // ),
    ModalErrorNotificationsCol: defineAsyncComponent(() =>
      import("../modals/ModalErrorNotificationsCol.vue")
    ),
    ModalLocationRequiredCol: defineAsyncComponent(() =>
      import("../modals/ModalLocationRequiredCol.vue")
    ),
    ModalPhoneRequiredCol: defineAsyncComponent(() =>
      import("../modals/ModalPhoneRequiredCol.vue")
    ),
    VueCountdown,
  },
  data() {
    return {
      logoutTime: null,
      gettingLocation: false,
      timeRemaining: 0,
      timeRemaining2: 0,
      isWithinRange: false,
      ratingModal: false,
      requestCheckModal: false,
      gratitudeModal: false,
      cdMinutes: 5,
      cdSeconds: 60,
      visiblity: {
        requiredInformation: false,
        requiredLocation: false,
        modalError: false,
      },
      counting: false,
      counting2: false,
    }
  },
  mounted() {
    if (this.getRequestServer) this.initializeCountdown()
    if (this.getRequestCheck) this.initializeCountdown2()
    this.getupdatedLocation()
    this.startLogoutTime()
    this.setModalError()
    this.fetchBranchDetails()
    this.fetchBranchInfo()
    this.fetchBranchConfig()
  },
  watch: {
    getSocketError() {
      this.setModalError()
    },
  },
  computed: {
    ...mapGetters(["getRequestServer", "getRequestCheck"]),
    ...mapGetters(["getBusinessInfo", "getSocketConnection"]),
    ...mapGetters("socket", ["getLocation", "getSocketError"]),
    ...mapGetters("currentOrder", [
      "getOrderId",
      "getPreparingProducts",
      "getDeliveredProducts",
    ]),
    ...mapGetters("socket", {
      connectionId: "getConnectionId",
      socket: "getSocket",
      phoneData: "getPhoneData",
    }),
    disableBtn() {
      return (
        this.getDeliveredProducts.length === 0 &&
        this.getPreparingProducts.length === 0
      )
    },
  },
  methods: {
    ...mapActions("socket", ["fetchLocation"]),
    fetchBranchInfo() {
      this.$store.dispatch("fetchBranchInfo")
      this.$store.dispatch("fetchBusinessInfo")
    },
    setModalError() {
      this.visiblity.modalError = !!this.getSocketError
    },

    showModalError(errorMessage) {
      // aqui helpview escucha ese error y por medio de la ref del modal entonces lo abre
      this.$refs.errorModal.show(errorMessage)
    },
    startLogoutTime() {
      this.logoutTimer = setTimeout(() => {
        this.checkAndLogout()
      }, 14400000) // 4 horas en milisegundos
    },
    checkAndLogout() {
      if (!this.isWithinRange || !this.location) {
        // Realizar la desconexión aquí

        this.$router.push({ name: "logout" }) // aqui va el logout de Col
      }
    },
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data[0]

          setCssVar("secondary", styles?.color || " #03257E") // botones activos,required&notavailable bagde,skip & go menu,titulos,algunos precios
        }
        this.loading = false
      })
    },
    fetchBranchDetails() {
      this.$store.dispatch("fetchBranchDetails").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data || resp.detail || {}
          setCssVar("bg-color", styles?.detail?.color_background) //esto es el background
          setCssVar("disable-btn", styles?.detail?.disable_buttons) // esto para btn desactivados
          setCssVar("body-color", styles?.detail?.body_color) // esto para los subtitulos
          setCssVar("body-font", styles?.detail?.body_type)
          setCssVar("title-font", styles?.detail?.title_type)
          setCssVar("bg-nav", styles?.detail?.background_navigation)
          setCssVar("bg-contrast", styles?.detail?.background_contrast)
          // setCssVar("review-link", styles?.detail?.review_link)
        }
      })
    },
    toggleRatingModal() {
      this.ratingModal = !this.ratingModal
    },
    toggleRequestCheckModal() {
      this.requestCheckModal = !this.requestCheckModal
    },
    toggleModalGratitude() {
      this.gratitudeModal = !this.gratitudeModal
    },
    initializeCountdown() {
      const cadenaDeTiempo = this.getRequestServer
      const currentHour = new Date()
      const oldHour = new Date() // Obtiene la fecha y hora actual
      const [hora, minutos, segundos] = cadenaDeTiempo.split(":") // Divide la cadena en hora, minutos y segundos
      oldHour.setHours(hora, minutos, segundos)
      const diferenciaEnMilisegundos = currentHour - oldHour

      // Calcular 5 minutos en milisegundos (5 * 60 * 1000)
      const cincoMinutosEnMilisegundos = 5 * 60 * 1000

      if (this.$store.state.socket.phoneId && this.$store.state.socket.phone) {
        if (diferenciaEnMilisegundos > cincoMinutosEnMilisegundos) {
          // Si han pasado más de 5 minutos, haz algo
          this.counting = false

          // Ejecuta tu acción aquí
        } else {
          this.counting = true
          this.timeRemaining =
            cincoMinutosEnMilisegundos - diferenciaEnMilisegundos
        }
      }
    },
    initializeCountdown2() {
      const cadenaDeTiempo = this.getRequestCheck
      const currentHour = new Date()
      const oldHour = new Date() // Obtiene la fecha y hora actual
      const [hora, minutos, segundos] = cadenaDeTiempo.split(":") // Divide la cadena en hora, minutos y segundos
      oldHour.setHours(hora, minutos, segundos)
      const diferenciaEnMilisegundos = currentHour - oldHour

      // Calcular 5 minutos en milisegundos (5 * 60 * 1000)
      const cincoMinutosEnMilisegundos = 5 * 60 * 1000
      if (diferenciaEnMilisegundos > cincoMinutosEnMilisegundos) {
        // Si han pasado más de 5 minutos, haz algo
        this.counting2 = false

        // Ejecuta tu acción aquí
      } else {
        this.counting2 = true
        this.timeRemaining2 =
          cincoMinutosEnMilisegundos - diferenciaEnMilisegundos
      }
    },
    async startCountdown() {
      const horaActual = new Date().toLocaleTimeString()

      this.$store.commit("setRequestServer", horaActual)

      // this.getLocation()
      try {
        const horaActual = new Date().toLocaleTimeString()

        this.$store.commit("setRequestServer", horaActual)
        // if (!this.phoneData.phoneId && !this.phoneData.phone) {
        //   this.visiblity.requiredInformation = true
        //   return
        // }
        this.counting = true
        this.initializeCountdown()
        this.showNotifs()
        setTimeout(() => {
          if (this.counting) this.onCallWaiter()
        }, 5000)
      } catch (error) {
        console.error("Error fetching location:", error)
      }
    },
    getupdatedLocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available."
        return
      }
      this.gettingLocation = true
      // get position
      navigator.geolocation.getCurrentPosition(
        pos => {
          this.gettingLocation = false
          this.location = {
            accuracy: String(pos.coords.accuracy),
            altitude: String(pos.coords.altitude),
            altitudeAccuracy: String(pos.coords.altitudeAccuracy),
            heading: String(pos.coords.heading),
            latitude: String(pos.coords.latitude),
            longitude: String(pos.coords.longitude),
            speed: String(pos.coords.speed),
          }

          this.fetchLocation(this.location).then(response => {
            if (response.data.within_range === false) {
              // this.$router.push({ name: "us-logout" }) // esto deberia pasar solo despues de 4 horas de volver a validaer ubicación
              this.visiblity.requiredLocation = true
            } else {
              this.isWithinRange = true
            }
          })

          // Manejo de error de getCurrentPosition
        },
        err => {
          this.gettingLocation = false
          this.visiblity.requiredLocation = true
          this.errorStr = err.message
        }
      )
    },
    // startCountdown() {
    //   const horaActual = new Date().toLocaleTimeString()

    //   this.$store.commit("setRequestServer", horaActual)
    //   const locationData = this.getLocation

    //   if (!this.phoneData.phoneId && !this.phoneData.phone) {
    //     this.visiblity.requiredInformation = true
    //     return
    //   }
    //   this.counting = true
    //   this.showNotifs()
    //   this.onCallWaiter()
    //   this.initializeCountdown()
    // },
    startCountdown2() {
      const horaActual = new Date().toLocaleTimeString()
      this.$store.commit("setRequestCheck", horaActual)
      // if (!this.phoneData.phoneId && !this.phoneData.phone) {
      //   this.visiblity.requiredInformation = true
      //   return
      // }
      this.counting2 = true
      this.initializeCountdown2()
      this.showNotifs2()
      setTimeout(() => {
        if (this.counting2) this.onBillRequest()
      }, 5000)
    },

    onCountdownEnd() {
      this.counting = false
    },
    onCountdownEnd2() {
      this.counting2 = false
    },
    onCallWaiter() {
      // if (!this.phoneData.phoneId && !this.phoneData.phone) {
      //   this.visiblity.requiredInformation = true
      //   return
      // }

      this.getupdatedLocation()

      if (this.counting === true) {
        this.socket.send(
          JSON.stringify({
            action: "call_Waiter",
            restaurant_location_id: this.getBusinessInfo.id,
            restaurant_name: this.getBusinessInfo.name,
            restaurant_id: this.getBusinessInfo.name,
            table: this.$route.params.table,
            order_id: this.getOrderId,
            phone_number: this.phoneData.phone,
            phone_id: this.phoneData.phoneId,
          })
        )
      }
      // this.socket.send(
      //   JSON.stringify({
      //     action: "call_Waiter",
      //     restaurant_location_id: this.getBusinessInfo.id,
      //     restaurant_name: this.getBusinessInfo.name,
      //     restaurant_id: this.getBusinessInfo.name,
      //     table: this.$route.params.table,
      //     order_id: this.getOrderId,
      //     phone_number: this.phoneData.phone,
      //     phone_id: this.phoneData.phoneId,
      //   })
      // )
    },
    onBillRequest() {
      // if (!this.phoneData.phoneId && !this.phoneData.phone) {
      //   this.visiblity.requiredInformation = true
      //   return
      // }
      this.getupdatedLocation()

      if (this.phoneData.phoneId && this.phoneData.phone) {
        this.socket.send(
          JSON.stringify({
            action: "order_Bill",
            restaurant_location_id: String(this.getBusinessInfo.id),
            restaurant_name: this.getBusinessInfo.name,
            table: this.$route.params.table,
            order_id: this.getOrderId,
            payment_type: "1",
            setTip: "1",
            phone_number: String(this.phoneData.phone),
            phone_id: String(this.phoneData.phoneId),
          })
        )
      } else {
        this.visiblity.requiredInformation = true
      }
    },
    onClickServiceButton(serviceName) {
      this.serviceName = serviceName
      if (!this.location) {
        this.visiblity.requiredLocation = true
        return
      }
      // if (!this.phoneData.phoneId && !this.phoneData.phone) {
      //   this.visiblity.requiredInformation = true
      //   return
      // }
      this.onRequestService(serviceName)
    },
    onRequestService(serviceName) {
      if (serviceName == "server") {
        this.startCountdown()
      } else if (serviceName == "checkout") {
        this.startCountdown2()
      }
    },
  },
  setup() {
    const $q = useQuasar()

    return {
      showNotifs() {
        $q.notify({
          progress: true,
          message: "Solicitando mesero...",
          // icon: "mail",
          color: "dark",
          textColor: "white",
          actions: [
            {
              label: "Cancelar",
              style: "text-decoration: underline",
              color: "yellow",
              noCaps: true,
              handler: () => {
                this.onCountdownEnd()
              },
            },
          ],
        })
      },
      showNotifs2() {
        $q.notify({
          progress: true,
          message: "Solicitando la cuenta",
          // icon: "mail",
          color: "dark",
          textColor: "white",
          actions: [
            {
              label: "Cancelar",
              style: "text-decoration: underline",
              color: "yellow",
              noCaps: true,
              handler: () => {
                this.onCountdownEnd2()
              },
            },
          ],
        })
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.title-font {
  font-family: DM Sans;
}
.body-font {
  font-family: Fira Sans !important;
  font-size: 17px;
}

.help-view {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--q-bg-contrast);
}

.help-img {
  height: 144px;
  max-width: 182px;
  margin-top: 52px;
}
</style>
