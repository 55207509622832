export const setSocket = (state, payload) => {
  state.socket = payload
  state.newConnection = false
}
export const setError = (state, error) => {
  state.error = error
}
export const setConnectionId = (state, payload) => {
  state.connectionId = payload
}
export const setNewConnection = state => {
  state.newConnection = true
}
export const setPhoneData = (state, payload) => {
  state.phone = payload.phone
  state.phoneId = payload.phoneId
}
export const setLocation = (state, location) => {
  state.location = location.data.within_range
}

export const reset = state => {
  state.socket.close()
  state.connectionId = null
  state.newConnection = false
  state.phone = ""
  state.phoneId = ""
}
