<template>
  <section class="food-item">
    <div :class="noImg ? 'food-info fw' : 'food-info '" @click="openModal">
      <div
        :class="
          noImg ? 'd-flex flex-column info fw' : 'd-flex flex-column info'
        "
      >
        <p>{{ name }}</p>
        <small>{{ food.description }}</small>
      </div>
      <div class="food-item--footer">
        <p>$ {{ new Intl.NumberFormat("es-CO").format(food.total_price) }}</p>
      </div>
    </div>
    <img v-if="!noImg" class="food-img" :src="food.image" alt="" />
    <button class="primary-button add-button" @click="openModal">
      <img src="@/assets/icons/add-cross.svg" alt="add-icon" />
    </button>
  </section>
</template>
<script>
export default {
  name: "product-vue",
  props: {
    food: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      const name = this.food.name.substring(0, 36)
      return name
    },
    description() {
      const description = this.food.description.substring(0, 58)
      return description
    },
    noImg() {
      return this.food.image === null
    },
  },
  methods: {
    openModal() {
      this.$store.commit("setFoodConfig", this.food.id)
      window.$("#modalFood").modal("show")
    },
  },
}
</script>
<style scoped>
.food-item {
  height: 107px;
  min-width: 100%;
  cursor: pointer;
  background: #fcfcfc;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 9px;
  margin-top: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}
.food-img {
  height: 84px;
  min-height: 84px;
  width: 84px;
  min-width: 84px;
  background-color: gray;
  border-radius: 5px;
  border: none;
  align-self: center;
}
.food-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 8px;
}
.info {
  max-height: 72px;
}
p {
  font-weight: 700;
  margin: 0;
  text-align: start;
  line-height: 12px;
}
small {
  margin-top: 5px;
  font-size: 12px;
  text-align: start;
  height: 28px;
  line-height: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.food-item--footer {
  width: 100%;
  justify-self: flex-end;
}
.food-item--footer p {
  font-weight: 400;
}
.add-button {
  width: 36px;
  height: 36px;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0;
}
@media screen and (min-width: 1023px) {
  .food-item {
    min-width: 254px;
    width: 100%;
    max-width: 370px;
  }
}
@media screen and (min-width: 1359px) {
  .food-item {
    height: 157px;
    padding: 16px;
  }
  .add-button {
    height: 44px;
    width: 44px;
  }
}
</style>
