import "./registerServiceWorker"
import App from "./App.vue"
import VueAxios from "vue-axios"
import axios from "axios"
import bootstrap from "bootstrap"
import { createApp } from "vue"
import router from "./router"
import store from "./store"
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from "./quasar-user-options"
import i18n from "./locales/i18n"
import "./styles/main.scss"
const app = createApp(App)

const userLanguage = navigator.language.slice(0, 2) // Extract the first two characters for language code
// Ensure that the user's language preference is supported in your i18n configuration
if (i18n.global.availableLocales.includes(userLanguage)) {
  i18n.global.locale.value = userLanguage
}
// import { mdiAccount } from "@mdi/js";
const EventBus = {
  emit(event, ...args) {
    app.config.globalProperties.$emit(event, ...args)
  },
  on(event, callback) {
    app.config.globalProperties.$on(event, callback)
  },
  off(event, callback) {
    app.config.globalProperties.$off(event, callback)
  },
}

app.config.globalProperties.$eventBus = EventBus
app
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(bootstrap)
  .use(i18n)
  // .use(mdiAccount)
  .mount("#app")
