<template>
  <div class="profile">
    <div v-for="button in buttons" :key="button.id">
      <button>
        <img :src="button.icon" alt="" /> {{ button.name }}
        <div v-if="!button.active" class="soon">(PRONTO)</div>
      </button>
    </div>
  </div>
</template>
<script>
import IconProfile from "@/assets/icons/icon-profile.svg"
import IconCard from "@/assets/icons/icon-card.svg"
import IconHistory from "@/assets/icons/icon-history.svg"
import IconPaper from "@/assets/icons/icon-paper.svg"
import IconExit from "@/assets/icons/icon-exit.svg"
import { mapMutations } from "vuex"
export default {
  name: "profile-view",
  data() {
    return {
      buttons: [
        { name: "Perfil", id: 0, icon: IconProfile, active: true },
        { name: "Método de pago", id: 1, icon: IconCard, active: false },
        {
          name: "Historial de Pedidos",
          id: 2,
          icon: IconHistory,
          active: true,
        },
        {
          name: "Términos y Condiciones",
          id: 3,
          icon: IconPaper,
          active: true,
        },
        { name: "Cerrar sesión", id: 4, icon: IconExit, active: true },
      ],
    }
  },
  methods: {
    ...mapMutations({
      toggleButtons: "setCurrentNavbarButton",
    }),
  },
  created() {
    this.toggleButtons(3)
  },
}
</script>
<style scoped>
.profile {
  padding: 20px;
}
button {
  width: 280px;
  text-align: start;
  position: relative;
  background-color: transparent;
  border: none;
}
.soon {
  position: absolute;
  right: 10px;
  top: 0;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 13px;
  transform: translateY(75%);
}
</style>
