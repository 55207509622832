<template>
  <div class="app">
    <router-view />
    <!-- <modal-order /> -->
    <div id="modalOrder"></div>
    <div id="toast"></div>
    <div id="modalSoldOut"></div>
    <!-- <modal-waiter />
    <modal-access-error />
    <modal-food />
    <modal-order-product />
    <modal-status />
    <modal-perquisite />
    <modal-pay-process />
    <modal-bill />
    <modal-phone-verification />

    <modal-order-confirmed /> -->
  </div>
</template>
<script>
// import ModalOrder from "./modals/ModalOrder.vue";
// Modals
// import ModalWaiter from "@/modals/ModalWaiter.vue";
// import ModalAccessError from "@/modals/ModalAccessError.vue";
// import ModalFood from "@/modals/ModalFood.vue";
// import ModalOrderProduct from "@/modals/ModalOrderProduct.vue";
// import ModalStatus from "@/modals/ModalStatus.vue";
// import ModalPerquisite from "@/modals/ModalPerquisite.vue";
// import ModalPayProcess from "@/modals/ModalPayProcess.vue";
// import modalBill from "@/modals/ModalBill.vue";
// import ModalPhoneVerification from "@/modals/ModalPhoneVerification.vue";

// import ModalOrderConfirmed from "@/modals/ModalOrderConfirmed";

export default {
  name: "App",
  data() {
    return {
      stylesFontsVariables: [],
      isMobile: false,
    }
  },
  created() {
    this.setBusinessInfo()
  },
  mounted() {},
  watch: {
    "$route.params.business": "setBusinessInfo",
    "$route.params.id": "setBusinessInfo",
  },
  methods: {
    setBusinessInfo() {
      const business = this.$route.params.business
      const id = this.$route.params.id

      if (business && id) {
        this.$store.commit("setBusinessName", business)
        this.$store.commit("setIdBranch", id)
        this.fetchStyles()

        this.fetchBranchConfig()
      }
    },
    handleResize() {
      // Actualiza isMobile basado en el ancho de la pantalla
      this.isMobile = window.innerWidth <= 800 // Puedes ajustar el umbral según tus necesidades
      this.applyStyles(this.isMobile ? "Mobile" : "Desktop")
    },
    applyStyles(device = "Mobile") {
      const stylesLocal = this.stylesFontsVariables.find(
        font => font.device_type === device
      )
      const styles = {
        // aqui pondria al body, ya sea p,span,small,etc
        p: {
          fontFamily: stylesLocal.body_font_name,
          fontSize: `${stylesLocal.body_font_size}rem`,
          fontWeight: stylesLocal?.body_font_weight,
          color: stylesLocal.body_color?.accent,
          colorSecondary: stylesLocal.body_color?.secondary,
        },
        small: {
          fontFamily: stylesLocal.body_font_name,
          fontSize: `${stylesLocal.body_font_size}rem`,
          fontWeight: stylesLocal?.body_font_weight,
          color: stylesLocal.body_color?.accent,
          colorSecondary: stylesLocal.body_color?.secondary,
        },
        span: {
          fontFamily: stylesLocal.body_font_name,
          fontSize: `${stylesLocal.body_font_size}rem`,
          fontWeight: stylesLocal?.body_font_weight,
          color: stylesLocal.body_color?.accent,
          colorSecondary: stylesLocal.body_color?.secondary,
        },
      }
      // Itera sobre los diferentes tamaños de encabezados
      ;["h1", "h2", "h3", "h4", "h5", "h6"].forEach(h => {
        styles[h] = {
          fontFamily: stylesLocal[`${h}_font_name`],
          fontSize: `${stylesLocal[`${h}_font_size`]}rem`,
          fontWeight: stylesLocal[`${h}_font_weight`],
          color: stylesLocal[`${h}_color`]?.accent,
          colorSecondary: stylesLocal[`${h}_color`]?.secondary,
        }
      })

      this.$store.commit("setStyles", styles)
    },

    async fetchStyles() {
      this.$store.dispatch("fetchStyles").then(resp => {
        this.stylesFontsVariables = resp
        this.applyStyles(window.innerWidth <= 800 ? "Mobile" : "Desktop")
      })
    },
    async fetchBranchConfig() {
      await this.$store.dispatch("fetchBranchConfig").then(resp => {
        const arrowColor = resp.data.html_color_palette.Accent
        const secondArrowColor = resp.data.html_color_palette.Primary
        const bagdeColor = resp.data.html_color_palette.Surface1
        const textBagdeColor = resp.data.html_color_palette.Primary
        const borderRadius = resp.data.radius
        const stroke = resp.data.html_color_palette.Stroke

        document.documentElement.style.setProperty(
          "--q-arrow-color",
          arrowColor
        )
        document.documentElement.style.setProperty(
          "--q-second-arrow-color",
          secondArrowColor
        )

        document.documentElement.style.setProperty(
          "--q-bagde-color",
          bagdeColor
        )

        document.documentElement.style.setProperty(
          "--q-text-bagde-color",
          textBagdeColor
        )
        document.documentElement.style.setProperty(
          "--q-border-radius",
          borderRadius
        )
        document.documentElement.style.setProperty("--q-stroke", stroke)
      })
    },
  },
}
</script>
<style>
/* button:focus {
  outline: none;
}
button {
  font-family: "DM Sans", sans-serif !important;
  font-size: 16px;
  padding: 0;
}
.primary-button {
  background-color: #02c874;
  color: #fff;
  border: none;
  border-radius: 5px;
  height: 45px;
  font-weight: 700;
}
.primary-button:active {
  background-color: #00b568;
}
.primary-button:disabled {
  background-color: #828282;
}
.secondary-button {
  background-color: #fff;
  color: #02c874;
  border: 1px solid #02c874;
  border-radius: 5px;
  height: 45px;
}
.secondary-button:hover {
  color: #109a5f;
  border: 1px solid #109a5f;
}
.secondary-button:active {
  background-color: rgba(2, 200, 116, 0.09);
  color: #02c874;
  border: 1px solid #02c874;
}
.danger-button {
  background-color: #cb3734;
  color: #fff;
  border: none;
  border-radius: 5px;
  height: 45px;
}
.danger-button:hover {
  background-color: #a8201e;
}
.danger-button:active {
  border: 2px solid #b62320;
}

.black-button {
  background-color: #222237;
  color: #fff;
  border: none;
  border-radius: 5px;
  height: 45px;
}
.black-button:hover {
  border: 2px solid #161616;
}
.black-button:active {
  background: #626262;
}
::placeholder {
  opacity: 0.5;
}
select:focus {
  outline: none;
}
select {
  font-family: "DM Sans", sans-serif !important;
  padding-left: 12px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  height: 45px;
}
a {
  font-weight: bold;
  color: #02c874;
  text-decoration: none;
}

a.router-link-exact-active {
  font-weight: bold;
  color: #42b983;
}
.wrapper {
  width: 280px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
}
h1 {
  font-size: 24px;
  font-weight: 700;
  color: #282828;
}
h2 {
  font-size: 18px;
  font-weight: 700;
}
h3 {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
h4 {
  font-size: 24px;
  font-weight: 700;
}
h5 {
  font-size: 20px;
  font-weight: 700;
}
h6 {
  font-size: 16px;
  font-weight: 700;
}
p {
  line-height: 21px;
  margin: 0;
  color: #343434;
}
small {
  font-weight: 400;
  font-size: 12px;
}
span {
  font-weight: 700;
}
.link {
  font-weight: 700;
  color: #02c874;
  cursor: pointer;
}

.all-height {
  height: 100vh;
}

.fh {
  height: 100%;
}
.box {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card {
  background: #fefefe;
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
}

.modal-dialog {
  border-radius: 10px;
} */
</style>
