<template>
  <div class="current-order">
    <h6 v-if="productsToEatHere.length > 0">PARA COMER AQUÍ</h6>
    <div v-for="(product, index) in products" :key="product.id">
      <order-product
        v-if="product.order_type == 3"
        :product="product"
        :index="index"
      />
      <!-- <hr v-if="index != productsToEatHere.length - 1" /> -->
    </div>
    <h6 v-if="productsToCarry.length > 0">PARA COMER LLEVAR</h6>
    <div v-for="(product, index) in products" :key="product.id">
      <order-product
        v-if="product.order_type == 1"
        :product="product"
        :index="index"
      />
      <!-- <hr v-if="index != productsToCarry.length - 1" /> -->
    </div>
    <nelkir-button class="fw" @click="loadOrder"> Pedir ahora </nelkir-button>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import OrderProduct from "./OrderProduct.vue"
import NelkirButton from "@/components/tools/buttons/NelkirButton.vue"
export default {
  name: "CurrentOrder",
  components: {
    OrderProduct,
    NelkirButton,
  },
  computed: {
    ...mapGetters({
      products: "getOrderProducts",
      clientInfo: "getClientInfo",
      restaurant: "getRestaurant",
      orderId: "getOrderId",
      idTable: "getIdTable",
    }),
    productsToEatHere() {
      return this.products.filter(element => element.order_type == 3)
    },
    productsToCarry() {
      return this.products.filter(element => element.order_type == 1)
    },
  },
  methods: {
    loadOrder() {
      if (this.orderId === null) {
        this.createOrder(this.getInfo())
      } else {
        this.$store
          .dispatch("addProductToOrder", {
            order_products: this.getUpdateFood(),
          })
          .then(response => {
            if (response.status == 200) {
              this.$store.commit("emptyCart")
            }
          })
      }
    },
    createOrder() {
      this.$store.dispatch("createOrder", this.getInfo()).then(response => {
        if (response.status == 200) {
          window.$("#modalOrderConfirmed").modal("show")
          this.$store.commit("emptyCart")
        }
      })
    },
    getInfo() {
      return {
        client: 1,
        table: 1,
        order_type: 2,
        payment_type: 1,
        service_type: 1,
        order_products: this.getFood(),
      }
    },
    getUpdateFood() {
      const products = []
      for (let index = 0; index < this.products.length; index++) {
        const element = this.products[index]
        products.push({
          operation: "add",
          product_id: element.product_id,
          order_type: 1,
          quantity: element.quantity,
          additions: this.getElectionsOfAdditions(index),
          options: this.getElectionsOfOptions(index),
        })
      }
      return products
    },
    getFood() {
      const products = []
      for (let index = 0; index < this.products.length; index++) {
        const element = this.products[index]
        products.push({
          product_id: element.product_id,
          order_type: 1,
          quantity: element.quantity,
          additions: this.getElectionsOfAdditions(index),
          options: this.getElectionsOfOptions(index),
        })
      }
      return products
    },
    getElectionsOfAdditions(payload) {
      const elections = []
      const product = this.products[payload]
      for (let index = 0; index < product.additions.length; index++) {
        const additions = product.additions[index]
        for (let index2 = 0; index2 < additions.elections.length; index2++) {
          const election = additions.elections[index2]
          let find = false
          let sameElectionIndex
          for (let index3 = 0; index3 < elections.length; index3++) {
            const element = elections[index3]
            if (element.id == election.id) {
              find = true
              sameElectionIndex = index3
            }
          }
          if (find) {
            elections[sameElectionIndex].quantity++
          } else {
            elections.push({
              addition_id: election.id,
              quantity: 1,
            })
          }
        }
      }
      return elections
    },
    getElectionsOfOptions(payload) {
      const elections = []
      const product = this.products[payload]
      for (let index = 0; index < product.options.length; index++) {
        const options = product.options[index]
        for (let index2 = 0; index2 < options.elections.length; index2++) {
          const election = options.elections[index2]
          let find = false
          let sameElectionIndex
          for (let index3 = 0; index3 < elections.length; index3++) {
            const element = elections[index3]
            if (element.id == election.id) {
              find = true
              sameElectionIndex = index3
            }
          }
          if (find) {
            elections[sameElectionIndex].quantity++
          } else {
            elections.push({
              election_id: election.id,
              quantity: 1,
            })
          }
        }
      }
      return elections
    },
  },
}
</script>
<style scoped>
.current-order {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}
.black-button {
  width: 100%;
}
hr {
  margin: 0;
}
h6 {
  color: #828282;
  margin: 0;
}
</style>
