<template>
  <q-card flat bordered class="product-item">
    <div class="food-info" @click="openModal(food)">
      <div>
        <h5 class="text-secondary body-font fs-19">
          {{ name }}
        </h5>
        <small class="body-color fs-14 body-font">{{ food.description }}</small>
      </div>
      <div class="food-item--footer">
        <p>$ {{ new Intl.NumberFormat("es-CO").format(food.total_price) }}</p>
        <!-- <q-chip class="q-ma-none q-mt-sm" v-else>
          <h6>{{ $t("pages.productitem.soldOut") }}</h6>
        </q-chip> -->
      </div>
    </div>
    <q-avatar size="87px" rounded>
      <img v-if="!noImg" class="food-img" :src="food.image" alt="" />
    </q-avatar>
    <q-btn
      class="add-btn"
      color="secondary"
      :style="{ color: 'var(--q-bg-color)' }"
      text-color="bg-nav"
      icon="add"
      @click="openModal(food)"
    />
  </q-card>
</template>
<script>
export default {
  props: {
    food: {
      type: Object,
      required: true,
    },
    openModal: {
      type: Function,
      required: true,
    },
    location: {
      type: String, // Propiedad para recibir la ubicación
      required: true,
    },
  },
  computed: {
    formattedPrice() {
      const price = this.food.total_price

      if (this.location === "col") {
        // Formatear el precio en pesos colombianos
        return `$ ${new Intl.NumberFormat("es-CO").format(price)}`
      } else if (this.location === "us") {
        // Formatear el precio en dólares estadounidenses
        return `$ ${new Intl.NumberFormat("en-US").format(price)}`
      }

      // Si la ubicación no es "col" ni "us", devolver el precio sin formato
      return `$ ${price}`
    },
    name() {
      const name = this.food.name.substring(0, 36)
      return name
    },
    description() {
      const description = this.food.description.substring(0, 58)
      return description
    },
    noImg() {
      return this.food.image === null
    },
  },
  methods: {
    // openModal() {
    //   this.$store.commit("setFoodConfig", this.food.id);
    //   window.$("#modalFood").modal("show");
    // },
  },
}
</script>
<style lang="scss" scoped>
h5 {
  font-weight: 600;
}
.product-item {
  width: 100%;
  height: auto;
  padding: 16px;
  display: grid;
  grid-template-columns: auto 87px;
  column-gap: 10px;
  background-color: var(--q-bg-products-item);
  border: 1px solid var(--q-card_stroke);
  border-radius: 5px;
  text-align: start;
}
.food-img {
  height: 84px;
  min-height: 84px;
  width: 84px;
  min-width: 84px;
  background-color: gray;
  border-radius: 4px !important;
  border: none;
  align-self: center;
}
.food-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  small {
    font-size: 12px;
  }
}
.info {
  max-height: 72px;
}
p {
  font-weight: 700;
  margin: 0;
  text-align: start;
  line-height: 12px;
}
small {
  margin-top: 5px;
  font-size: 12px;
  text-align: start;
  height: 28px;
  line-height: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.add-btn {
  width: 36px;
  height: 36px !important;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0;
  border-radius: 5px 0px;
}
/* @media screen and (min-width: 1023px) {
    .food-item {
      min-width: 254px;
      width: 100%;
      max-width: 370px;
    }
  }
  @media screen and (min-width: 1359px) {
    .food-item {
      height: 157px;
      padding: 16px;
    }
    .add-button {
      height: 44px;
      width: 44px;
    }
  } */
</style>
