<template>
  <section class="menu-list">
    <div v-if="!noOneProduct" class="container-lists">
      <div
        class="category-list"
        v-for="(category, index) in categories"
        :key="index"
      >
        <h5
          v-if="category.products.length != 0"
          :id="category.name"
          class="text-start"
        >
          {{ category.name }}
        </h5>
        <div class="list-food">
          <div class="fw" v-for="item in category.products" :key="item.id">
            <food-item class="food-item" :food="item" />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <p>No fue posible encontrar un producto con ese nombre :(</p>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex"
import FoodItem from "./products/Product.vue"
export default {
  components: {
    FoodItem,
  },
  computed: {
    ...mapGetters({
      categories: "getProductsFiltered",
    }),
    noOneProduct() {
      let products = 0
      for (let index = 0; index < this.categories.length; index++) {
        const element = this.categories[index]
        products = products + element.products.length
      }
      return products == 0
    },
  },
}
</script>
<style scoped>
.menu-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  padding: 0 20px;
}
.container-lists {
  width: 100%;
}
h5 {
  font-size: 22px;
  margin: 16px 0 0 0;
}
.list-food {
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;
}
p {
  margin-top: 20px;
  width: 280px;
}
@media screen and (min-width: 767px) {
  .list-food {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 26px;
  }
}
</style>
