<template>
  <div v-show="loading" class="q-pa-md flex flex-center">
    <q-circular-progress
      indeterminate
      rounded
      size="50px"
      color="white"
      class="q-ma-md"
    />
  </div>
  <div
    class="card-principal"
    v-show="!loading"
    :style="{ background: getBranchConfig.html_color_palette?.Secondary }"
  >
    <div class="img-container-container">
      <div class="img-container">
        <img
          style="width: 69px;
                 height: 35px;"
          :src="getBusinessInfo.image"
        />
      </div>
    </div>
    <div>
      <h1
        class="  title-font "
        :style="{ ...getStyles.h1, color: getStyles.h1?.color }"
      >
        {{
          $t("common.menu", {
            restaurant: getBusinessInfo.name,
          })
        }}
      </h1>
    </div>

    <div class="card-container">
      <q-card
        v-for="category in categories"
        @click="goToCategory(category.id)"
        class="card-menu"
        :key="category.id"
        :style="{
          position: 'relative',
          backgroundImage: 'url(' + category.image + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: getBranchConfig.radius,
          border: `1px solid ${getBranchConfig.html_color_palette?.Stroke}`,
        }"
        :class="{ 'background-whithout-img': !category.image }"
      >
        <div v-if="category.image" class="opacity-layer"></div>
        <div
          :class="{
            'container-title-chip': category.is_available === false,
          }"
        >
          <div>
            <h2
              class=" title-menu  "
              :style="{ ...getStyles.h2, color: getStyles.h2?.color }"
            >
              {{ category.name }}
            </h2>
          </div>
          <div>
            <q-chip
              v-if="category.is_available == false"
              class=" bagde-available"
              :label="$t('pages.productitem.noDisponible')"
              style="position: absolute;  transform: translateX(-50%);  bottom: 25px; left: 50%; padding-inline: 8px; padding-top: 4px; padding-bottom: 4px; "
              :style="{
                background: this.getBranchConfig.html_color_palette?.Accent,
                color: getBranchConfig.html_color_palette.Secondary,
                borderRadius: getBranchConfig.radius,
              }"
            ></q-chip>
          </div>
        </div>
      </q-card>
    </div>
    <NotifyBadge />
  </div>
</template>
<script>
import NotifyBadge from "@/components/NotifyBadge.vue"
import { mapGetters } from "vuex"

// import { setCssVar } from "quasar"

export default {
  data() {
    return {
      loading: false,
      position: 0,
    }
  },
  components: {
    NotifyBadge,
  },
  watch: {},
  computed: {
    ...mapGetters({
      // categories: "getProductsFiltered",
      categories: "getFilteredCategories",
    }),
    ...mapGetters([
      "getAllProducts",
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
      "getStyles",
    ]),
  },
  methods: {
    goToCategory(categoryId) {
      this.$store.commit("setSelectedCategoryId", categoryId)

      this.$router.push({
        name: "us-menu",
      })
    },

    fetchBranchInfo() {
      this.$store.dispatch("fetchBranchInfo")
      this.$store.dispatch("fetchAllProducts").then(() => {
        this.loading = false
      })
    },
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig").then(resp => {
        if (resp.status == 200) {
          // const styles = resp.data[0]
          // setCssVar("secondary", styles?.color || "#DBB244") // botones activos,required&notavailable bagde,skip & go menu,titulos,algunos precios
          // setCssVar("bg-options", styles?.background_options)
          // setCssVar("bg-search", styles?.background_search)
          // setCssVar("bg-products-item", styles?.background_products_item)
          // setCssVar("bg-products", styles?.background_products)
          // setCssVar("bg-modal", styles?.background_modal)
          // setCssVar("bg-tabs", styles?.background_tabs)
        }
      })
    },
    fetchBranchDetails() {
      this.$store.dispatch("fetchBranchDetails").then(resp => {
        if (resp.status == 200) {
          // const styles = resp.data || resp.detail || {}
          // setCssVar("bg-color", styles?.detail?.color_background) //esto es el background
          // setCssVar("disable-btn", styles?.detail?.disable_buttons || "#777") // esto para btn desactivados
          // setCssVar("body-color", styles?.detail?.body_color) // esto para los subtitulos
          // setCssVar("body-font", styles?.detail?.body_type)
          // setCssVar("title-font", styles?.detail?.title_type)
          // setCssVar("bg-nav", styles?.detail?.background_navigation)
          // setCssVar(
          //   "bg-contrast",
          //   styles?.detail?.background_contrast || "#303030"
          // )
          // setCssVar("review-link", styles?.review_link || "#DBB244")
        }
      })
    },
    fetchBusinessInfo() {
      this.$store.dispatch("fetchBusinessInfo").then(resp => {
        const location = resp.location
        if (location === "col") {
          this.$i18n.locale = "es"

          // Colombia
        } else if (location === "us") {
          this.$i18n.locale = "en"

          // Belongs to USA
        }
      })
    },
  },
  mounted() {
    this.fetchBusinessInfo()
  },
  created() {
    this.$store.commit("setBusinessName", this.$route.params.business)
    this.fetchBranchInfo()
    this.fetchBranchConfig()
    // this.fetchMenu()
    this.fetchBranchDetails()
    this.loading = true
  },
}
</script>

<style lang="scss" scoped>
::v-deep .q-notifications__list {
  ::v-deep .custom-notification-join {
    background-color: var(--q-bg-color);
    color: var(--q-text-color);
    border-radius: var(--q-border-radius);
  }
}

.custom-notification-join {
  height: 56px !important;
  width: 350px !important;
  font-size: 16px;
  background: var(--q-bagde-color) !important;
  color: var(--q-text-bagde-color) !important;
  border-radius: var(--q-border-radius) !important;

  .q-notification__message {
    font-size: 16px;
    font-family: Manrope;
    padding: 0;
  }
  .q-notification__wrapper {
    display: flex;
    align-items: center;
  }
}

.container-title-chip {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.bagde-available {
  width: 111px;
  height: 22px;
  font-size: 16px; /* Ajusta el tamaño del texto según sea necesario */
  font-family: Manrope;

  margin: 0 !important;
  z-index: 2;
}
.opacity-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Opacidad del 10% */
  z-index: 1; /* Asegura que la capa de opacidad esté sobre la imagen de fondo */
}

.card-container {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.background-whithout-img {
  background-color: rgba(255, 255, 255, 0.1);
}
.img-container-container {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.arrow-color {
  color: var(--q-bg-color);
}
.body-color-btn {
  color: var(--q-bg-color);
}
.title-menu {
  font-size: 1.375rem;
  font-family: Playfair Display;
  color: #ffffff;
  position: relative;
  z-index: 2;
}
.card-menu {
  margin-bottom: 24px;
  // width: 321px;
  height: 110px;
  border: 1px solid #dee1e4;
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  //   background-color: rgba(0, 0, 0, 0.6);
}
.card-principal {
  background-color: #0c2043;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.q-card-margin {
  margin-bottom: 10px;
}
.description-text {
  padding: 8px 12px 16px;
}
.img-container {
  padding-top: 25px;
  border-radius: 50%;
  overflow: hidden;
  width: 90px;
  height: 90px;
  background-color: #071b2c;
}
.logout {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 100vh;
  // width: 100vw;
  // max-width: 425px;
  // min-width: 360px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  // background-color: var(--q-bg-color);
}
.title-font {
  font-family: Playfair Display;
  font-size: 1.5rem;
  color: #ffffff;
  padding-top: 24px;
  padding-bottom: 24px;
}
// .body-font {
//   font-family: Georgia !important;
//   font-size: 16px;
//   font-weight: 400;
// }

// .content {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

.header {
  margin-bottom: 10px;
}

.text {
  margin-bottom: 20px;
}

.payfud {
  background-color: #fff;
  width: 100%;
  height: 55.433px;
  text-align: center;
}
</style>
