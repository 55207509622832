import bill from "./modules/bill"
import business from "./modules/business"
import client from "./modules/client"
import commensal from "./modules/commensal"
import currentOrderStore from "./modules/currentOrder"
import { createStore } from "vuex"
import footerNavbar from "./modules/footerNavbar"
import order from "./modules/order"
import recomendations from "./modules/recomendations"
// import socket from "./modules/socket";
import socketStore from "./modules/socket/index"
import tables from "./modules/tables"

import helpStore from "../modules/help/store"

export default createStore({
  state: {
    businessName: null,
    idBranch: null,
    tableNum: null,
  },
  mutations: {
    setBusinessName(state, payload) {
      state.businessName = payload
    },
    setIdBranch(state, payload) {
      state.idBranch = payload
    },
    setIdTable(state, payload) {
      state.idTable = payload
    },
    setTableNum(state, payload) {
      state.tableNum = payload
    },
  },
  actions: {
    clearPersistedState() {
      // Clear the persisted state data by clearing the entire local storage
      window.localStorage.clear("vuex")
    },
  },
  getters: {
    getIdTable(state) {
      return state.idTable
    },
    getIdBranch(state) {
      return state.idBranch
    },
    getBusinessName(state) {
      return state.businessName
    },
    gettableNum(state) {
      return state.tableNum
    },
  },
  modules: {
    bill,
    business,
    client,
    commensal,
    footerNavbar,
    order,
    recomendations,
    tables,
    help: helpStore,
    currentOrder: currentOrderStore,
    socket: socketStore,
  },
})
