<template>
  <nav>
    <div class="restaurant-info">
      <div class="restaurant">
        <section class="profile">
          <figure>
            <img src="@/assets/images/noImage.png" alt="img" />
          </figure>
          <div class="d-flex flex-column align-items-center">
            <h5>El Gran Langostino</h5>
            <div class="social-media">
              <img src="@/assets/icons/fb-icon.svg" alt="fb-icon" />
              <img src="@/assets/icons/ig-icon.svg" alt="ig-icon" />
              <img src="@/assets/icons/ws-icon.svg" alt="ws-icon" />
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="container-item" v-for="button in buttons" :key="button.id">
      <button
        :class="button.id == currentButton ? 'active' : ''"
        @click="setView(button.id)"
      >
        <img
          :src="button.id == currentButton ? button.iconActive : button.icon"
          alt="button-icon"
        />
        <p :class="button.id == currentButton ? 'active' : ''">
          {{ button.name }}
        </p>
        <div
          :class="button.id == currentButton ? 'border' : 'border d-none'"
        ></div>
        <div
          v-if="showNotification && button.id == 1"
          class="notification-dot"
        ></div>
      </button>
    </div>
  </nav>
</template>
<script>
// import billIcon from "@/assets/icons/bill-icon.svg";
// import billIconActive from "@/assets/icons/bill-icon-active.svg";
import menuIcon from "@/assets/icons/menu-icon.svg"
import menuIconActive from "@/assets/icons/menu-icon-active.svg"
import orderIcon from "@/assets/icons/order-icon.svg"
import orderIconActive from "@/assets/icons/order-icon-active.svg"
import profileIcon from "@/assets/icons/profile-icon.svg"
import profileIconActive from "@/assets/icons/profile-icon-active.svg"
import { mapGetters, mapMutations } from "vuex"
export default {
  name: "FooterNavbar",
  data() {
    return {
      buttons: [
        {
          name: "Menú",
          icon: menuIcon,
          iconActive: menuIconActive,
          active: false,
          id: 0,
          urlName: "menu",
        },
        {
          name: "Pedido",
          icon: orderIcon,
          iconActive: orderIconActive,
          active: false,
          id: 1,
          urlName: "order",
        },
        // {
        //   name: "Factura",
        //   icon: billIcon,
        //   iconActive: billIconActive,
        //   active: false,
        //   id: 2,
        //   urlName: "bill"
        // },
        {
          name: "Mi perfil",
          icon: profileIcon,
          iconActive: profileIconActive,
          active: false,
          id: 3,
          urlName: "profile",
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      currentButton: "getCurrentNavbarButton",
      orderProducts: "getOrderProducts",
      businessName: "getBusinessName",
    }),
    showNotification() {
      return this.orderProducts.length > 0
    },
  },
  methods: {
    ...mapMutations({
      toggleButtons: "setCurrentNavbarButton",
    }),
    setView(index) {
      this.toggleButtons(index)
      const finded = this.buttons.find(
        button => button.id == this.currentButton
      )
      this.$router.push({
        name: finded.urlName,
        params: { business: this.$route.params.business },
      })
    },
  },
}
</script>
<style scoped>
nav {
  width: 100%;
  height: 59px;
  background: #ffffff;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.restaurant-info {
  display: none;
}
h5 {
  margin-top: 25px;
}
button {
  width: 48px;
  height: 50px;
  font-size: 12px;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #828282;
  position: relative;
}
button img {
  margin: 10px 0 4px;
}
.active p {
  color: #02c874;
}
.notification-dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: #e94a47;
  border-radius: 100%;
  top: 5px;
  right: 14px;
}
@media screen and (min-width: 1023px) {
  nav {
    width: 167px;
    height: 100vh;
    top: 0;
    left: 0;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0;
    z-index: 100;
  }
  .restaurant-info {
    display: block;
    margin-top: 34px;
    margin-bottom: 38px;
  }
  .profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .restaurant-info figure {
    height: 85px;
    width: 85px;
    overflow: hidden;
    margin: 0;
  }
  .restaurant-info figure img {
    width: 100%;
  }
  .container-item {
    width: 100%;
  }
  button {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 16px;
    margin-bottom: 6px;
  }
  button img {
    margin: 0;
    margin-right: 16px;
  }
  .border {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 4px;
    background-color: #02c874;
  }
  .social-media {
    width: 90px;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 11px;
  }
  .active {
    background-color: rgba(2, 200, 117, 0.08);
  }
  .active p {
    background-color: transparent;
  }
}
@media screen and (min-width: 1359px) {
  nav {
    width: 227px;
  }
}
</style>
