<template>
  <div class="restaurant">
    <section class="profile">
      <q-avatar style="background-color: white; width: 68px; height: 68px;">
        <img
          style=" width: 83.024px;
           height: 73.526px;"
          :src="getBranchDetails.image"
        />
      </q-avatar>
      <div class="profile-info">
        <h3 class="text-secondary fs-22 q-pb-sm">{{ getBusinessInfo.name }}</h3>
        <q-chip
          text-color="dark"
          size="16px"
          style="background-color:var(--q-secondary)"
          class="q-ml-none q-mr-sm q-py-sm"
        >
          <a
            class="socials-link text-white"
            :href="getBranchConfig[0] && getBranchConfig[0].instagram_link"
            target="_blank"
            rel="noopener noreferrer"
            ><img
              class="q-mr-sm"
              src="@/assets/icons/igicon.svg"
              alt="ig-icon"
            />
            <p class="q-mr-sm q-mb-none text-white fs-14 ">Seguir</p>
          </a>
        </q-chip>
        <q-chip
          class="q-ml-none"
          size="16px"
          style="background-color:var(--q-bg-color); border: 1px solid var(--q-secondary);"
          text-color="secondary"
        >
          <a
            :href="getBranchDetails.detail?.review_link"
            class="socials-link text-secondary"
            target="_blank"
            rel="noopener noreferrer"
            ><img class="q-mr-sm" src="@/assets/icons/reviewicon.svg" />
            <p class="q-mr-sm q-mb-none text-secondary fs-14 ">
              Calificar
            </p>
          </a>
        </q-chip>
      </div>
    </section>
    <p class="description-text fs-16">
      {{ getBranchConfig && getBranchConfig[0]?.descripcion }}
    </p>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "restaurant-vue",
  mounted() {},
  computed: {
    restaurant() {
      return this.$store.getters.getRestaurant
    },
    scroll() {
      return document.querySelector("#menu-view").scrollTop
    },
    ...mapGetters([
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
    ]),
    truncatedDescription() {
      const maxDescriptionLength = 75 // El número máximo de caracteres antes de agregar puntos suspensivos
      const description = this.getBusinessInfo.description

      if (description.length <= maxDescriptionLength) {
        return description // No es necesario truncar
      } else {
        return `${description.slice(0, maxDescriptionLength - 3)}...` // Agrega los puntos suspensivos
      }
    },
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.restaurant {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  padding: 16px 20px 0 20px;
}
.profile {
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 220px;
  text-align: start;
}
.profile-info {
  margin-left: 15px;

  .q-chip {
    padding: 4px 6px;
  }
}
.restaurant-name {
  font-size: 20px;
}
.collapse {
  height: 0px;
}
figure {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  display: flex;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  margin: 0;
  margin-right: 12px;
}
img {
  height: 100%;
}
h1 {
  margin: 0;
}
.description-text {
  display: flex;
  margin: 2px 0 0 0;
  text-align: start;
  letter-spacing: 0;
  // -webkit-box-orient: vertical;
  // -webkit-line-clamp: 2; // Número de líneas que deseas mostrar
  // max-height: 3em; // Altura máxima en em o px
  // text-align: start;
  // letter-spacing: 0;
  // text-overflow: ellipsis;
}
.social-media {
  margin-top: 8px;
}
.social-media img {
  height: auto;
  margin-right: 10px;
}
.socials-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-right: 5px;
  padding-left: 5px;
}
</style>
