<template>
  <section
    class="so-on-categories"
    @click="collapseProfile"
    style="background-color: var(--q-bg-color);"
  >
    <div v-if="buttons.length == 0" class="so-non-menu">
      There is still no menu available for this restaurant menu online.
    </div>
    <div v-else class="so-on-container-categories">
      <div class="input so-on-input-container fs-16 body-font body-color">
        <q-input
          borderless
          v-model="text"
          type="search"
          @input="filterByText"
          :placeholder="$t('pages.menu.searchProduct')"
          style="padding: 0 20px; font-weight: 400 !important; "
        >
          <template v-slot:prepend>
            <q-icon class="search-color q-pl-sm " name="search" />
          </template>
        </q-input>
      </div>
      <div v-if="isFiltered == false" class="so-on-buttons">
        <ScrollTabsOnline
          :buttons="buttons"
          :moveToCategory="moveToCategory"
          :currentIndex="currentIndex"
        />
      </div>
    </div>
  </section>
</template>
<script>
import ScrollTabsOnline from "../../liteus/components/ScrollTabsOnline.vue"
import { mapGetters } from "vuex"

export default {
  components: {
    ScrollTabsOnline,
  },
  props: {
    position: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      coordinates: [],
      buttons: [],
      text: "",
      tab: "",
      isActive: false,
      categoriesTop: false,
      currentIndex: 0,
    }
  },
  watch: {
    text() {
      this.filterByText()
    },
    allProducts() {
      if (this.allProducts.length > 0) {
        this.createCategory()
      }
    },

    position() {
      if (!this.categoriesTop) {
        this.buttons.forEach((button, index) => {
          const categoryElement = document.getElementById(button.ref)
          this.buttons[index].position = categoryElement.offsetTop
        })
        this.categoriesTop = true
      }

      const scrollPosition = document.getElementById("menu-view").scrollTop

      let activated = false

      this.buttons.forEach((category, categoryIndex) => {
        if (scrollPosition >= category.position - 185 && !activated) {
          this.buttons.forEach((button, index) => {
            let activeAlgo = categoryIndex === index
            button.isActive = activeAlgo

            this.currentIndex = categoryIndex

            // if (!this.currentIndex == index) {

            //   this.currentIndex = index
            //   this.moveToCategory(index)
            // }
          })
          this.tab = category.text
        }
      })
    },
  },
  mounted() {
    this.filterByText()
    this.createCategory()
  },
  computed: {
    ...mapGetters({
      isFiltered: "getIsFiltered",
      allProductsFiltered: "getProductsFiltered",
      allProducts: "getAllProducts",
    }),
  },
  methods: {
    toogleCategories(id) {
      for (let index = 0; index < this.buttons.length; index++) {
        if (index == id) {
          this.buttons[index].active = true
          this.moveToCategory(index)
        } else {
          this.buttons[index].active = false
        }
      }
    },
    moveToCategory(index) {
      const category = document.querySelector(`#${this.buttons[index].ref}`)

      document.getElementById("menu-view").scrollTop = category.offsetTop - 175
      this.buttons.forEach((button, i) => {
        button.active = i === index
        button.isActive = i === index // Agrega esta línea
      })
    },
    createCategory() {
      this.$nextTick(() => {
        for (let index = 0; index < this.allProductsFiltered.length; index++) {
          if (this.allProductsFiltered[index].products.length > 0) {
            const categoryRef = this.allProductsFiltered[index].name.replace(
              /[\s/]+/g,
              ""
            )

            this.buttons.push({
              text: this.allProductsFiltered[index].name,
              active: false,
              id: index,
              ref: categoryRef,
            })
          }
        }
        if (this.buttons.length == 0) {
          return
        }
        this.buttons[0].isActive = true
        this.tab = this.buttons[0].text
        this.filterByText()
      })
    },
    filterByText() {
      this.$store.commit("filterByText", this.text)
    },
  },
}
</script>
<style lang="scss">
// /* Esto es de modalProduct */
.search-color {
  color: var(--q-body-color);
}
.active-button {
  color: var(
    --q-secondary
  ) !important; /* Cambiar el color del texto al color secundario */
  border-bottom: 2px solid var(--q-secondary) !important; /* Agregar un borde inferior de color secundario */
}
.so-on-input-container {
  margin-bottom: 10px;
  width: 100%;
  max-width: 654px;
}
.btn-cat {
  white-space: nowrap;
  background-color: var(--q-bg-nav);
  border: none;
  color: #f8f8f8;
  margin-top: 10px;

  padding: 1px 8px 13px;
  &:active {
    color: var(--q-secondary);
  }
}

.so-on-container-categories .q-field__control {
  background-color: var(--q-bg-search) !important;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  &:focus-within {
    border: 1px solid var(--q-secondary);
  }
}

.so-on-container-categories input {
  color: var(--q-body-color) !important;
}

.so-non-menu {
  width: 80%;
}
.so-on-categories {
  position: sticky; // esta position necesita una altura especifica, no auto
  top: 82px;
  height: 120px;
  width: 100%;
  border-bottom: 1px solid var(--q-card_stroke);
  background-color: var(--q-bg-tabs) !important;
  z-index: 99;
  padding-top: 16px;
}
.so-on-container-categories {
  width: 100%;
  color: var(--q-body-color);
  /* padding: 0 20px; */
}
.so-payfud-input {
  height: 48px;
}

.so-on-buttons {
  display: flex;
  width: 98vw;
  max-width: 985px;
  overflow: auto;
  touch-action: pan-x;

  .q-tabs__content {
    padding-left: 13px !important;
    padding-right: 13px;
  }
}
</style>
