import api from "@/api"
import axios from "axios"

export const fetchLocation = ({ rootState, commit }, location) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${api}/r/${rootState.businessName}/v1/branch-locations/check_distance?branch_id=${rootState.idBranch}`,
        location,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(
        response => {
          resolve(response)
          commit("setLocation", response)
          // commit("setLocation", location)
        },
        error => {
          reject(error)
        }
      )
  })
}

export const fetchTables = async ({ commit, rootState }) => {
  try {
    const response = await axios.get(
      `/r/${rootState.domainName}/v1/tables`,
      rootState.businessAuth
    )
    commit("setTables", response.data)
  } catch (error) {
    console.error(error)
  }
}
export const createOrder = ({ rootState, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${api}/r/${rootState.businessName}/v1/orders`, payload, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(
        response => {
          resolve(response)
          // commit("setOrderInProgress");
          commit("setOrderId", response.data.id)
        },
        error => {
          reject(error)
        }
      )
  })
}
export const createSessions = ({ rootState, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${api}/r/${rootState.businessName}/v1/session-analitics`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${rootState.businessAuth}`,
            "content-type": "application/json",
          },
        }
      )
      .then(
        response => {
          resolve(response)
          // commit("setOrderInProgress");
          commit("setSession", response.data.id)
        },
        error => {
          reject(error)
        }
      )
  })
}

export const createBillAnalitics = ({ rootState, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${api}/r/${rootState.businessName}/v1/bill-analitics`, payload, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(
        response => {
          resolve(response)
          // commit("setOrderInProgress");
          commit("setBillAnalitics", response.data.id)
        },
        error => {
          reject(error)
        }
      )
  })
}

export const createWaiterAnalitics = ({ rootState, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${api}/r/${rootState.businessName}/v1/waiter-analitics`, payload, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(
        response => {
          resolve(response)
          // commit("setOrderInProgress");
          commit("setWaiterAnalitics", response.data.id)
        },
        error => {
          reject(error)
        }
      )
  })
}

// export const fetchOrder = ({ rootState, commit }, payload) => {

//   return new Promise((resolve, reject) => {
//     axios
//       .get(
//         `${api}/r/${rootState.businessName}/v1/query-orders?connectionid=${payload}`,
//         {
//           headers: {
//             "content-type": "application/json",
//           },
//         }
//       )
//       .then(
//         (response) => {
//           resolve(response);
//           // commit("setOrderInProgress");
//           commit("setCurrentOrder", response.data);
//         },
//         (error) => {
//           reject(error);
//         }
//       );
//   });
// };
export const addProductToOrder = ({ rootState, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${api}/r/${rootState.businessName}/v1/orders/${payload.orderId}`,
        payload.data,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(
        response => {
          resolve(response)
          commit("addOrderInProgress")
        },
        error => {
          reject(error)
        }
      )
  })
}
