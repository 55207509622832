const bill = {
  state: {
    perquisite: 0,
    inProgress: false,
    payMethod: -1,
  },
  mutations: {
    setPerquisite(state, payload) {
      state.perquisite = payload
    },
    setInProgressBill(state) {
      state.inProgress = true
      setTimeout(() => {
        state.inProgress = false
      }, 10000)
    },
    setPayMethod(state, payload) {
      state.payMethod = payload
    },
  },
  actions: {},
  getters: {
    getPerquisite(state) {
      return state.perquisite
    },
    getInProgressBill(state) {
      return state.inProgress
    },
    getPayMethod(state) {
      return state.payMethod
    },
    getOrdersToPay(state) {
      return state.ordersToPay
    },
  },
}
export default bill
