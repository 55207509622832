<template>
  <div id="main-view" class="main-view">
    <router-view></router-view>
    <div class="fixed-menu" ref="myDiv">
      <q-tabs
        active-class="custom-active"
        inline-label
        indicator-color="transparent"
        content-class="custom-tabs"
      >
        <Socket class="absolute" />
        <q-route-tab
          :ripple="false"
          icon="restaurant"
          label="Menú"
          :to="{ name: 'menu-v2' }"
          no-caps
          tabindex="1"
          class="body-font  fs-14"
          :class="{ underline: activeRoute('menu-v2') }"
        >
          <div v-if="route.name == 'menu-v2'"></div>
        </q-route-tab>
        <!-- <q-route-tab
          :ripple="false"
          icon="list_alt"
          :label="ordersText"
          :to="{ name: 'orders-v2' }"
          exact
          no-caps
          tabindex="2"
          :class="{ underline: activeRoute('orders-v2') }"
        >
          <div v-if="route.name == 'orders-v2'" class="mark bg-primary"></div>
        </q-route-tab> -->
        <q-route-tab
          :ripple="false"
          icon="help_outline"
          label="Ayuda"
          :to="{ name: 'help-v2' }"
          no-caps
          tabindex="3"
          class=" body-font"
          :class="{ underline: activeRoute('help-v2') }"
        >
          <div v-if="route.name == 'help-v2'" class="mark bg-primary"></div>
        </q-route-tab>
      </q-tabs>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue"
import { mapGetters } from "vuex"
import { setCssVar } from "quasar"
export default {
  components: {
    Socket: defineAsyncComponent(() => import("../components/Socket.vue")),
  },
  data() {
    return {
      connection: null,
    }
  },
  computed: {
    ...mapGetters("currentOrder", {
      products: "getProducts",
      preparingProducts: "getPreparingProducts",
      deliveredProducts: "getDeliveredProducts",
    }),
    ...mapGetters(["getBranchConfig"]),
    notification() {
      return (
        this.products.length > 0 ||
        this.preparingProducts.length > 0 ||
        this.deliveredProducts.length > 0
      )
    },
    productsCount() {
      return (
        this.products.length +
        this.preparingProducts.length +
        this.deliveredProducts.length
      )
    },
    ordersText() {
      return this.productsCount > 0
        ? `Pedidos (${this.productsCount})`
        : "Pedidos"
    },
    route() {
      return this.$route
    },
  },
  setup() {
    return {}
  },

  mounted() {
    this.fetchBranchConfig()
  },
  methods: {
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data[0]
          setCssVar("bg-color", styles?.color_background || "#FFF") //esto es el background
          setCssVar("secondary", styles?.color || " #03257E") // botones activos,required&notavailable bagde,skip & go menu,titulos,algunos precios
          setCssVar("disable-btn", styles?.disable_buttons || "#777") // esto para btn desactivados
          setCssVar("body-color", styles?.body_color || "#323232") // esto para los subtitulos
          setCssVar("body-font", styles?.body_type || "Fira Sans")
          setCssVar("title-font", styles?.title_type || "DM Sans")
          setCssVar("bg-nav", styles?.background_navigation || " #7c9ae5")
          setCssVar("bg-contrast", styles?.backgorund_contrast || "#f9fafe")
          // setCssVar("review-link", styles?.review_link || "#DBB244")
        }
      })
    },
    activeRoute(route) {
      return this.$route.name == route
    },
  },
  // mounted() {
  //   this.$refs.myDiv.addEventListener(
  //     "touchmove",
  //     function(e) {
  //       e.preventDefault()
  //     },
  //     { passive: false }
  //   )
  // },
}
</script>

<style lang="scss" scoped>
.title-font {
  font-family: var(--q-title-font) !important;
}
.body-font {
  font-family: var(--q-body-font) !important;
  font-size: 16px;
}
.main-view {
  height: 100dvh;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  position: relative;
  font-family: Georgia;
  overflow: hidden;
  background-color: var(--q-bg-color);
  // display: grid;
  // grid-template-rows: calc(100vh - 50px) 50px;
  // grid-template-columns: 1fr;
}

.fixed-menu {
  position: fixed;
  bottom: 0;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  // border-top: 1px solid var(--q-bg-nav);
  background-color: var(--q-bg-color);
}

.mark {
  position: absolute;
  width: 125%;
  max-width: 90px;
  height: 75%;
  max-height: 40px;
  opacity: 0.08;
  border-radius: 60px;
}
</style>
