<template>
  <div class="current-order">
    <h6 v-if="productsToEatHere.length > 0">PARA COMER AQUÍ</h6>
    <div v-for="(product, index) in productsToEatHere" :key="product.id">
      <order-product-to-pay :product="product" />
      <hr v-if="index != productsToEatHere.length - 1" />
    </div>
    <h6 v-if="productsToCarry.length > 0">PARA COMER LLEVAR</h6>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import OrderProductToPay from "./OrderProductToPay.vue"
export default {
  name: "OrderToPay",
  components: {
    OrderProductToPay,
  },
  computed: {
    ...mapGetters({
      orderToPay: "getOrderToPay",
      orderId: "getOrderId",
    }),
    productsToEatHere() {
      return this.orderToPay.filter(element => element.order_type == 3)
    },
    productsToCarry() {
      return this.orderToPay.filter(element => element.order_type == 1)
    },
  },
}
</script>
<style scoped>
.current-order {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}
.black-button {
  width: 100%;
}
hr {
  margin: 0;
}
</style>
