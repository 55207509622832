import api from "@/api"
import axios from "axios"

export const fetchTables = async ({ commit, rootState }) => {
  try {
    const response = await axios.get(
      `/r/${rootState.domainName}/v1/tables`,
      rootState.businessAuth
    )
    commit("setTables", response.data)
  } catch (error) {
    console.error(error)
  }
}
export const createOrder = ({ rootState, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${api}/r/${rootState.businessName}/v1/orders`, payload, {
        headers: {
          "content-type": "application/json",
        },
      })
      .then(
        response => {
          resolve(response)
          // commit("setOrderInProgress");
          commit("setOrderId", response.data.id)
        },
        error => {
          reject(error)
        }
      )
  })
}

export const fetchOrder = ({ rootState, commit }, payload) => {
  if (payload != null) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${api}/r/${rootState.businessName}/v1/query-orders?id=${payload}`,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        )
        .then(
          response => {
            resolve(response)
            commit("setCurrentOrder", response.data)
          },
          error => {
            reject(error)
          }
        )
    })
  }
}
export const updateTableNum = ({ commit }, newTableNum) => {
  commit("updateTableNum", newTableNum)
}
export const addProductToOrder = ({ rootState, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${api}/r/${rootState.businessName}/v1/orders/${payload.orderId}`,
        payload.data,
        {
          headers: {
            "content-type": "application/json",
          },
        }
      )
      .then(
        response => {
          resolve(response)
          commit("addOrderInProgress")
        },
        error => {
          reject(error)
        }
      )
  })
}
