<template>
  <section class="simple-menu">
    <div class="container-products">
      <div class="input">
        <q-input
          v-model="text"
          outlined
          @input="filterByText"
          type="search"
          style="padding: 0 20px"
          placeholder="Buscar producto"
        >
          <template v-slot:prepend>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </div>
    <div class="product-list">
      <div
        v-for="product in filteredProducts"
        :key="product.id"
        class="product"
      >
        <div class="product-info">
          <h4>{{ product.name }}</h4>
          <p>{{ product.products.name }}</p>
          // aqui deberia ir cada producto del array products
        </div>
        <q-btn dense round icon="add" @click="showProductDetails(product)" />
      </div>
    </div>
    <q-dialog v-model="showDetailsDialog" @hide="clearSelectedProduct">
      <q-card>
        <q-card-section>
          <h3>{{ selectedProduct.name }}</h3>
          <p>{{ selectedProduct.description }}</p>
          // aqui deberia ir la descripcion que hay en el array de products
          dentro de la categoria que nombro como product products.description
          <p>Valor: {{ selectedProduct.total_price }}</p>
          // aqui debe ir el valor del array products.total_price
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="Cerrar" @click="closeDetailsDialog" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </section>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  components: {},
  data() {
    return {
      text: "",
      showDetailsDialog: false,
      selectedProduct: null,
    }
  },
  computed: {
    ...mapGetters({
      allProducts: "getProductsFiltered",
      isFiltered: "getIsFiltered",
    }),
    ...mapGetters(["getBusinessInfo"]),

    filteredProducts() {
      return this.allProducts.filter(product => {
        return (
          product.name.toLowerCase().includes(this.text.toLowerCase()) ||
          product.description.toLowerCase().includes(this.text.toLowerCase())
        )
      })
    },
  },
  methods: {
    filterByText() {},
    showProductDetails(product) {
      this.selectedProduct = product
      this.showDetailsDialog = true
    },
    closeDetailsDialog() {
      this.showDetailsDialog = false
      this.clearSelectedProduct()
    },
    clearSelectedProduct() {
      this.selectedProduct = null
    },
  },
}
</script>

<style scoped>
.simple-menu {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #ececec;
  background-color: #fff;
  z-index: 99;
  padding-top: 16px;
}

.container-products {
  width: 100%;
}

.input {
  margin: 16px 0;
}

.product-list {
  padding: 16px;
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
  padding: 8px 0;
  cursor: pointer;
}

.product-info {
  flex: 1;
  margin-right: 16px;
}
</style>
