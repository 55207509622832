<template>
  <div id="main-view" class="main-view">
    <router-view></router-view>
    <!-- active-color="primary" -->
    <div
      v-if="!route.path.includes('/categories')"
      class="fixed-menu"
      ref="myDiv"
      :style="{
        background: getBranchConfig.html_color_palette?.Primary,
        color: getBranchConfig.html_color_palette?.Accent,
        borderTop: `1px solid ${getBranchConfig.html_color_palette?.Stroke}`,
      }"
    >
      <q-tabs
        active-class="custom-active"
        inline-label
        indicator-color="transparent"
        content-class="custom-tabs"
        class="half-width-tabs"
        style="height: 100%;"
        outside-arrows
        mobile-arrows
      >
        <Socket class="absolute" />
        <q-route-tab
          :ripple="false"
          :to="{ name: 'us-menu' }"
          no-caps
          tabindex="1"
          class=" tab-menu"
          :class="{
            underline: activeRoute('us-menu'),
            'background-opacity': activeRoute('us-menu'),
          }"
          :style="{
            color: getBranchConfig.html_color_palette?.Accent,
            ...getStyles?.p,
            fontSize: '14px',
            fontWeight: 'bold',
          }"
        >
          <q-col col="auto">
            <span style="font-size: 24px;" class="material-symbols-outlined"
              >menu_book</span
            >
          </q-col>
          <q-col style="padding-left: 8px;">
            MENU
          </q-col>
          <div v-if="route.name == 'menu-v2'" class="mark white"></div>
        </q-route-tab>
        <!-- <q-route-tab
          :ripple="false"
          icon="list_alt"
          :label="ordersText"
          :to="{ name: 'orders-v2' }"
          exact
          no-caps
          tabindex="2"
        >
          <div v-if="route.name == 'orders-v2'" class="mark bg-primary"></div>
        </q-route-tab> -->
        <q-route-tab
          v-if="getBranchConfig.help_visible"
          :ripple="false"
          :to="{ name: 'us-help' }"
          no-caps
          tabindex="3"
          style="color:white"
          class="text-white body-font tab-request"
          :class="{
            underline: activeRoute('us-help'),
            'background-opacity': activeRoute('us-help'),
          }"
        >
          <q-col col="auto">
            <span style="font-size: 24px;" class="material-symbols-outlined"
              >person_raised_hand</span
            >
          </q-col>
          <q-col style="padding-left: 8px;">
            SERVICE
          </q-col>
          <div v-if="route.name == 'help-v2'" class="mark white"></div>
        </q-route-tab>
      </q-tabs>
    </div>
    <q-btn
      v-if="route.name == 'us-menu'"
      class="scroll-to-top"
      @click="scrollToTop"
      size="lg"
      flat
      :style="{
        borderRadius: getBranchConfig.radius,
        background: getBranchConfig.html_color_palette?.Primary,
        color: getBranchConfig.html_color_palette?.Accent,
      }"
    >
      <q-icon name="arrow_upward" class="icon-with-margin" size="25px" />
    </q-btn>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue"
import { mapGetters } from "vuex"
// import { setCssVar } from "quasar";
export default {
  components: {
    Socket: defineAsyncComponent(() => import("@/components/Socket.vue")),
  },
  data() {
    return {
      connection: null,
    }
  },
  computed: {
    ...mapGetters("currentOrder", {
      products: "getProducts",
      preparingProducts: "getPreparingProducts",
      deliveredProducts: "getDeliveredProducts",
    }),
    ...mapGetters(["getBranchConfig", "getStyles"]),
    notification() {
      return (
        this.products.length > 0 ||
        this.preparingProducts.length > 0 ||
        this.deliveredProducts.length > 0
      )
    },
    isInSpecificRoute() {
      // Reemplaza '/ruta-especifica' con la ruta que quieres comprobar
      return this.$route.name === "us-menu"
    },

    productsCount() {
      return (
        this.products.length +
        this.preparingProducts.length +
        this.deliveredProducts.length
      )
    },
    ordersText() {
      return this.productsCount > 0
        ? `Pedidos (${this.productsCount})`
        : "Pedidos"
    },
    route() {
      return this.$route
    },
  },
  setup() {
    return {}
  },
  methods: {
    activeRoute(route) {
      return this.$route.name == route
    },

    scrollToTop() {
      const container = document.getElementById("menu-view")
      container.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    },
  },
  // mounted() {
  //   this.$refs.myDiv.addEventListener(
  //     "touchmove",
  //     function(e) {
  //       e.preventDefault()
  //     },
  //     { passive: false }
  //   )
  // },
}
</script>

<style lang="scss" scoped>
.tab-request {
  background-color: #020c1c;
}
.tab-menu {
  background-color: #020c1c;
}
.half-width-tabs .q-tab {
  width: 50%; /* Cada pestaña ocupa la mitad del espacio disponible */
}
.background-opacity {
  background-color: rgba(255, 255, 255, 0.1);
  text-decoration: underline;
}
.title-font {
  font-family: var(--q-title-font) !important;
}
.body-font {
  font-family: Manrope !important;
  font-size: 16px;
}
.main-view {
  height: 100dvh;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  position: relative;
  font-family: Georgia;
  overflow-x: hidden;

  // display: grid;
  // grid-template-rows: calc(100vh - 50px) 50px;
  // grid-template-columns: 1fr;
}

.fixed-menu {
  position: fixed; // estaba en fixed, en IOS molesta diferente
  bottom: 0;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  border-top: 1px solid#DBECFA;
  background-color: #082136;
  z-index: 2;
  height: 60px;
  .q-tabs__indicator {
    display: none;
  }
}

.mark {
  position: absolute;
  width: 125%;
  max-width: 90px;
  height: 75%;
  max-height: 40px;
  opacity: 0.08;
  border-radius: 60px;
  color: white;
  background-color: white;
}

.scroll-to-top {
  position: absolute;
  bottom: 77px;
  display: flex;
  right: 18px;
  z-index: 1;
  background: #020c1c;
  color: white;
  width: 57px;
  height: 57px !important;
}
</style>
