<template>
  <section id="scroll-list" class="menu-list">
    <div v-if="!noOneProduct" class="container-lists">
      <div
        class="category-list"
        v-for="(category, index) in categories"
        :key="index"
      >
        <div class="category-header">
          <h3 :id="category.ref" class="category-title text-secondary  fs-18">
            {{ category.name }}
          </h3>
          <!-- <q-chip
            v-if="!category.is_available"
            class="q-mx-none body-font"
            color="secondary"
            text-color="black"
            :label="$t('pages.productitem.noDisponible')"
          ></q-chip> -->
        </div>
        <q-item-section class="mp-q-item-section" side> </q-item-section>
        <div
          v-if="
            category.description.length > 0 && category.products.length != 0
          "
          class="q-mb-sm text-left body-font body-color fs-16"
          style="font-weight: 400;"
        >
          <p>
            {{ category.description }}
          </p>
        </div>
        <div v-if="category.products.length > 0" class="list-by-category">
          <div v-for="item in category.products" :key="item.id">
            <ProductItemOnline
              :openModal="toggleModalProduct"
              :food="item"
              :categoryAvailability="category.is_available"
            ></ProductItemOnline>
          </div>
        </div>
      </div>
      <div class="offset"></div>
    </div>
    <div v-else>
      <p>{{ $t("pages.productlist.notFound") }}</p>
    </div>
  </section>
  <ModalProductOnline
    v-model="productModal"
    :currentProduct="currentProduct"
    :close="toggleModalProduct"
  />
</template>
<script>
import { defineAsyncComponent } from "vue"
import { mapGetters } from "vuex"
import ProductItemOnline from "../../lite/components/ProductItemOnline.vue"
export default {
  props: {
    onlyRead: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ProductItemOnline,
    ModalProductOnline: defineAsyncComponent(() =>
      import("../modals/ModalProductOnline.vue")
    ),
  },
  data() {
    return {
      productModal: false,
      onlyReadModal: false,
      maximizedToggle: true,
      currentProduct: null,
    }
  },

  computed: {
    ...mapGetters("socket", {
      phoneData: "getPhoneData",
    }),
    ...mapGetters({
      categories: "getFilteredCategories",
    }),
    ...mapGetters(["getBranchInfo"]),
    noOneProduct() {
      let products = 0
      for (let index = 0; index < this.categories.length; index++) {
        const element = this.categories[index]
        products = products + element.products.length
      }
      return products == 0
    },
  },
  methods: {
    toggleModalProduct(product) {
      const body = {
        product: product.id, // aqui va el id del producto
        client_id: this.phoneData.phone || null, // aqui va el numero de telefono en caso de que lo haya, si no lo hay entonces se puede enviar vacio
        sucursal: this.getBranchInfo[0].id, // aqui va la sucursal
      }
      this.$store.dispatch("mostLessProducts", body)
      if (this.onlyRead) {
        this.onlyReadModal = !this.onlyReadModal
      } else {
        this.productModal = !this.productModal
      }
      this.currentProduct = product
      this.$store.commit("setFoodConfig", product)
    },
  },
}
</script>
<style scoped>
.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-list {
  display: grid;
  /* grid-template-columns: 1fr 1fr 1fr; */
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  box-sizing: border-box;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column;
  align-items: center; */
  /* margin-bottom: 60px; */
  padding: 0 20px;
  padding-top: 16px;
  padding-right: 30px;

  background-color: var(--q-bg-products);
  /* overflow-y: auto; */
  /* padding-bottom: 50px; */
  scroll-behavior: smooth;
}
.container-lists {
  width: 100%;
  max-height: calc(100vh - 305px);
  overflow: visible;
}
.category-list {
  margin-bottom: 10px;
}
.category-title {
  text-align: start;
  margin-bottom: 8px;
  color: #dbb244;
}
.list-by-category {
  display: grid;
  row-gap: 21px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  /* grid-template-columns: 1fr 1fr 1fr; */
  gap: 20px; /* Espacio entre las columnas */
}
.category-description {
  font-size: 14px;
  color: white;
}
/* p {
  margin-top: 20px;
  width: 280px;
} */
.offset {
  height: 60px;
}

@media screen and (min-width: 995px) {
  .menu-list {
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr; */
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    box-sizing: border-box;
    width: 100%;
    /* display: flex; */
    /* flex-direction: column;
  align-items: center; */
    /* margin-bottom: 60px; */
    padding: 0 20px;
    padding-top: 16px;
    padding-right: 30px;
    background-color: var(--q-bg-products);
    /* overflow-y: auto; */
    /* padding-bottom: 50px; */
    scroll-behavior: smooth;
  }
  .list-by-category {
    display: grid;
    row-gap: 21px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* grid-template-columns: 1fr 1fr 1fr; */
    gap: 20px; /* Espacio entre las columnas */
  }
}

@media screen and (min-width: 767px) {
  .list-food {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 26px;
  }
}
@media screen and (min-width: 414px) {
  .list-by-category {
    display: grid;
    row-gap: 21px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px; /* Espacio entre las columnas */
  }
  .menu-list {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 60px; */
    padding: 0 20px;
    padding-top: 16px;
    padding-right: 30px;
    background-color: var(--q-bg-products);
    /* overflow-y: auto; */
    /* padding-bottom: 50px; */
    scroll-behavior: smooth;
  }
}
</style>
