<template>
  <button :class="active ? 'active' : ''">
    {{ text }}
    <div v-if="active" class="line position-absolute"></div>
  </button>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
<style scoped>
button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: transparent;
  border: none;
  color: #828282;
  height: 50px;
  margin: 0 8px;
}
.active {
  color: #02c874;
  font-weight: 700;
}
.line {
  background-color: #02c874;
  height: 2px;
  bottom: 0;
  width: 100%;
}
</style>
