import api from "@/api"
import axios from "axios"

const tables = {
  state: {},
  mutations: {},
  actions: {
    getAllTables({ rootState }) {
      return new Promise((resolve, reject) => {
        axios.get(`${api}/r/${rootState.businessName}/v1/tables`).then(
          response => {
            resolve(response.data)
          },
          error => {
            reject(error)
          }
        )
      })
    },
  },
  getters: {},
}

export default tables
