<template>
  <q-card-section
    class="column onboarding full-height full-width justify-between"
  >
    <q-carousel
      v-model="slide"
      transition-prev="scale"
      transition-next="scale"
      swipeable
      animated
      control-color="primary"
      height="calc(100% - 45px)"
      class="rounded-borders"
      ref="carousel"
    >
      <q-carousel-slide
        name="first"
        class="column no-wrap flex-center q-py-none"
      >
        <q-img
          src="@/assets/images/payfud-logo.png"
          style="height: 29px; width: 72px"
        />
        <div class="restaurant-img">
          <q-img
            src="https://placeimg.com/500/300/nature"
            style="width: 182px"
            :ratio="1"
          />
        </div>
        <h4>Bievenido a {{ businessName }}</h4>
      </q-carousel-slide>
      <q-carousel-slide name="menu" class="column no-wrap flex-center">
        <q-img src="@/assets/images/list-example.png" style="width: 182px" />
        <h4 class="q-pt-xl q-pb-sm">Agrega productos a tu pedido</h4>
        <p>
          Selecciona el icono “+” para agregar productos a tu pedido y así
          recordar más tarde para solicitarle a tu mesero
        </p>
      </q-carousel-slide>
      <q-carousel-slide name="order" class="column no-wrap flex-center">
        <q-img src="@/assets/images/order-example.png" style="width: 182px" />
        <h4 class="q-pt-xl q-pb-sm">Solicita un mesero</h4>
        <p>
          Puedes llamar a un mesero cuando estés listo para realizar tu pedido o
          cuando requieras ayuda
        </p>
      </q-carousel-slide>
      <q-carousel-slide name="check" class="column no-wrap flex-center">
        <q-img src="@/assets/images/check-example.png" style="width: 182px" />
        <h4 class="q-pt-xl q-pb-sm">Pide la cuenta desde tu celular</h4>
        <p>
          No esperes para tener tu cuenta, selecciona “ayuda” para pedirla desde
          tu celular y elegir un método de pago
        </p>
      </q-carousel-slide>
      <q-carousel-slide name="rating" class="column no-wrap flex-center">
        <q-img src="@/assets/images/rating-example.png" style="width: 182px" />
        <h4 class="q-pt-xl q-pb-sm">Califica tu experiencia</h4>
        <p>
          Selecciona “ayuda” para calificar tu experiencia durante o después de
          tu estadía en el restaurante
        </p>
      </q-carousel-slide>
    </q-carousel>
    <div v-if="slide !== 'rating'" class="row q-col-gutter-md">
      <div v-if="slide !== 'first'" class="col-4">
        <q-btn
          class="fw"
          outline
          color="primary"
          label="Saltar"
          no-caps
          :to="{ name: 'menu-v2' }"
        />
      </div>
      <div :class="slide == 'first' || slide == 'rating' ? 'fw' : 'col-8'">
        <q-btn
          class="fw"
          color="primary"
          unelevated
          :label="slide == 'first' ? 'Comenzar' : 'Siguiente'"
          no-caps
          @click="$refs.carousel.next()"
        />
      </div>
    </div>
    <q-btn
      v-else
      class="fw"
      color="primary"
      unelevated
      label="Ver menú"
      no-caps
      :to="{ name: 'menu-v2' }"
    />
  </q-card-section>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "onboarding-view",
  computed: {
    ...mapGetters({
      businessName: "getBusinessName",
    }),
  },
  data() {
    return {
      slide: "first",
    }
  },
  methods: {
    toMenu() {
      this.$router.push({ name: "menu-v2" })
    },
  },
}
</script>

<style scoped>
.onboarding {
  padding: 20px;
}
.restaurant-img {
  padding-top: 45px;
  padding-bottom: 57px;
}
</style>
