<template>
  <div class="orders-view">
    <q-btn
      v-if="eatHereProducts.length > 0 || takeAwayProducts.length > 0"
      class="full-width orderBtn text-weight-bold"
      unelevated
      color="primary"
      label="Pedir ahora"
      no-caps
      @click="onCreateOrder"
    />
    <div class="head_container">
      <h3 class="head">Tu pedido</h3>
      <q-card>
        <q-tabs
          v-model="tab"
          dense
          class="text-grey"
          active-color="primary"
          active-class="tab-active"
          indicator-color="primary"
          align="justify"
          narrow-indicator
        >
          <q-tab name="new" label="Nuevos" no-caps>
            <q-badge
              v-if="products.length > 0"
              rounded
              color="red"
              :label="products.length"
              floating
          /></q-tab>
          <q-tab name="preparing" label="Preparando" no-caps
            ><q-badge
              v-if="preparingProducts.length > 0"
              rounded
              color="red"
              :label="preparingProducts.length"
              floating
            />
          </q-tab>
          <q-tab name="finished" label="Entregados" no-caps>
            <q-badge
              v-if="deliveredProducts.length > 0"
              rounded
              color="red"
              :label="deliveredProducts.length"
              floating
            />
          </q-tab>
        </q-tabs>

        <q-separator />
      </q-card>
    </div>
    <q-tab-panels v-model="tab" animated>
      <q-tab-panel name="new">
        <div
          v-if="eatHereProducts.length > 0 || takeAwayProducts.length > 0"
          class="products-list"
        >
          <q-chip
            v-if="eatHereProducts.length > 0"
            color="dark"
            text-color="white"
          >
            PARA COMER AQUÍ
          </q-chip>
          <ul>
            <li v-for="product in eatHereProducts" :key="product">
              <OrderProduct
                :product="product"
                :openModal="toggleProductModal"
              />
            </li>
          </ul>
          <q-chip
            v-if="takeAwayProducts.length > 0"
            color="dark"
            text-color="white"
          >
            PARA LLEVAR
          </q-chip>
          <ul>
            <li v-for="product in takeAwayProducts" :key="product">
              <OrderProduct
                :product="product"
                :openModal="toggleProductModal"
              />
            </li>
          </ul>
        </div>
        <div v-else class="no-products">
          <q-img
            src="@/assets/images/nuevos.png"
            height="140px"
            width="140px"
            class="q-mb-xl"
          />
          <p>
            Aún no has seleccionado nuevos productos. Intenta ir al menú para
            ver y seleccionar nuestros productos.
          </p>
          <q-btn
            class="full-width"
            unelevated
            color="primary"
            label="Ir al menú"
            no-caps
            @click="toMenu"
          />
        </div>
      </q-tab-panel>

      <q-tab-panel name="preparing">
        <OrderList :products="preparingProducts" :info="noProductsMsgs[1]" />
      </q-tab-panel>

      <q-tab-panel name="finished">
        <OrderList :products="deliveredProducts" :info="noProductsMsgs[2]" />
      </q-tab-panel>
    </q-tab-panels>
    <q-btn
      v-show="false"
      class="fw q-mb-md"
      color="primary"
      unelevated
      no-caps
      @click="startCountdown"
      :disable="counting"
    >
      Solicitar mesero
      <vue-countdown
        class="q-ml-md"
        v-if="counting"
        @end="onCountdownEnd"
        :time="minutes * seconds * 1000"
        v-slot="{ minutes, seconds }"
      >
        <vue-countdown
          v-if="counting"
          :time="5 * 60 * 1000"
          @end="onCountdownEnd"
          v-slot="{ totalSeconds }"
        >
          <q-circular-progress
            show-value
            class="text-white q-ma-xs"
            :value="totalSeconds"
            :min="0"
            :max="300"
            size="30px"
            color="white"
            :thickness="0.05"
          >
            {{ minutes }} : {{ seconds }}
          </q-circular-progress>
        </vue-countdown>
      </vue-countdown>
    </q-btn>
  </div>
  <ModalProduct
    v-model="productModal"
    :currentProduct="currentProduct.product"
    :currentOptionsAndAdditions="currentProduct"
  />
</template>

<script>
import { defineAsyncComponent } from "vue"
import { mapActions, mapGetters } from "vuex"
import VueCountdown from "@chenfengyuan/vue-countdown"
import newestImg from "@/assets/images/nuevos.png"
import preparingImg from "@/assets/images/preparando.png"
import deliveredImg from "@/assets/images/entregados.png"
import { useQuasar } from "quasar"
import { mapState } from "vuex"
// import { mapMutations } from 'vuex';

export default {
  components: {
    OrderProduct: defineAsyncComponent(() =>
      import("../components/OrderProductV2.vue")
    ),
    ModalProduct: defineAsyncComponent(() =>
      import("../../menu/modals/ModalProduct.vue")
    ),
    OrderList: defineAsyncComponent(() =>
      import("../components/OrderList.vue")
    ),
    VueCountdown,
  },
  data() {
    return {
      counting: false,
      // minutes: 5,
      // seconds: 60,
      tab: "new",
      productModal: false,
      currentProduct: {},
      noProductsMsgs: [
        {
          msg:
            "Aún no has seleccionado nuevos productos. Intenta ir al menú para ver y seleccionar nuestros productos.",
          img: newestImg,
        },
        {
          msg:
            "No tienes productos en preparación. Intenta ir al menú para ver nuestros productos y pedir.",
          img: preparingImg,
        },
        { msg: "Aún no se han entregado productos.", img: deliveredImg },
      ],
    }
  },
  computed: {
    ...mapState("currentOrder", ["tableNum"]),
    ...mapGetters({
      idBranch: "idBranch",
    }),
    ...mapGetters({
      orderProducts: "getOrderProducts",
      table: "getIdTable",
      // connectionId: "getConnectionId",
      connection: "getSocketConnection",
      businessInfo: "getBusinessInfo",
    }),
    ...mapGetters("socket", {
      connectionId: "getConnectionId",
      socket: "getSocket",
      phoneData: "getPhoneData",
    }),
    ...mapGetters("currentOrder", {
      table: "getTable",
      products: "getProducts",
      eatHereProducts: "getEatHereProducts",
      takeAwayProducts: "getTakeAwayProducts",
      orderId: "getOrderId",
      order: "getOrder",
      preparingProducts: "getPreparingProducts",
      deliveredProducts: "getDeliveredProducts",
    }),
    ...mapGetters("socket", {
      phoneData: "getPhoneData",
    }),
  },
  methods: {
    getOrder() {
      this.$store.dispatch("currentOrder/fetchOrder", this.orderId)
    },
    toMenu() {
      this.$router.push({
        name: "menu-v2",
      })
    },
    ...mapActions("socket", {
      createSessions: "createWaiterAnalitics",
    }),
    testOrder() {
      this.socket.send(
        JSON.stringify({
          action: "orders",
          order_id: this.orderId.toString(),
          order_status: "1",
          table: this.$route.params.table.toString(),
          restaurant_location_id: this.businessInfo.id.toString(),
          restaurant_name: this.businessInfo.name.toString(),
          phone_number: this.phoneData.phone,
          phone_id: this.phoneData.phoneId,
        })
      )
    },
    toggleProductModal(product) {
      this.currentProduct = product
      this.productModal = !this.productModal
    },
    startCountdown: function() {
      this.counting = true
    },
    onCountdownEnd: function() {
      this.counting = false
    },
    getInfo() {
      return {
        connectionid: this.connectionId.toString(),
        table: this.tableNum,
        payment_type: 1,
        order_products: this.getFood(),
        order_status: 1,
        discount_order: 0,
      }
    },
    onCreateOrder() {
      this.loading = true
      if (this.orderId === null) {
        this.$store
          .dispatch("currentOrder/createOrder", this.getInfo())
          .then(response => {
            if (response.status == 200) {
              this.testOrder()
              this.showNotif("bottom-right", "Orden Creada")
              this.$store.commit("currentOrder/resetOrder")
              this.$router.push({ name: "branch-orders" })
            } else {
              this.showNotif("bottom-right", "Algo salio mal :(")
            }
            this.loading = false
          })
      } else {
        this.$store
          .dispatch("currentOrder/addProductToOrder", {
            data: this.getInfo(),
            orderId: this.orderId,
          })
          .then(response => {
            if (response.status == 200) {
              this.testOrder()
              this.showNotif("bottom", "Orden Actualizada")
              this.$store.commit("currentOrder/resetOrder")
              this.$router.push({ name: "branch-orders" })
            } else {
              this.showNotif("bottom", "Algo salio mal :(")
            }
            this.loading = false
          })
      }
    },
    getFood() {
      const products = []
      if (this.orderId === null) {
        for (let index = 0; index < this.products.length; index++) {
          const product = this.products[index]
          products.push({
            product_id: product.product.id,
            order_type: product.order_type,
            quantity: product.quantity,
            additions: this.getElectionsOfAdditions(index),
            options: this.getElectionsOfOptions(index),
            product_status: 1,
          })
        }
      } else {
        for (let index = 0; index < this.products.length; index++) {
          const product = this.products[index]
          products.push({
            product_id: product.product.id,
            order_type: product.order_type,
            quantity: product.quantity,
            additions: this.getElectionsOfAdditions(index),
            options: this.getElectionsOfOptions(index),
            product_status: 1,
            operation: "add",
          })
        }
      }
      return products
    },
    getElectionsOfAdditions(payload) {
      const elections = []
      const product = this.products[payload]
      for (let index = 0; index < product.additions.length; index++) {
        const additions = product.additions[index]
        for (let index2 = 0; index2 < additions.elections.length; index2++) {
          const election = additions.elections[index2]
          let find = false
          let sameElectionIndex
          for (let index3 = 0; index3 < elections.length; index3++) {
            const element = elections[index3]
            if (element.id == election.id) {
              find = true
              sameElectionIndex = index3
            }
          }
          if (find) {
            elections[sameElectionIndex].quantity++
          } else {
            elections.push({
              addition_id: election.id,
              quantity: 1,
            })
          }
        }
      }
      return elections
    },
    getElectionsOfOptions(payload) {
      const elections = []
      const product = this.products[payload]
      for (let index = 0; index < product.options.length; index++) {
        const options = product.options[index]
        for (let index2 = 0; index2 < options.elections.length; index2++) {
          const election = options.elections[index2]
          elections.push({
            election_id: election.id,
            quantity: election.quantity,
          })
        }
      }
      return elections
    },
    testSocket() {
      this.$store.dispatch("socketNewOrder", {
        action: "orders",
        order_id: this.orderId.toString(),
        order_status: "in progress",
        table: this.$route.params.table.toString(),
        restaurant_location_id: "15",
        restaurant_name: "OneWay",
      })
    },
  },
  setup() {
    const $q = useQuasar()

    return {
      showNotif(position, message) {
        $q.notify({
          message,
          position,
        })
      },
    }
  },
  created() {
    this.getOrder()
  },
}
</script>

<style lang="scss" scoped>
.orders-view {
  text-align: start;
  position: relative;
  padding-top: 103px;
  // padding: 24px 20px 0;
}
.head {
  padding: 24px 20px 12px;
}
.q-card {
  box-shadow: none;
}
.products-list {
  height: calc(100vh - 230px);
  overflow: auto;
}
.no-products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 152px);

  .no-img {
    height: 136px;
    width: 159px;
    background-color: #d9d9d9;
    margin-bottom: 27px;
  }
  p {
    text-align: center;
    margin: 0;
    max-width: 280px;
  }
  .q-btn {
    margin-top: 32px;
  }
}
.orderBtn {
  position: fixed;
  bottom: 48px;
  z-index: 99;
  max-width: 425px;
  min-width: 360px;
}
.head_container {
  position: fixed;
  top: 0;
  z-index: 99;
  background-color: #fff;
  width: 100%;
  max-width: 425px;
  min-width: 280px;
}
</style>
