<template>
  <div v-if="!loading" class="auth-layout">
    <q-card flat>
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
      </q-tabs>

      <q-tab-panels
        v-model="tab"
        animated
        class="tabs q-panel scroll"
        style="overflow-y: hidden !important;"
        role="tabpanel"
      >
        <q-tab-panel
          name="alarms"
          style="padding: 20px;   background-color: var(--q-bg-contrast);"
        >
          <div class="auth-form">
            <h3 class="q-mb-sm fs-22 text-secondary tittle-font">
              Bienvenido a {{ getBusinessInfo.name }}
            </h3>
            <p class="q-mb-sm body-font" style="font-size: 17px;">
              Ingresa tu número de teléfono
            </p>
            <!-- <p>Recibirás un mensaje de texto para verificar tu cuenta.</p> -->
            <div class="phone-input q-mb-md q-mt-md">
              <q-select
                class="country"
                borderless
                v-model="model"
                :options="options"
              >
                <template v-slot:prepend>
                  <q-avatar v-if="model != null" size="xs">
                    <img :src="model.icon" />
                  </q-avatar>
                </template>
                <template v-slot:option="scope">
                  <q-item v-bind="scope.itemProps">
                    <q-item-section avatar>
                      <q-avatar size="xs">
                        <img :src="scope.opt.icon" />
                      </q-avatar>
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>{{ scope.opt.label }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
              <q-input
                input-style="padding: 0 16px fs-16"
                borderless
                v-model="phone"
                mask="###-###-####"
                unmasked-value
                placeholder="000-000-0000"
              />
            </div>
            <q-checkbox
              class="q-mb-md"
              v-model="terms"
              color="var(--q-secondary)"
              dense
            >
              <p class="q-mb-none fs-16 q-pl-sm q-mt-md body-font">
                He leído y acepto la
                <a
                  href="https://payfud.com/es/politica-de-privacidad/"
                  @click.stop
                  >política de privacidad</a
                >
              </p>
            </q-checkbox>
            <q-checkbox
              class="q-mb-none welcome-checkbox-us"
              v-model="subscribe"
              color="var(--q-secondary)"
              dense
            >
              <p class="q-mb-none fs-16 q-pl-sm q-mt-md body-font">
                Suscríbete a las últimas noticias y eventos de
                {{ getBusinessInfo.name }}
              </p>
            </q-checkbox>
            <q-btn
              class="full-width fs-16 q-mt-md body-font"
              color="secondary"
              unelevated
              no-caps
              @click="onSendVerification"
              :disable="disableBtn"
              label="Comenzar"
            />
          </div>
          <div class="q-pa-md text-secondary fs-16 body-font">
            <q-btn
              label="Omitir para ver el menú"
              @click="skipMenu"
              flat
              no-caps
              class="q-mb-md btn--no-hover"
            />
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </div>
  <ModalPhoneVerification v-model="phoneVerification" />
</template>
<script>
import colombianBall from "@/assets/icons/colombia.svg"
import { defineAsyncComponent } from "vue"
import { mapGetters } from "vuex"
import { setCssVar } from "quasar"
export default {
  components: {
    ModalPhoneVerification: defineAsyncComponent(() =>
      import("../modals/ModalPhoneVerification.vue")
    ),
  },
  data() {
    return {
      tab: "alarms",
      phoneVerification: false,
      phone: "",
      model: null,
      options: [
        {
          label: "+57",
          value: "+57",
          description: "Search engine",
          icon: colombianBall,
        },
      ],
      terms: true,
      subscribe: true,
      loading: false,
    }
  },
  computed: {
    ...mapGetters(["getBusinessInfo", "getBranchDetails"]),
    disableBtn() {
      return this.phone.length < 10 || this.terms === false
    },
  },
  methods: {
    skipMenu() {
      this.$router.push({ name: "menu-v2" })
    },
    sendSubscribe() {
      // agregar que se envie el telefono y si el suscribe es false o true, es decir que ya no haya una condicion de que solo se cumpla si está suscrito en true, sino que ponga telefono y revise si suscribe es false o tr
      const payload = {
        phone_number: this.phone,
        subscribe: this.subscribe,
      }
      this.$store.dispatch("subscribeClient", payload)
    },
    setDefault() {
      this.model = this.options[0]
    },
    onSendVerification() {
      //   this.phoneVerification = !this.phoneVerification;
      this.$store.commit("socket/setPhoneData", {
        phone: this.phone,
        phoneId: this.model.value,
      })
      this.sendSubscribe()
      this.$router.push({ name: "menu-v2" })
    },
    fetchBranchInfo() {
      this.$store.dispatch("fetchBranchInfo")
      this.$store.dispatch("fetchBusinessInfo")
      this.$store.dispatch("fetchBranchDetails")
    },
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig").then(resp => {
        if (resp.status == 200) {
          // const styles = resp.data[0]
          // setCssVar("secondary", styles?.color || " #03257E") // botones activos,required&notavailable bagde,skip & go menu,titulos,algunos precios
        }
        this.loading = false
      })
    },
    fetchBranchDetails() {
      this.$store.dispatch("fetchBranchDetails").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data || resp.detail || {}
          setCssVar("bg-color", styles?.detail?.color_background) //esto es el background
          setCssVar("disable-btn", styles?.detail?.disable_buttons) // esto para btn desactivados
          setCssVar("body-color", styles?.detail?.body_color) // esto para los subtitulos
          setCssVar("body-font", styles?.detail?.body_type)
          setCssVar("title-font", styles?.detail?.title_type)
          setCssVar("bg-nav", styles?.detail?.background_navigation)
          setCssVar("bg-contrast", styles?.detail?.background_contrast)
          // setCssVar("review-link", styles?.review_link)
        }
      })
    },
  },
  mounted() {
    this.fetchBranchConfig()
    this.setDefault()
    this.$store.commit("socket/setNewConnection")
    this.$store.commit("setIdBranch", this.$route.params.id)
  },
  created() {
    this.$store.dispatch("clearPersistedState")
    this.loading = true
    this.$store.commit("setBusinessName", this.$route.params.business)
    this.fetchBranchInfo()
    this.fetchBranchConfig()
    this.fetchBranchDetails()
  },
}
</script>

<style lang="scss" scoped>
.auth-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  background-color: var(--q-bg-contrast);
}
.auth-form {
  text-align: start;
  width: 100%;
  padding: 0 20px;
  background-color: var(--q-bg-color);
}
.phone-input {
  display: grid;
  grid-template-columns: 90px 1fr;
  border: 1px solid var(--gray);
  border-radius: 5px;
  padding: 0 14px;

  &:focus-within {
    border: 1px solid var(--q-secondary);
  }
}
.country {
  border-right: 1px solid var(--gray);
}
</style>
