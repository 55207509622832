<template>
  <div :class="show ? 'order show-order' : 'order'">
    <div class="container-lists">
      <div class="lists">
        <div v-if="foods.length == 0" class="no-food">
          <h2>Sin productos</h2>
          <p>Añade productos en el menú para realizar tu pedido</p>
        </div>
        <div v-else>
          <h4>Tu pedido</h4>
          <p class="label-order"><span>NUEVO</span></p>
          <current-order />
        </div>
        <div v-if="orderToPay.length > 0" class="mt-4 fw">
          <p class="label-order"><span>HACE POCO</span></p>
          <order-to-pay />
        </div>
      </div>
      <div class="button-area" v-if="foods.length > 0">
        <nelkir-button class="fw" :style="1"> Ver factura </nelkir-button>
      </div>
    </div>
    <button class="back-button" @click="toggle">Volver menu</button>
  </div>
</template>
<script>
import CurrentOrder from "../components/order/CurrentOrder.vue"
import OrderToPay from "../components/order/OrderToPay.vue"
import NelkirButton from "@/components/tools/buttons/NelkirButton.vue"
import { mapGetters, mapMutations } from "vuex"
export default {
  name: "Order-view",
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    toggle: {
      type: Function,
      required: true,
      default: () => {},
    },
  },
  components: {
    CurrentOrder,
    OrderToPay,
    NelkirButton,
  },
  data() {
    return {
      loading: false,
      back: "menu",
    }
  },
  computed: {
    ...mapGetters({
      clientInfo: "getClientInfo",
      restaurant: "getRestaurant",
      foods: "getOrderProducts",
      orderToPay: "getOrderToPay",
    }),
  },
  methods: {
    ...mapMutations({
      toBill: "toggleFooterNavbarButtons",
      toggleButtons: "setCurrentNavbarButton",
    }),
  },
  created() {
    this.toggleButtons(1)
  },
}
</script>
<style scoped>
.order {
  background-color: #fff;
  min-height: calc(100vh - var(--header-h));
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  text-align: start;
  transition: 0.5s;
}
.show-order {
  right: 0;
}
.container-lists {
  display: grid;
  grid-template-rows: 1fr auto;
  padding-bottom: 44px;
}
.lists {
  padding: 0 20px;
}
.button-area {
  padding: 24px 20px;
}
.back-button {
  height: 45px;
  width: 100%;
  background-color: var(--red-nelkir);
  color: #fff;
  border: none;
  position: fixed;
  bottom: 0;
}
.no-food {
  height: 100%;
  width: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}
.no-food p {
  text-align: center;
  margin-top: 8px;
  margin-bottom: 18px;
}
.your-order {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  width: 100%;
  padding: 16px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
}
h4 {
  margin-top: 19px;
  margin-bottom: 24px;
}
.label-order {
  color: #5e5e5e;
  margin-bottom: 10px;
}
h6 {
  margin: 16px 0 0 0;
  font-size: 16px;
  color: #828282;
}
.your-order h6 {
  margin: 0;
}
.your-order .primary-button {
  margin: 0;
}
.form {
  margin: 5px 0 0 0;
}
.form p {
  font-weight: 700;
  margin: 10px 0 0 0;
}
input {
  width: 100%;
  height: 45px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 10px;
}
.black-button {
  width: 100%;
  height: 45px;
  font-weight: 700;
  margin-bottom: 3px;
}
.bill-button {
  height: 55px;
  background-color: #2f2f2f;
  color: #fff;
  border: none;
  border-radius: 5px;
  width: 100%;
  margin: 24px 0;
  font-weight: 700;
}
.primary-button {
  width: 100%;
}
@media screen and (min-width: 1023px) {
  .order {
    right: 0;
    width: 280px;
    border-left: 1px solid #e1e1e1;
  }
  .back-button {
    display: none;
  }
  .button-area {
    border-top: 1px solid #e1e1e1;
  }
}
@media screen and (min-width: 1359px) {
  .order {
    width: 390px;
  }
}
</style>
