<template>
  <div class="restaurant">
    <section class="profile">
      <q-avatar
        style="background-color: white; width: 60px; height: 58px;   margin-top: 15px; "
      >
        <!-- <img :src="getBusinessInfo.image" /> -->
        <!-- src="https://s3-alpha-sig.figma.com/img/9355/b102/800e70a5f6b81e7d452a4f2f2f8904d4?Expires=1694995200&Signature=Rg3aNbYaFk2efkVoGvfAGbmQoQY3r4bmb3iqPamzp~5XhPqV-iVCzv43D8II73Oa2RFnYehiFbSGrXAtEu4MZWvSSKcSOKAQe-tiNULpKxvhgJGYECmrdUHTZ8ItYgQdLC1tCcYwoGCAZF1NDXO2YT36tLSNSMix9LmKtxYXGsaH8bWvWsm9wCOvMpkTkSn5AyWQlkTv6b~0t~yv2eHGp~95BGwEaovTuuaadWpMpl31nPUoCr-yCu~W8U3Ek9qwygSnftjc~5mCL-HwzFGEOMBH~ZOFHw9SPGG7MueOw5tX7a20rWVtr8DU5VAM0Z8SEn3VmRC19EqTspKu6aVlrw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" -->
        <img
          :src="getBranchDetails ? getBranchDetails.image : ''"
          style=" margin-top: -3px;
    width: 100px;
    height: 66px;
  "
        />
      </q-avatar>
      <div class="profile-info">
        <h3 class="text-secondary fs-22 q-pb-sm" style="margin-top: 29px;">
          {{ getBusinessInfo.name }}
        </h3>
        <!-- <q-chip
          class="q-ml-none q-mr-sm q-py-sm"
          size="16px"
          style="background-color:var(--q-secondary)"
          text-color="dark"
        >
          <a
            :href="getBranchConfig[0] && getBranchConfig[0].instagram"
            class="socials-link text-dark"
            target="_blank"
            rel="noopener noreferrer"
            ><img
              class="q-mx-sm"
              src="@/assets/icons/ig-icon.svg"
              alt="ig-icon"
            />
            <p class="q-mr-sm q-mb-none text-black fs-14 ">
              {{ $t("pages.restaurant.follow") }}
            </p>
          </a>
        </q-chip>
        <q-chip
          class="q-ml-none"
          size="16px"
          style="background-color:var(--q-bg-nav); border: 1px solid #DBB244;"
          text-color="secondary"
        >
          <a
            :href="getBranchDetails.detail?.review_link"
            class="socials-link text-dark"
            target="_blank"
            rel="noopener noreferrer"
            ><img
              class="q-mx-sm"
              src="@/assets/icons/review.svg"
              alt="ig-icon"
            />
            <p class="q-mr-sm q-mb-none text-secondary fs-14 ">
              {{ $t("pages.restaurant.review") }}
            </p>
          </a>
        </q-chip> -->
      </div>
    </section>
    <p class="description-text body-color  fs-16">
      {{ getBranchConfig[0] && getBranchConfig[0].descripcion }}
    </p>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "restaurant-vue",
  computed: {
    restaurant() {
      return this.$store.getters.getRestaurant
    },
    scroll() {
      return document.querySelector("#menu-view").scrollTop
    },
    ...mapGetters([
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
    ]),
  },
}
</script>
<style lang="scss" scoped>
.restaurant {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: var(--q-bg-tabs);
  padding: 16px 20px 0 20px;
  // font-family: Playfair Display;
}
.profile {
  margin-top: 19px;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 80px;
  text-align: start;
}
.profile-info {
  margin-left: 15px;

  .q-chip {
    padding: 4px 6px;
  }
}
.restaurant-name {
  font-size: 20px;
}
.collapse {
  height: 0px;
}
figure {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  display: flex;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(169, 156, 62, 0.08);
  margin: 0;
  margin-right: 12px;
}
img {
  height: 100%;
}
h1 {
  margin: 0;
}
.description-text {
  margin: 5px 0 0 0;
  text-align: start;
  letter-spacing: 0;
  margin-bottom: 0 !important;
}
.social-media {
  margin-top: 8px;
}
.social-media img {
  height: auto;
  margin-right: 10px;
}
.socials-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
</style>
