<template>
  <button
    class="btn-list"
    @click="goToCategories"
    :style="{ color: getBranchConfig.html_color_palette?.Accent }"
  >
    <span
      :style="{ color: getBranchConfig.html_color_palette?.Accent }"
      class="material-symbols-outlined list"
      style="font-size: 24px; line-height: 24px;"
    >
      list
    </span>
  </button>

  <div class="tabs-container">
    <div
      class="tabs-wrapper"
      ref="tabsWrapper"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      @scroll="handleScroll"
    >
      <div
        v-for="(button, index) in buttons"
        :key="index"
        @click="activateTab(index)"
        :class="{ active: button.isActive }"
        class="tab"
        :style="{ ...getStyles?.h3, color: getStyles.h3?.color }"
      >
        {{ button.text }}
      </div>
    </div>
  </div>
  <div class="btns-wrapper">
    <button
      :style="{ color: getBranchConfig.html_color_palette?.Accent }"
      class="btn-left"
      @touchstart.prevent="startScrolling('left')"
      @touchend="stopScrolling"
      @click="scroll('left')"
      @mousedown="startScrolling('left')"
      @mouseup="stopScrolling"
      @mouseleave="stopScrolling"
    >
      <span
        :style="{ color: getBranchConfig.html_color_palette?.Accent }"
        class="material-symbols-outlined arrow-icon-left"
        :class="{ disabled: !showLeftArrow }"
      >
        chevron_left
      </span>
    </button>
    <button
      :style="{ color: getBranchConfig.html_color_palette?.Accent }"
      class="btn-right"
      @touchstart.prevent="startScrolling('right')"
      @touchend="stopScrolling"
      @click="scroll('right')"
      @mousedown="startScrolling('right')"
      @mouseup="stopScrolling"
      @mouseleave="stopScrolling"
    >
      <span
        :style="{ color: getBranchConfig.html_color_palette?.Accent }"
        class="material-symbols-outlined arrow-icon-right"
        :class="{ disabled: !showRightArrow }"
      >
        chevron_right
      </span>
    </button>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  props: {
    buttons: {
      type: Array,
      default: () => [],
    },
    moveToCategory: {
      type: Function,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      startX: 0,
      currentX: 0,
      isDragging: false,
      activeTab: 0,
      showLeftArrow: false,
      showRightArrow: false,
      scrollInterval: null,
    }
  },
  mounted() {
    this.updateArrows() // Call it once when the component is mounted
  },
  watch: {
    activeTab: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.updateArrows()
          this.centerActiveTab()
        })
      },
    },
    currentIndex() {
      this.activeTab = this.currentIndex
    },
  },
  computed: {
    ...mapGetters(["getStyles", "getBranchConfig"]),
  },
  methods: {
    goToCategories() {
      this.$router.push({
        name: "menu-categories",
      })
    },

    startScrolling(direction) {
      if (this.scrollInterval) clearInterval(this.scrollInterval)
      // Comenzar el intervalo
      this.scrollInterval = setInterval(() => {
        this.scroll(direction)
      }, 10) //velocidad del desplazamiento
    },
    stopScrolling() {
      if (this.scrollInterval) {
        clearInterval(this.scrollInterval)
        this.scrollInterval = null
      }
    },
    handleTouchStart(event) {
      this.startX = event.touches[0].pageX
      this.isDragging = true
    },
    handleTouchMove(event) {
      if (!this.isDragging) return
      this.currentX = event.touches[0].pageX
      let difference = this.startX - this.currentX
      this.$refs.tabsWrapper.scrollLeft += difference
      this.startX = this.currentX // Update startX for the next move event
    },
    handleTouchEnd() {
      this.isDragging = false
    },
    updateArrows() {
      if (!this.$refs.tabsWrapper) return
      let wrapper = this.$refs.tabsWrapper
      this.showLeftArrow = wrapper?.scrollLeft > 0
      this.showRightArrow =
        wrapper?.scrollLeft < wrapper.scrollWidth - wrapper?.offsetWidth
    },
    handleScroll() {
      this.updateArrows()
    },
    activateTab(index) {
      this.activeTab = index
      this.moveToCategory(index)
    },
    scroll(direction) {
      let amount = 5 // saltos de desplazamiento
      if (!this.$refs.tabsWrapper) return
      if (direction === "left") {
        this.$refs.tabsWrapper.scrollLeft -= amount
      } else if (direction === "right") {
        this.$refs.tabsWrapper.scrollLeft += amount
      }
      // this.$nextTick(this.updateArrows)
    },
    centerActiveTab() {
      let wrapper = this.$refs.tabsWrapper

      if (!wrapper) return
      let tabs = wrapper.children
      let activeTab = tabs[this.activeTab]
      let center = wrapper?.offsetWidth / 2 - activeTab?.offsetWidth / 2
      let endPosition = activeTab?.offsetLeft - center
      let currentPosition = wrapper?.scrollLeft
      let changeInPosition = endPosition - currentPosition
      let startTime = performance.now()
      let duration = 300 // Duración en milisegundos
      function animateScroll(currentTime) {
        let elapsed = currentTime - startTime
        let progress = Math.min(elapsed / duration, 1)
        // Cálculo del desplazamiento basado en una función de facilidad para una transición más suave
        let easeInOutQuad = t => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t)
        let position =
          currentPosition + easeInOutQuad(progress) * changeInPosition
        wrapper.scrollLeft = position
        if (progress < 1) requestAnimationFrame(animateScroll)
      }
      requestAnimationFrame(animateScroll)
    },
  },
}
</script>
<style scoped>
.btn-list {
  background-color: transparent;
  border: none;
  color: white;
  padding-left: 18px;
  padding-right: 0;
}
.arrow-icon-left {
  color: white;
  font-size: 35px;
  /* margin-left: -7px; */
}
.arrow-icon-right {
  color: white;
  font-size: 35px;
  margin-right: -7px;
}
.btn-right {
  position: absolute;
  right: 0;
  border: none;
  background: linear-gradient(322deg, hsla(0, 0%, 8%, 0), #0c2043 50%);
  padding-left: 0;
  padding-right: 14px;
  height: 46px;
}
.btn-left {
  position: absolute;
  right: 0;
  border: none;
  /* background: linear-gradient(227deg, #082136 50%, transparent); */
  /* background: linear-gradient(90deg, hsla(0, 0%, 8%, 0), #082136 50%); */

  /* background: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0.02) 0%,
    hsla(207, 74%, 12%, 1) 15%
  ); */
  background: linear-gradient(
    272deg,
    rgba(12, 32, 67, 1) 87%,
    rgba(255, 255, 255, 0) 100%
  );
  padding-left: 0;
  padding-right: 35px;
  height: 46px;
}
.tabs-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.tabs-wrapper {
  display: flex;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  transition: transform 0.3s ease;
  height: 47px;
  padding-left: 12px;
}
.tab {
  /* font-family: Prata;
  font-size: 19px;
  font-weight: 500;
  font-style: normal; */
  display: inline-block;
  padding: 10px 20px;
  cursor: pointer;
  padding: 0;
  margin-right: 18px;
  align-items: center;
  display: flex;
  justify-content: center;
}
.tab:last-child {
  border-right: none;
}
.tab.active {
  border-bottom: 2px solid #edf6fc !important; /* Agregar un borde inferior de color secundario */
}
</style>
