<template>
  <header :class="scrolled ? 'dark' : ''">
    <!-- <button @click="toBack">
      <img :src="scrolled ? exitArrowWhite : exitArrow" alt="arrow-icon" />
    </button> -->
    <nav>
      <button
        class="help-button"
        data-bs-toggle="modal"
        data-bs-target="#modalWaiter"
      >
        <img :src="scrolled ? iconWhite : icon" alt="help-icon" />
        <p>Ayuda</p>
      </button>
    </nav>
  </header>
</template>
<script>
import Icon from "@/assets/icons/help-icon.svg"
import IconWhite from "@/assets/icons/help-icon-white.svg"
import ExitArrow from "@/assets/icons/exit-arrow.svg"
import ExitArrowWhite from "@/assets/icons/exit-arrow-white.svg"
export default {
  data() {
    return {
      scrolled: false,
      icon: Icon,
      iconWhite: IconWhite,
      exitArrow: ExitArrow,
      exitArrowWhite: ExitArrowWhite,
    }
  },
  computed: {
    foodLength() {
      return this.$store.getters.getOrderProducts
    },
  },
  methods: {
    toBack() {
      window.$("#modalLogout").modal("show")
    },
    checkScroll() {
      const y = window.scrollY
      if (y > 1) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.checkScroll)
  },
  unmounted() {
    window.removeEventListener("scroll", this.checkScroll)
  },
}
</script>
<style scoped>
header {
  height: var(--header-h);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: #fff;
  z-index: 100;
}
.dark {
  background-color: #343434;
}
.dark p {
  color: #fff;
}
.wrapper {
  display: flex;
  justify-content: space-between;
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 50%;
}
button p {
  margin-left: 9px;
  width: auto;
  font-weight: 700;
}
.modalOrder {
  display: block;
}
.notification-detail {
  height: 12px;
  width: 12px;
  background-color: #e94a47;
  top: -20%;
  right: 10%;
  border-radius: 100%;
  color: #fff;
  font-size: 8px;
}
.help-button {
  margin-right: 16px;
}
</style>
