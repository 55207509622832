<template>
  <div>
    <nav>
      <div class="logo-container">
        <img src="@/assets/images/logoavenue.png" alt="Logo" class="logo" />
      </div>
      <ul>
        <li @click="changeTab('welcome')">Welcome</li>
        <li @click="changeTab('menu')">Menu</li>
        <li @click="changeTab('events')">Events</li>
        <li @click="changeTab('location')">Location</li>
        <li @click="changeTab('about')">About</li>
        <li @click="changeTab('reserve')">Reserve Now</li>
      </ul>
    </nav>

    <div v-if="currentTab === 'welcome'">
      <HomeOnline></HomeOnline>
    </div>

    <div v-if="currentTab === 'menu'">
      <MenuLayout></MenuLayout>
    </div>

    <div v-if="currentTab === 'events'">
      <h4>Events Content</h4>
    </div>

    <div v-if="currentTab === 'location'">
      <h4>Location Content</h4>
    </div>

    <div v-if="currentTab === 'about'">
      <h4>About Content</h4>
    </div>

    <div v-if="currentTab === 'reserve'">
      <h4>Reserve Now Content</h4>
    </div>
  </div>
</template>

<script>
import MenuLayout from "../../lite/layouts/MenuLayout.vue"
import HomeOnline from "../../online/views/HomeOnline.vue"
export default {
  components: {
    MenuLayout,
    HomeOnline, // Registrar el componente para su uso en esta vista o componente
  },
  data() {
    return {
      currentTab: "welcome", // Inicialmente muestra el contenido de 'welcome'
    }
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab
    },
  },
}
</script>

<style scoped>
/* Estilos SCSS aquí */
nav {
  background-color: #f2f2f2;
  padding: 10px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
}

nav li {
  cursor: pointer;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #ddd;
}

nav li:hover {
  background-color: #bbb;
}
</style>
