<template>
  <div class="main-view">
    <commensal-header class="sticky-header" />
    <router-view />
    <footer-navbar class="footer-navbar" />
    <modal-logout />
  </div>
</template>
<script>
import CommensalHeader from "../components/CommensalHeader.vue"
import FooterNavbar from "@/components/FooterNavbar.vue"
import ModalLogout from "@/modals/ModalLogout.vue"
export default {
  name: "NameView",
  components: {
    CommensalHeader,
    FooterNavbar,
    ModalLogout,
  },
}
</script>
<style scoped>
.main-view {
  background-color: #fbfbfb;
}
.sticky-header {
  position: sticky;
  top: 0;
}
.footer-navbar {
  position: fixed;
  bottom: 0;
}
@media screen and (min-width: 1023px) {
  .main-view {
    padding-left: 167px;
  }
  .footer-navbar {
    top: 0;
    left: 0;
  }
}
@media screen and (min-width: 1359px) {
  .main-view {
    padding-left: 227px;
  }
}
</style>
