import { createRouter, createWebHistory } from "vue-router"
import BillView from "../views/BillView.vue"
import ErrorCommensals from "../views/ErrorCommensals.vue"
import FarewellView from "@/views/FarewellView.vue"
import MainView from "@/views/MainView.vue"
import Menu from "../views/Menu.vue"
import main from "../layouts/MainLayout.vue"
// import MainOnline from "../layouts/MainLayoutOnline.vue"
import Order from "../views/Order.vue"
import Profile from "../views/Profile.vue"
import MenuLayout from "../modules/lite/layouts/MenuLayout.vue"
// import Welcome from "../views/Welcome.vue";

// V2

import MenuV2 from "../modules/menu/views/MenuView.vue"
import OrdersV2 from "../modules/orders/views/OrdersView.vue"
import HelpV2 from "../modules/help/views/HelpView.vue"
import Onboarding from "../views/Onboarding.vue"
import AuthenticationView from "../modules/auth/views/AuthenticationView.vue"
// import Entering from "../views/Entering.vue"
import MenuPrincipal from "../modules/lite/layouts/MenuPrincipalColUs.vue"
import LiteMenu from "../modules/lite/layouts/MenuLayout.vue" // este es el menú sin interacción de USA y COL
import MenuViewCOL from "../modules/menu/views/MenuViewCOL"
import CloseSession from "../modules/auth/views/CloseSession.vue"
import AllCategories from "../modules/liteus/views/AllCategories.vue"
// Lite US
import ReviewsUs from "../modules/liteus/views/ReviewsUs.vue"
import NavBarOnline from "../modules/online/components/NavBarOnline.vue"
import MainLayoutUs from "../modules/liteus/layouts/MainLayout.vue"
import HelpUs from "../modules/liteus/views/HelpView.vue"
import MenuUs from "../modules/liteus/views/MenuView.vue" // este es el menú principal de USA con pedida ayuda-mesero-cuenta
// import CloseSessionUS from "../modules/auth/views/CloseSessionUS.vue"

const routes = [
  {
    path: "/:business/:idBranch/:clientId/:clickReview",
    name: "reviews",
    component: ReviewsUs,
  },
  {
    path: "/website",
    name: "website",
    component: NavBarOnline,
  },
  // {
  //   path: "/:business/:id/:table",
  //   name: "Welcome",
  //   component: Entering,
  // },
  {
    path: "/:business/:id/:table/auth", // pagina auth COL
    name: "auth",
    component: AuthenticationView,
  },
  {
    path: "/:business/logout", // pagina de cerrar sesión COL
    name: "logout",
    component: CloseSession,
  },
  {
    path: "/:business/:id/:table/main",
    name: "main-view",
    component: MainView,
    children: [
      {
        path: "/:business/:id/:table/menu",
        name: "menu",
        component: Menu,
      },
      {
        path: "/:business/:id/:table/order",
        name: "order",
        component: Order,
      },
      {
        path: "/:business/:id/:table/bill",
        name: "bill",
        component: BillView,
      },
      {
        path: "/:business/:id/:table/profile",
        name: "profile",
        component: Profile,
      },
    ],
  },
  {
    path: "/farewell",
    name: "Farewell",
    component: FarewellView,
    children: {},
  },
  {
    path: "/error-commensals",
    name: "ErrorCommensals",
    component: ErrorCommensals,
  },
  {
    path: "/:business/:id/:table/main-v2",
    name: "main-v2",
    component: main,
    children: [
      {
        path: "",
        name: "menu-v2",
        component: MenuV2,
      },
      {
        path: "orders-v2",
        name: "orders-v2",
        component: OrdersV2,
      },
      {
        path: "help-v2",
        name: "help-v2",
        component: HelpV2,
      },
    ],
  },
  {
    path: "/:business/:id/menu",
    name: "main_online",
    component: MenuPrincipal,
  },
  {
    path: "/:business/:id/menu/menu-name/:menuName",
    name: "menu_name",
    component: MenuLayout,
  },

  {
    path: "/:business/:id/:table/onboarding",
    name: "onboarding",
    component: Onboarding,
  },
  {
    path: "/:business/:id/menu", // view principal menu online
    name: "menu-static",
    component: MenuPrincipal,
  },
  {
    path: "/:business/:id/menu/:menuId", // menu online
    name: "lite-menu",
    component: LiteMenu,
  },
  {
    path: "/col/:business/menu",
    component: MenuViewCOL,
  },
  // {
  //   path: "/close",
  //   component: CloseSession,
  // },

  // US LITE MENU
  {
    path: "/us/:business/:id/:table/entering",
    name: "us-entering",
    component: () => import("../modules/liteus/views/Entering.vue"),
  },
  {
    path: "/us/:business/:id/:table/auth",
    name: "us-welcome",
    component: () => import("../modules/liteus/views/Welcome.vue"),
  },
  {
    path: "/us/:business/logout-commensal",
    name: "us-logout",
    component: () => import("../modules/auth/views/CloseSessionUS.vue"), //pagina de cerrar sesion US
  },
  {
    path: "/us/:business/:id/:table/menu",
    name: "us-main",
    component: MainLayoutUs,
    children: [
      {
        path: "",
        name: "us-menu",
        component: MenuUs,
      },
      {
        path: "help",
        name: "us-help",
        component: HelpUs,
      },
      {
        path: "categories",
        name: "menu-categories",
        component: AllCategories,
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
