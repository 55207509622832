<template>
  <div
    class="modal fade"
    id="modalRecomendations"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div v-if="products" class="modal-content">
        <div class="modal-body" v-if="products.length > 0">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Recomendaciones</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <p class="description">
            Mientras que eliges tu pedido, puedes iniciar con alguno de los
            siguientes aperitivos
          </p>
          <div v-for="(product, index) in products" :key="product.id">
            <recomended-product
              :food="product"
              :setProductRecomended="setProductRecomended"
            />
            <hr v-if="index + 1 < products.length" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RecomendedProduct from "@/components/products/RecomendedProduct.vue"
import { mapGetters } from "vuex"
export default {
  name: "ModalRecomendations",
  props: {
    setProductRecomended: {
      type: Function,
      required: true,
    },
  },
  components: {
    RecomendedProduct,
  },
  computed: {
    ...mapGetters({
      products: "getRecomendedProducts",
    }),
  },
}
</script>
<style scoped>
.modal-content {
  border-radius: 10px;
}
.modal-dialog {
  width: 370px;
}
.modal-header {
  padding: 0;
  border: none;
}
.modal-title {
  font-size: 18px;
}
.description {
  font-size: 14px;
  text-align: start;
  margin-top: 8px;
}
@media screen and (max-width: 1023px) {
  .modal-dialog {
    width: auto;
  }
}
</style>
