<template>
  <div class="menu-view">
    <div
      v-if="loading"
      class="all-height d-flex justify-content-center align-items-center"
    >
      <fade-loader />
    </div>
    <div v-else :class="showOrder ? 'hidden' : ''">
      <restaurant class="mobile-info" />
      <search-options class="sticky-search" />
      <menu-list />
      <!-- <order :show="showOrder" :toggle="toggleShowOrder" /> -->
    </div>
    <!-- <order-pop-up
      class="order-popup"
      v-if="!loading"
      @click="toggleShowOrder"
    /> -->
    <modal-recomendations
      :products="recomendedProducts"
      :setProductRecomended="setProductRecomended"
    />
    <modal-product-recomended :food="productRecomended" />
  </div>
</template>
<script>
import Restaurant from "../components/Restaurant.vue"
import MenuList from "../components/MenuList.vue"
import SearchOptions from "../components/SearchOptions.vue"
import FadeLoader from "vue-spinner/src/FadeLoader.vue"
// import OrderPopUp from "../components/OrderPopUp.vue";
// import Order from "@/views/Order.vue";
import ModalRecomendations from "@/modals/ModalRecomendations.vue"
import ModalProductRecomended from "@/modals/ModalProductRecomended.vue"
import { mapMutations } from "vuex"
export default {
  name: "MenuView",
  components: {
    Restaurant,
    MenuList,
    SearchOptions,
    FadeLoader,
    // OrderPopUp,
    // Order,
    ModalRecomendations,
    ModalProductRecomended,
  },
  data() {
    return {
      loading: false,
      back: "",
      waiter: false,
      showOrder: false,
      recomendedProducts: [],
      productRecomended: null,
    }
  },
  computed: {
    allProducts() {
      return this.$store.getters.getAllProducts
    },
  },
  created() {
    this.loading = true
    this.fetchAllProducts()
    this.toggleButtons(0)
  },
  methods: {
    ...mapMutations({
      toggleButtons: "setCurrentNavbarButton",
    }),
    fetchAllProducts() {
      this.$store.dispatch("fetchAllProducts").finally(() => {
        this.loading = false
        this.getRecomendations()
      })
    },
    toggleShowOrder() {
      this.showOrder = !this.showOrder
    },
    getRecomendations() {
      const products = []
      for (let index = 0; index < this.allProducts.length; index++) {
        const category = this.allProducts[index]
        for (let index = 0; index < category.products.length; index++) {
          const element = category.products[index]
          products.push(element)
        }
      }
      for (let index = 0; index < 3; index++) {
        const randomNumber = Math.floor(Math.random() * products.length)
        this.recomendedProducts.push(products[randomNumber])
        products.splice(1, randomNumber)
      }
      this.$store.commit("setRecomendedProducts", this.recomendedProducts)
      this.openModalRecomendations()
    },
    setProductRecomended(payload) {
      this.productRecomended = payload
    },
    openModalRecomendations() {
      window.$("#modalRecomendations").modal("show")
    },
  },
}
</script>
<style scoped>
.menu-view {
  position: relative;
}
.sticky-search {
  position: sticky;
  top: -6px;
}
.hidden {
  max-height: calc(100vh - var(--header-h));
  overflow: hidden;
}
@media screen and (min-width: 767px) {
  .mobile-info {
    display: none;
  }
  .sticky-search {
    top: 50px;
  }
}
@media screen and (min-width: 1023px) {
  .menu-view {
    padding-right: 280px;
  }
}
@media screen and (min-width: 1359px) {
  .menu-view {
    padding-right: 390px;
  }
}
</style>
