<template>
  <div class="farewell-layout">
    <div v-if="step == 0" class="farewell">
      <img src="@/assets/images/bye-emoji.png" alt="bye-emoji" />
      <h6>Mesero en camino</h6>
      <p>
        Dentro de poco un mesero se acercará a ti con tu factura impresa
      </p>
      <n-button @click="nextStep">Calificar servicio</n-button>
      <n-button :style="2">Ver factura</n-button>
    </div>
    <div v-else class="rate-us">
      <h2>Tu opinión es importante</h2>
      <div class="rate-option">
        <h3>Servicio</h3>
        <p>
          ¿Qué tan satisfecho te sentiste con el servicio recibido por el
          personal?
        </p>
        <rating-stars :action="rateTheService" />
      </div>
      <hr />
      <div class="rate-option">
        <h3>Tecnología</h3>
        <p>
          ¿Qué tanto te gustó utilizar una herramienta digital para tu pedido?
        </p>
        <rating-stars :action="rateTheSoftware" />
      </div>
      <label for="comment"
        ><h3>Comentarios</h3>
        <p>Opcional</p></label
      >
      <textarea
        name=""
        id="comment"
        cols="30"
        rows="3"
        placeholder="Añade un comentario"
      ></textarea>
      <n-button>Enviar calificación</n-button>
      <n-button :style="2">Cancelar</n-button>
    </div>
  </div>
</template>
<script>
import NButton from "@/components/tools/buttons/NelkirButton.vue"
import RatingStars from "@/components/tools/RatingStars.vue"
import { mapMutations } from "vuex"
export default {
  name: "FarewellView",
  components: {
    NButton,
    RatingStars,
  },
  data() {
    return {
      rateService: 0,
      rateSoftware: 0,
      step: 0,
    }
  },
  methods: {
    ...mapMutations({
      toMenu: "toggleFooterNavbarButtons",
      reset: "resetState",
    }),
    nextStep() {
      this.step++
    },
    rateTheService(payload) {
      this.rateService = payload
    },
    rateTheSoftware(payload) {
      this.rateSoftware = payload
    },
  },
  created() {
    this.reset()
  },
}
</script>
<style scoped>
.farewell-layout {
  height: 100vh;
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.farewell {
  width: 280px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h6 {
  margin: 27px 0 9px;
  font-size: 18px;
  font-weight: 700;
}
.primary-button {
  height: 55px;
  width: 100%;
  margin: 21px 0 12px;
}
.secondary-button {
  width: 100%;
  height: 55px;
}
.rate-us {
  width: 100%;
  max-width: 425px;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
}
hr {
  margin: 0;
}
.rate-option h3 {
  margin-top: 15px;
}
.rate-option p {
  margin-top: 9px;
}
label {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
label p {
  opacity: 50%;
}
textarea {
  margin-top: 8px;
  resize: none;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
}
</style>
