<template>
  <q-layout
    v-show="!loading"
    id="menu-view"
    ref="scrollContainer"
    class="menu-layout scroll-y"
    @scroll="scrollHandler"
  >
    <Restaurant />
    <SearchOptions :position="position" />
    <ProductsList :onlyRead="false" />
    <!-- <q-page-sticky position="bottom" :offset="[0, 55]">
      <q-btn
        v-if="productsCount > 0"
        class="fab-btn"
        fab
        :label="`Ver pedido (${productsCount})`"
        color="primary"
        noCaps
        @click="toOrders"
      />
    </q-page-sticky> -->
    <div
      id="sticky-header"
      class="another-header row"
      :class="position > 50 ? 'fixed-header' : ''"
    >
      <q-avatar
        style="background-color: white; width: 37.03px;height: 37.03px;"
      >
        <img :src="getBusinessInfo.image" />
      </q-avatar>
      <h3 class="q-ml-md  fs-22 text-secondary">
        {{ getBusinessInfo ? getBusinessInfo.name : "Loading..." }}
      </h3>
    </div>
  </q-layout>
  <div v-show="loading" class="q-pa-md flex flex-center">
    <q-circular-progress
      indeterminate
      rounded
      size="50px"
      color="lime"
      class="q-ma-md"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { setCssVar } from "quasar"
import Restaurant from "../../menu/components/Restaurant.vue"
import SearchOptions from "../../menu/components/SearchOptions.vue"
import ProductsList from "../../menu/components/ProductsList.vue"

export default {
  components: {
    Restaurant,
    SearchOptions,
    ProductsList,
  },
  data() {
    return {
      loading: false,
      position: 0,
    }
  },
  computed: {
    ...mapGetters([
      "getAllProducts",
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
    ]),
    ...mapGetters("currentOrder", {
      products: "getProducts",
      preparingProducts: "getPreparingProducts",
      deliveredProducts: "getDeliveredProducts",
    }),
    productsCount() {
      return (
        this.products.length +
        this.preparingProducts.length +
        this.deliveredProducts.length
      )
    },
  },
  methods: {
    toOrders() {
      this.$router.push({
        name: "orders-v2",
      })
    },
    scrollHandler(w) {
      this.position = w.position
    },
    fetchBranchInfo() {
      this.$store.dispatch("fetchBranchInfo")
      this.$store.dispatch("fetchBusinessInfo")
    },
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data[0]

          setCssVar("secondary", styles?.color || " #03257E") // botones activos,required&notavailable bagde,skip & go menu,titulos,algunos precios
        }
      })
    },
    fetchBranchDetails() {
      this.$store.dispatch("fetchBranchDetails").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data || resp.detail || {}
          setCssVar("bg-color", styles?.detail?.color_background) //esto es el background
          setCssVar("disable-btn", styles?.detail?.disable_buttons) // esto para btn desactivados
          setCssVar("body-color", styles?.detail?.body_color) // esto para los subtitulos
          setCssVar("body-font", styles?.detail?.body_type)
          setCssVar("title-font", styles?.detail?.title_type)
          setCssVar("bg-nav", styles?.detail?.background_navigation)
          setCssVar("bg-contrast", styles?.detail?.background_contrast)
          // setCssVar("review-link", styles?.detail?.review_link)
        }
      })
    },
    fetchMenu() {
      this.$store.dispatch("fetchAllProducts").then(resp => {
        if (resp.status === 200) {
          // setTimeout(() => {

          this.loading = false
          // }, 1500)
        }
      })
    },
  },
  created() {
    this.loading = true
    this.$store.commit("setBusinessName", this.$route.params.business)
    this.fetchBranchInfo()
    this.fetchBranchConfig()
    this.fetchMenu()
    this.fetchBranchDetails()
  },
}
</script>

<style lang="scss" scoped>
.menu-layout {
  max-width: 425px;
  height: calc(100vh - 50px);
  display: grid;
  overflow-y: auto;
  grid-template-rows: 166px 110px calc(100vh - 326px);
  scroll-behavior: smooth;
}

.fab-btn {
  width: calc(100vw - 20px);
  max-width: 400px;
  min-width: 280px;
  border-radius: 10px;
  font-weight: bold;
}
.another-header {
  display: none;
}
.fixed-header {
  display: block;
  position: fixed;
  top: 0;
  z-index: 99;
  background: white;
  width: 100%;
  max-width: 425px;
  min-width: 280px;
  display: flex;
  align-items: center;
  padding: 20px 20px 0;
}
</style>
