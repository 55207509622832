import api from "@/api"
import axios from "axios"

// export const myAction = async ({commit}) => {

// }

export const sendRating = async ({ rootState }, payload) => {
  const response = await axios.post(
    `${api}/r/${rootState.businessName}/v1/qualifications`,
    payload
  )
  console.log(response)
}
