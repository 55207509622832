<template>
  <div class="logout">
    <div class="content">
      <q-card flat style="background-color :var(--q-bg-color)">
        <q-img
          src="@/assets/images/helpview.png"
          height="140px"
          width="150px"
          class="q-mb-lg"
        />
        <h3 class="header title-font fs-22">{{ $t("pages.logout.bye") }}</h3>
        <p class="text body-font fs-16">
          {{ $t("pages.logout.byeDescription") }} <br />
          {{ getBusinessInfo.name }}.
        </p>
      </q-card>
    </div>
    <div class="logo-container">
      <q-img src="../../../../public/img/icons/logopayfud2.png" width="100%" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["getBusinessInfo", "getBranchDetails"]),
  },
}
</script>

<style lang="scss" scoped>
.logout {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 100vh;
  // width: 100vw;
  // max-width: 425px;
  // min-width: 360px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  background-color: var(--q-bg-color);
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  margin-bottom: 10px;
}

.text {
  margin-bottom: 20px;
}

.logo-container {
  // width: 90px;
  // display: flex;
  // justify-content: center;
  // margin: auto;
  // margin-top: 20px;

  width: 90px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
