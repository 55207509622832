<template>
  <div class="restaurant">
    <section class="profile">
      <figure>
        <img src="@/assets/images/noImage.png" alt="img" />
      </figure>
      <div class="d-flex flex-column">
        <h4 class="restaurant-name">El Gran Langostino</h4>
        <div class="social-media">
          <img src="@/assets/icons/fb-icon.svg" alt="fb-icon" />
          <img src="@/assets/icons/ig-icon.svg" alt="ig-icon" />
          <img src="@/assets/icons/ws-icon.svg" alt="ws-icon" />
        </div>
      </div>
    </section>
    <p>
      Inspirados en la cocina tradicional del pacífico y sus raíces africanas
    </p>
  </div>
</template>
<script>
export default {
  name: "restaurant-vue",
  computed: {
    restaurant() {
      return this.$store.getters.getRestaurant
    },
  },
}
</script>
<style scoped>
.restaurant {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
  padding: 20px 20px 0 20px;
}
.profile {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 20px;
  text-align: start;
}
.restaurant-name {
  font-size: 20px;
}
.collapse {
  height: 0px;
}
figure {
  height: 85px;
  width: 85px;
  border-radius: 50%;
  display: flex;
  justify-items: center;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  margin: 0;
  margin-right: 12px;
}
img {
  height: 100%;
}
h1 {
  margin: 0;
}
p {
  margin: 2px 0 0 0;
  text-align: start;
}
.social-media {
  margin-top: 8px;
}
.social-media img {
  height: auto;
  margin-right: 10px;
}
</style>
