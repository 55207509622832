<template>
  <div class="card-principal">
    <div class="img-container">
      <img
        style="width: 114.024px;
           height: 93.526px;"
        :src="getBusinessInfo.image"
      />
    </div>
    <div>
      <h3 class="text-secondary fs-22 title-font">
        {{
          $t("common.menu", {
            restaurant: getBusinessInfo.name,
          })
        }}
      </h3>
    </div>
    <div>
      <p class="description-text fs-16 body-font">
        {{ getBranchConfig && getBranchConfig[0]?.descripcion }}
      </p>
    </div>

    <q-card
      class="card-menu"
      v-for="menu in getMenuTime"
      :key="menu.id"
      style=""
    >
      <h3 class=" title-menu text-secondary fs-15  title-font">
        {{ menu.menu_name }}
      </h3>
      <p class="description-text fs-16 body-font body-color">
        {{ menu.menu_description }}
      </p>
      <q-btn
        style="height: 55px !important; border-radius: 0px 0px 4px 4px !important;"
        class="full-width text-black body-font btn-go-back"
        color="secondary"
        unelevated
        no-caps
        @click="redirectMenuTime(menu.menu_name)"
      >
        <div class="flex items-center">
          <span
            style="font-size: 17px !important;"
            class="body-font body-color-btn"
            >{{ $t("pages.menu.seeMenu") }}</span
          >
          <q-icon name="arrow_forward" class="q-ml-xs arrow-color" />
          <!-- Ajusta el margen según sea necesario -->
        </div>
      </q-btn>
    </q-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import { setCssVar } from "quasar"

export default {
  data() {
    return {
      loading: false,
      position: 0,
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      // categories: "getProductsFiltered",
      categories: "getFilteredCategories",
    }),
    ...mapGetters([
      "getAllProducts",
      "getBranchInfo",
      "getBranchConfig",
      "getBusinessInfo",
      "getBranchDetails",
      "getMenuTime",
    ]),
    ...mapGetters("currentOrder", {
      products: "getProducts",
    }),
  },
  methods: {
    scrollHandler(w) {
      this.position = w.position
    },
    fetchBranchInfo() {
      this.$store.dispatch("fetchBranchInfo")
    },
    fetchBranchConfig() {
      this.$store.dispatch("fetchBranchConfig").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data[0]
          setCssVar("secondary", styles?.color || "#DBB244") // botones activos,required&notavailable bagde,skip & go menu,titulos,algunos precios
          setCssVar("bg-options", styles?.background_options)
          setCssVar("bg-search", styles?.background_search)
          setCssVar("bg-products-item", styles?.background_products_item)
          setCssVar("bg-products", styles?.background_products)
          setCssVar("bg-modal", styles?.background_modal)
          setCssVar("bg-tabs", styles?.background_tabs)
        }
      })
    },
    fetchBranchDetails() {
      this.$store.dispatch("fetchBranchDetails").then(resp => {
        if (resp.status == 200) {
          const styles = resp.data || resp.detail || {}
          setCssVar("bg-color", styles?.detail?.color_background) //esto es el background
          setCssVar("disable-btn", styles?.detail?.disable_buttons || "#777") // esto para btn desactivados
          setCssVar("body-color", styles?.detail?.body_color) // esto para los subtitulos
          setCssVar("body-font", styles?.detail?.body_type)
          setCssVar("title-font", styles?.detail?.title_type)
          setCssVar("bg-nav", styles?.detail?.background_navigation)
          setCssVar(
            "bg-contrast",
            styles?.detail?.background_contrast || "#303030"
          )
          // setCssVar("review-link", styles?.review_link || "#DBB244")
        }
      })
    },
    fetchBusinessInfo() {
      this.$store.dispatch("fetchBusinessInfo").then(resp => {
        const location = resp.location
        if (location === "col") {
          this.$i18n.locale = "es"

          // Colombia
        } else if (location === "us") {
          this.$i18n.locale = "en"

          // Belongs to USA
        }
      })
    },
    redirectMenuTime(menu_name) {
      this.$router.push({ name: "menu_name", params: { menuName: menu_name } })
    },
    fetchMenuTimeOnline(menu_name) {
      // esto se implementa en el botón

      const params = {} // aqui solo entra cuando si exista el id
      if (menu_name) params.name = menu_name
      this.$store.dispatch("fetchMenuTime", params)
    },
  },
  mounted() {
    this.fetchBusinessInfo()
    this.$store.dispatch("fetchMenuTimeAll")
  },
  created() {
    this.loading = true
    this.$store.commit("setBusinessName", this.$route.params.business)
    this.fetchBranchInfo()
    this.fetchBranchConfig()
    // this.fetchMenu()
    this.fetchBranchDetails()
    this.loading = false
  },
}
</script>

<style lang="scss" scoped>
.arrow-color {
  color: var(--q-bg-color);
}
.body-color-btn {
  color: var(--q-bg-color);
}
.title-menu {
  padding: 16px 12px 0;
}
.card-menu {
  background-color: var(--q-bg-contrast);
  margin: 0 27px 14px;
}
.card-principal {
  background-color: var(--q-bg-color) !important;
}
.q-card-margin {
  margin-bottom: 10px;
}
.description-text {
  padding: 8px 12px 16px;
}
.img-container {
  padding-top: 25px;
}
.logout {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 100vh;
  // width: 100vw;
  // max-width: 425px;
  // min-width: 360px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  // background-color: var(--q-bg-color);
}
// .title-font {
//   font-family: Playfair Display;
//   font-size: 22px;
// }
// .body-font {
//   font-family: Georgia !important;
//   font-size: 16px;
//   font-weight: 400;
// }

// .content {
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
// }

.header {
  margin-bottom: 10px;
}

.text {
  margin-bottom: 20px;
}

.payfud {
  background-color: #fff;
  width: 100%;
  height: 55.433px;
  text-align: center;
}
</style>
