<template>
  <div></div>
</template>

<script>
import { Notify } from "quasar"
import { mapGetters } from "vuex"
export default {
  name: "NotifyBadge",
  data() {
    return {}
  },
  methods: {
    handleNotification() {
      const notify = this.$route.query.notify
      if (notify) {
        const notifications = {
          success: {
            message: "You joined the VIP Club",
          },
        }
        const notification = notifications[notify]
        if (notification) {
          Notify.create({
            icon: "check",
            html: true,
            message: notification.message,
            color: "white",
            classes: "custom-notification-join",
            position: "bottom",
            textColor: "primary",
            timeout: 3000,
          })
        }
      }
    },
  },
  computed: {
    ...mapGetters(["getBranchConfig", "getStyles"]),
  },
  mounted() {
    this.handleNotification()
  },
}
</script>

<style lang="scss">
.custom-notification-join {
  height: 56px !important;
  width: 350px !important;
  font-size: 16px;
  background: var(--q-bagde-color) !important;
  color: var(--q-text-bagde-color) !important;
  border-radius: var(--q-border-radius) !important;

  .q-notification__message {
    font-size: 16px;
    font-family: Manrope;
    padding: 0;
  }
  .q-notification__wrapper {
    display: flex;
    align-items: center;
  }
}
</style>
