<template>
  <div
    class="modal fade"
    id="modalProductRecomended"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div v-if="food" class="modal-content">
        <button
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
          class="arrow-button"
        >
          <img src="@/assets/icons/arrow.svg" alt="" />
        </button>
        <div
          :class="
            noImg
              ? 'modal-header overflow-hidden noImg'
              : 'modal-header overflow-hidden'
          "
        >
          <img v-if="!noImg" class="foodImg" :src="food.image" alt="" />
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-between align-items-center fw">
            <h6>{{ food.name }}</h6>
            <p>
              <span
                >${{
                  new Intl.NumberFormat("es-CO").format(food.total_price)
                }}</span
              >
            </p>
          </div>
          <p>{{ food.description }}</p>
          <!-- <div v-if="options.length > 0" class="options">
            <div v-for="(option, index) in food.options" :key="option.id">
              <div
                :class="
                  option.required
                    ? 'd-flex justify-content-between align-items-center'
                    : 'd-flex justify-content-start'
                "
              >
                <h6>{{ option.name }}</h6>
                <p v-if="option.required" class="required">Obligatorio</p>
              </div>
              <small
                >Selecciona máximo {{ option.max_required }} opcion(es)</small
              >
              <div v-for="(elect, index2) in option.election" :key="elect.id">
                <div
                  v-if="option.selection_times == 1"
                  @click="setElectionOfOption(elect, index)"
                >
                  <check-box-option
                    :optionName="elect.name"
                    :isActive="
                      options[index].elections.find(
                        (element) => element.id == elect.id
                      )
                    "
                  />
                  <span v-if="elect.price > 0"
                    >+ ${{
                      new Intl.NumberFormat("es-CO").format(elect.price)
                    }}</span
                  >
                </div>
                <div v-else>
                  <election-quantity-handler
                    :election="elect"
                    :additions="options"
                    :index="index"
                    :more="setMoreElectionOfOption"
                    :less="setLessElectionOfOption"
                  />
                  <span v-if="elect.price > 0"
                    >+ ${{
                      new Intl.NumberFormat("es-CO").format(elect.price)
                    }}</span
                  >
                </div>
                <hr v-if="index2 < option.election.length - 1" />
              </div>
            </div>
          </div>
          <div v-if="additions.length > 0" class="options">
            <div v-for="(addition, index) in food.additions" :key="addition.id">
              <div
                :class="
                  addition.required
                    ? 'd-flex justify-content-between align-items-center'
                    : 'd-flex justify-content-start'
                "
              >
                <h6>{{ addition.name }}</h6>
                <p v-if="addition.required" class="required">Obligatorio</p>
              </div>
              <small
                >Selecciona máximo
                {{ addition.max_required }} adicion(es)</small
              >
              <div v-for="(elect, index2) in addition.election" :key="elect.id">
                <div
                  v-if="addition.selection_times == 1"
                  @click="setElectionOfAddition(elect, index)"
                >
                  <check-box-option
                    :optionName="elect.name"
                    :isActive="
                      additions[index].elections.find(
                        (element) => element.id == elect.id
                      )
                    "
                  />
                </div>
                <div v-else>
                  <election-quantity-handler
                    :election="elect"
                    :additions="additions"
                    :index="index"
                    :more="setMoreElectionOfAddition"
                    :less="setLessElectionOfAddition"
                  />
                  <span v-if="elect.price > 0"
                    >+ ${{
                      new Intl.NumberFormat("es-CO").format(elect.price)
                    }}</span
                  >
                </div>
                <hr v-if="index2 < addition.election.length - 1" />
              </div>
            </div>
          </div>
          <h6 class="fw text-start">
            Nota
          </h6>
          <textarea
            name=""
            id=""
            cols="30"
            rows="3"
            placeholder="Agrega indicaciones a tu pedido"
            v-model="note"
          ></textarea>
          <div class="check-boxes">
            <div class="select" @click="toogleEatHere">
              <img :src="eatHere == 3 ? checkIcon : noCheckIcon" alt="" />
              <p>Comer aquí</p>
            </div>
            <div class="select" @click="toogleCarry">
              <img :src="eatHere == 1 ? checkIcon : noCheckIcon" alt="" />
              <p>Llevar</p>
            </div>
          </div> -->
        </div>
        <div class="modal-footer">
          <div class="quantity-area">
            <button class="quantity-button" @click="lessQuantity">
              <img src="@/assets/icons/trash-small.svg" alt="trash-icon" />
            </button>
            <p class="quantity">{{ quantity }}</p>
            <button class="quantity-button" @click="moreQuantity">
              <img src="@/assets/icons/add.svg" alt="add-icon" />
            </button>
          </div>
          <n-button data-bs-dismiss="modal" @click="addFood">Agregar</n-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import check from "@/assets/icons/check.svg"
import noCheck from "@/assets/icons/nocheck.svg"
import NButton from "@/components/tools/buttons/NelkirButton.vue"
import { mapGetters } from "vuex"
export default {
  name: "modalProductRecomended",
  props: {
    food: {
      type: Object,
      required: true,
    },
  },
  components: {
    NButton,
  },
  data() {
    return {
      eatHere: 3,
      checkIcon: check,
      noCheckIcon: noCheck,
      note: "",
      quantity: 1,
      options: [],
      additions: [],
    }
  },
  computed: {
    ...mapGetters({
      food: "getFoodConfig",
    }),
    noImg() {
      return this.food.image === null
    },
    priceWithElecctions() {
      let totalPrice = this.food.total_price
      for (let index = 0; index < this.options.length; index++) {
        const element = this.options[index].elections
        for (let index2 = 0; index2 < element.length; index2++) {
          const element2 = element[index2].price
          totalPrice = totalPrice + element2
        }
      }
      for (let index = 0; index < this.additions.length; index++) {
        const element = this.additions[index].elections
        for (let index2 = 0; index2 < element.length; index2++) {
          const element2 = element[index2].price
          totalPrice = totalPrice + element2
        }
      }
      return totalPrice * this.quantity
    },
  },
  methods: {
    toogleEatHere() {
      this.eatHere = 3
    },
    toogleCarry() {
      this.eatHere = 1
    },
    getInfo() {
      return {
        product_id: this.food.id,
        name: this.food.name,
        description: this.food.description,
        price: this.food.total_price,
        img: this.food.image,
        order_type: this.eatHere,
        note: this.note,
        quantity: this.quantity,
        options: this.food.options,
        additions: this.food.additions,
        currentOptions: this.options,
        currentAdditions: this.additions,
      }
    },
    addFood() {
      this.$store.commit("setOrderProduct", this.getInfo())
    },
    moreQuantity() {
      this.quantity++
    },
    lessQuantity() {
      if (this.quantity > 0) {
        if (this.quantity > 1) {
          this.quantity--
        }
      }
    },
  },
}
</script>
<style scoped>
hr {
  margin: 12px 0;
}
small {
  margin-bottom: 10px;
}
.modal-dialog {
  margin: auto;
  border-radius: 0 !important;
  position: relative;
  height: 100%;
}
.modal-content {
  min-height: 100%;
  padding-bottom: 70px;
}
.modal-header {
  height: 222px;
  width: 100%;
  padding: 0;
  position: relative;
}
.noImg {
  height: 0;
  border-bottom: none;
}
.arrow-button {
  position: absolute;
  top: 5px;
  left: 5px;
  border: 0;
  background-color: white;
  border-radius: 100%;
  z-index: 99;
}
.foodImg {
  width: 100%;
  height: 100%;
}
.modal-body {
  padding: 10px 14px;
  display: flex;
  flex-direction: column;
  text-align: start;
}
h6 {
  margin: 8px 0;
  width: 196px;
  text-align: start;
  font-weight: 700;
  font-size: 18px;
  font-family: "DM Serif Display", serif;
}
span {
  font-size: 16px;
  font-weight: 700;
}
p {
  margin: 0;
  text-align: start;
}
.options {
  margin-top: 12px;
}
.required {
  font-size: 14px;
  color: #e94a47;
}
textarea {
  resize: none;
  padding: 12px;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
}
.check-boxes {
  width: 100%;
  margin-top: 20px;
  display: flex;
}
.select {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.check-boxes .select:first-child {
  margin-right: 30px;
}
.select img {
  margin-right: 10px;
}
.primary-button {
  height: 45px;
  width: 148px;
  background-color: #790c19;
}
.fw {
  width: 100%;
}
.quantity-area {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 45px;
  width: 121px;
}
.quantity {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin: 0 5px;
}
.quantity-button {
  background-color: transparent;
  height: 40px;
  width: 40px;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-close {
  background-color: transparent;
  border: none;
  top: 20px;
  right: 15px;
}
.modal-footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  justify-content: space-around;
  align-items: center;
  border-top: none;
  height: 70px;
  padding: 0;
  background-color: #fff;
}
</style>
