<template>
  <button class="pay-button" :disabled="disableButton">
    <p>Pagar cuenta</p>
  </button>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "PayButton",
  computed: {
    ...mapGetters({
      payMethod: "getPayMethod",
    }),
    disableButton() {
      return this.payMethod === -1
    },
  },
}
</script>
<style scoped>
.pay-button {
  width: 100%;
  height: 45px;
  background-color: #cb3734;
  border: none;
  position: fixed;
  bottom: 59px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
p {
  color: #fff;
}
.pay-button:disabled {
  background-color: #e0e0e0;
}
.pay-button:disabled p {
  color: #828282;
}
</style>
