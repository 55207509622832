<template>
  <div class="review">
    <div class="content">
      <q-card flat style="background-color: white; margin-top:48px">
        <q-avatar
          style="background-color: #071B2C; width: 68px; height: 68px; border-radius:50%;"
        >
          <!-- <img :src="getBusinessInfo.image" /> -->
          <!-- src="@/assets/images/logoab.png" -->
          <img
            :src="getBusinessInfo.image"
            style=" width:59px;
           height: 16px;"
          />
        </q-avatar>
        <h3 class="header title-font" style="color: #082136">
          {{ getBusinessInfo.name }}
        </h3>
        <p class="text-location body-font" style="color: #082136">
          <q-icon name="location_on" class="q-ml-xs" />
          {{ getBranchDetails?.detail?.address }}
        </p>
        <p class="text body-font" style="color: rgb(72, 71, 71)">
          Share your thoughts with us! <br />
          Your reviews mean the world to <br />
          {{ getBusinessInfo.name }}, and we'd love to<br />
          hear about your experiences.
        </p>
      </q-card>
      <q-btn
        style="background-color:#256FCA; display: flex; align-items: center; height: 55px !important; border-radius:5px !important;"
        @click="redirectToReviewLink"
        class="full-width text-white body-font"
        unelevated
        no-caps
      >
        <img
          src="@/assets/images/G.png"
          alt="Google Logo"
          style="width: 24px; height: 24px; margin-right: 13px;"
        />
        <span>Submit review on Google</span>
      </q-btn>
    </div>
    <div class=" " style="display: flex; justify-content: space-between;">
      <p style="margin-right: 5px; color: black;">Made with</p>
      <a href="https://payfud.com/" class="text-black" target="_blank">
        <p class="text-black">Payfud.com</p>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters(["getBusinessInfo", "getBranchDetails"]),
  },
  methods: {
    redirectToReviewLink() {
      const reviewLink = this.getBranchDetails?.review_link
      if (reviewLink) {
        window.open(reviewLink, "_blank")
      }
      const { business, idBranch, clientId, clickReview } = this.$route.params

      const urlShortened = `menu.payfud.com/${business}/${idBranch}/${clientId}/${clickReview}`
      const payload = {
        click_review_1: clickReview, // o el valor que corresponda a clickReview
        user_id: clientId, // o el valor de user_id que necesites
        url_shortened: urlShortened,
      }
      this.$store.dispatch("reviewsAnalytics", payload)
    },
  },
  mounted() {
    // Puedes hacer lo que necesites con business, id y clientid
  },

  created() {
    const business = this.$route.params.business
    this.$store.commit("setBusinessName", business)

    this.$store.dispatch("fetchBusinessInfo", { businessName: business })
    this.$store.dispatch("fetchBranchDetails", { businessName: business })
  },
}
</script>

<style lang="scss" scoped>
.img-container {
  padding-top: 25px;
}
.review {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // height: 100vh;
  // width: 100vw;
  // max-width: 425px;
  // min-width: 360px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100dvh;
  width: 100vw;
  max-width: 425px;
  min-width: 360px;
  background-color: white;
}
.title-font {
  font-family: Prata;
  font-size: 22px;
}
.body-font {
  font-family: Manrope !important;
  font-size: 16px;
  font-weight: 400;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  // justify-content: space-evenly;
  align-items: center;
}

.header {
  margin-bottom: 15px;
  margin-top: 12px;
}

.text-location {
  margin-bottom: 20px;
}
.text {
  margin-bottom: 45px;
}

.payfud {
  background-color: #fff;
  width: 100%;
  height: 55.433px;
  text-align: center;
}
</style>
