import api from "@/api"
import axios from "axios"

const getDefaultState = () => {
  return {
    orderProducts: new Array(),
    currentOrderProduct: [],
    orderState: "",
    modalTypeStatus: 0,
    orderInProgress: new Array(),
    orderId: null,

    table: null,
    orderType: null,
    products: [],
  }
}

const order = {
  state: getDefaultState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState())
    },
    setOrderProduct(state, payload) {
      state.orderProducts.push(payload)
    },
    updateOrderProduct(state, payload) {
      state.orderProducts.splice(payload.index, 1, payload.product)
    },
    deleteOrderProduct(state, payload) {
      state.orderProducts.splice(payload, 1)
    },
    setCurrentOrderProduct(state, payload) {
      state.currentOrderProduct = payload
    },
    setMoreQuantity(state, payload) {
      for (let index = 0; index < state.orderProducts.length; index++) {
        if (state.orderProducts[index].product_id === payload) {
          state.orderProducts[index].quantity++
          state.currentOrderProduct = state.orderProducts[index]
        }
      }
    },
    setLessQuantity(state, payload) {
      for (let index = 0; index < state.orderProducts.length; index++) {
        if (state.orderProducts[index].product_id === payload) {
          if (state.orderProducts[index].quantity > 0) {
            state.orderProducts[index].quantity--
            state.currentOrderProduct = state.orderProducts[index]
          }
        }
      }
    },
    setModalTypeStatus(state, payload) {
      state.modalTypeStatus = payload
    },
    emptyCart(state) {
      state.orderProducts = []
    },
    setOrderInProgress(state, payload) {
      for (let index = 0; index < state.orderProducts.length; index++) {
        state.orderInProgress.push(state.orderProducts[index])
      }
      state.orderId = payload
    },
    addOrderInProgress(state) {
      for (let index = 0; index < state.orderProducts.length; index++) {
        state.orderInProgress.push(state.orderProducts[index])
      }
    },
    setOrderTable(state, payload) {
      //funcion que guarda la mesa cuando la obtenga;
      state.table = payload
    },
  },
  actions: {
    createOrder({ rootState, commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${api}/r/${rootState.businessName}/v1/orders`, payload, {
            headers: {
              "content-type": "application/json",
            },
          })
          .then(
            response => {
              resolve(response)
              commit("setOrderInProgress", response.data.id)
            },
            error => {
              reject(error)
            }
          )
      })
    },
    addProductToOrder({ rootState, commit, state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `${api}/r/${rootState.businessName}/v1/orders/${state.orderId}`,
            payload,
            {
              headers: {
                "content-type": "application/json",
              },
            }
          )
          .then(
            response => {
              resolve(response)
              commit("addOrderInProgress")
            },
            error => {
              reject(error)
            }
          )
      })
    },
  },
  getters: {
    getOrderTable(state) {
      return state.table
    },
    getOrderProducts(state) {
      return state.orderProducts
    },
    getOrderToPay(state) {
      return state.orderInProgress
    },
    getCurrentOrderProduct(state) {
      return state.currentOrderProduct.product
    },
    getIndexOfCurrentOrderProduct(state) {
      return state.currentOrderProduct.index
    },
    getModalTypeStatus(state) {
      return state.modalTypeStatus
    },
    getOrderId(state) {
      return state.orderId
    },
    getTotalPrice(state) {
      let totalPrice = 0
      for (let index = 0; index < state.orderProducts.length; index++) {
        totalPrice =
          totalPrice +
          Number(state.orderProducts[index].price) *
            state.orderProducts[index].quantity
      }
      return totalPrice
    },
  },
}
export default order
