<template>
  <div class="home-online">
    <div class="q-pa-md row items-start q-gutter-md">
      <q-card flat bordered class="my-card">
        <q-card-section>
          <div class="text-h6">Avenue Blu</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          Tampa Best Casual Dinning
        </q-card-section>

        <q-card-section>
          Best Casual Dining Restaurant in Hyde Park Tampa
        </q-card-section>
      </q-card>
    </div>
  </div>
  <q-card class="stars-card">
    <q-card-section>
      <!-- Tres iconos de estrellas -->
      <div class="card-section">
        <div class="star">
          <q-icon name="star" size="md" color="yellow"></q-icon>
          <span>Come empty, leave full of joy</span>
        </div>
        <div class="star">
          <q-icon name="star" size="md" color="yellow"></q-icon>
          <span>A place like no other</span>
        </div>
        <div class="star">
          <q-icon name="star" size="md" color="yellow"></q-icon>
          <span>Unique restaurant concept</span>
        </div>
      </div>
    </q-card-section>
  </q-card>
  <q-card class="events-card">
    <q-card-section>
      <h4>Upcoming Events</h4>
      <div class="events">
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Event Name Max Title...</div>
            <div class="text-subtitle2">
              Delicious description melted with Wisconsin cheese...
            </div>
            <div class="info-icons">
              <q-icon name="event" size="xs"></q-icon>
              Wed, Jan 20, 2024
            </div>
            <div>
              <q-icon name="schedule" size="xs"></q-icon>
              3:00 P.M.
            </div>
            <q-btn
              style="text-align: center; width: 242px; margin-top: 9px;"
              class="btn-learn"
              >Learn more</q-btn
            >
          </q-card-section>
        </q-card>
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Event Name Max Tit</div>
            <div class="text-subtitle2">
              Delicious description melted with Wisconsin cheese...
            </div>
            <div class="info-icons">
              <q-icon name="event" size="xs"></q-icon>
              Wed, Jan 20, 2024
            </div>
            <div>
              <q-icon name="schedule" size="xs"></q-icon>
              3:00 P.M.
            </div>
            <q-btn
              style="text-align: center; width: 242px; margin-top: 9px;"
              class="btn-learn"
              >Learn more</q-btn
            >
          </q-card-section>
        </q-card>
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Event Name Max Title...</div>
            <div class="text-subtitle2">
              Delicious description melted with Wisconsin cheese...
            </div>
            <div class="info-icons">
              <q-icon name="event" size="xs"></q-icon>
              Wed, Jan 20, 2024
            </div>
            <div>
              <q-icon name="schedule" size="xs"></q-icon>
              3:00 P.M.
            </div>
            <q-btn
              style="text-align: center; width: 242px;margin-top: 9px;"
              class="btn-learn"
              >Learn more</q-btn
            >
          </q-card-section>
        </q-card>
      </div>
    </q-card-section>
  </q-card>
  <q-card flat bordered class="enjoy">
    <q-card-section>
      <div class="text-h6">Enjoy the rhythm of x while you dine with us</div>
    </q-card-section>
    <q-btn style="text-align: center; margin-top: 9px;" class="btn-learn"
      >Book a table</q-btn
    >
  </q-card>
  <q-card class="cousine-card">
    <q-card-section>
      <h4>Our cuisine</h4>
      <div class="cousine">
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Cheese Curds</div>
            <div class="text-subtitle2">
              Delicious description melted with Wisconsin cheese something...
            </div>
          </q-card-section>
        </q-card>
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Cheese Curds</div>
            <div class="text-subtitle2">
              Delicious description melted with Wisconsin cheese something...
            </div>
          </q-card-section>
        </q-card>
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Cheese Curds</div>
            <div class="text-subtitle2">
              Delicious description melted with Wisconsin cheese something...
            </div>
          </q-card-section>
        </q-card>
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />
          <q-card-section>
            <div class="text-h6">Cheese Curds</div>
            <div class="text-subtitle2">
              Delicious description melted with Wisconsin cheese something...
            </div>
          </q-card-section>
        </q-card>
      </div>
    </q-card-section>
  </q-card>
  <q-card class="reviews-card">
    <q-card-section>
      <h4>What our guests say about our service</h4>
      <div class="reviews">
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Donny Smallwood</div>
            <div class="star-section">
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
            </div>
            <div class="text-subtitle2">
              I love it, anytime I want to have a good time nearby, I know
              AvenuBlue is the place
            </div>
          </q-card-section>
        </q-card>
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Donny Smallwood</div>
            <div class="star-section">
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
            </div>
            <div class="text-subtitle2">
              I love it, anytime I want to have a good time nearby, I know
              AvenuBlue is the place
            </div>
          </q-card-section>
        </q-card>
        <q-card class="">
          <img src="https://cdn.quasar.dev/img/mountains.jpg" />

          <q-card-section>
            <div class="text-h6">Donny Smallwood</div>
            <div class="star-section">
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
              <q-icon name="star" size="md" color="yellow"></q-icon>
            </div>
            <div class="text-subtitle2">
              I love it, anytime I want to have a good time nearby, I know
              AvenuBlue is the place
            </div>
          </q-card-section>
        </q-card>
      </div>
    </q-card-section>
  </q-card>
</template>
<script>
export default {
  components: {
    // Registrar el componente para su uso en esta vista o componente
  },
  data() {
    return {
      currentTab: "welcome", // Inicialmente muestra el contenido de 'welcome'
    }
  },
  methods: {
    changeTab(tab) {
      this.currentTab = tab
    },
  },
}
</script>

<style scoped>
/* Estilos SCSS aquí */
.card-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}
.cousine {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
  text-align: left;
}
.reviews {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}
.events {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  text-align: left;
}
/* .info-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
} */
.star {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}
.home-online {
  background-image: url("@/assets/images/pruebawebsite.jpg");
}
.enjoy {
  background-image: url("@/assets/images/pruebawebsite.jpg");
}
.my-card {
  background: rgba(255, 255, 255, 0);
  background-size: cover; /* Ajusta según sea necesario */
  background-position: center; /* Ajusta según sea necesario */
  width: 100%;
  height: 100%;
  border: 0 !important;
}
.stars-card {
  color: black;
}
</style>
