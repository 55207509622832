<template>
  <div class="d-flex flex-column align-items-center">
    <commensals-header />
    <div class="wrapper">
      <div class="box">
        <img src="@/assets/images/error.png" alt="" />
        <p>Orden #azsfdaf</p>
        <h3>Ups... tu pedido ha sido rechazado.</h3>
        <button class="primary-button">Llama un mesero</button>
      </div>
      <button class="dark-button" @click="toOrder">Volver a mi pedido</button>
      <button class="dark-button" @click="toMenu">Volver al menú</button>
    </div>
  </div>
</template>
<script>
import CommensalsHeader from "@/components/CommensalHeader"
export default {
  components: {
    CommensalsHeader,
  },
  methods: {
    toMenu() {
      this.$router.push({ name: "name" })
    },
    toOrder() {
      this.$router.push({ name: "order" })
    },
  },
}
</script>
<style scoped>
.wrapper {
  padding-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.box {
  margin-top: 25px;
  width: 288px;
  height: 300px;
}
.box img {
  height: 150px;
}
.primary-button {
  width: 186px;
  margin-top: 20px;
}
.dark-button {
  border: none;
  background-color: #333333;
  border-radius: 5px;
  color: white;
  height: 55px;
  width: 100%;
  margin-top: 24px;
}
h3 {
  margin-top: 24px;
}
</style>
