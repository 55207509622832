<template>
  <section class="product-item">
    <div class="product-info">
      <p>{{ name }}</p>
    </div>
    <div>
      <p>
        $ {{ new Intl.NumberFormat("es-CO").format(product.price) }}
        <span>(x{{ product.quantity }})</span>
      </p>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex"
export default {
  name: "OrderProduct",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentProduct: {},
    }
  },
  computed: {
    ...mapGetters({
      allProducts: "getAllProducts",
    }),
    name() {
      const name = this.currentProduct.name.substring(0, 36)
      return name
    },
    description() {
      const description = this.currentProduct.description.substring(0, 58)
      return description
    },
  },
  methods: {
    findProduct() {
      const productsList = []
      for (let index = 0; index < this.allProducts.length; index++) {
        const element = this.allProducts[index]
        for (let index = 0; index < element.products.length; index++) {
          productsList.push(element.products[index])
        }
      }
      this.currentProduct = productsList.find(
        product => product.id == this.product.product_id
      )
    },
  },
  created() {
    this.findProduct()
  },
}
</script>
<style scoped>
.product-item {
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* border-bottom: 1px solid #e0e0e0; */
}
.product-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
p {
  margin: 0;
  text-align: start;
}
small {
  font-size: 12px;
  text-align: start;
}
.quantity {
  text-align: center;
  width: 36px;
}
.product-item--footer {
  width: 100%;
  justify-self: flex-end;
}
button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 14px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 0;
}
button .trash-button {
  background-color: transparent;
  min-height: 62px;
  min-width: 62px;
}
</style>
